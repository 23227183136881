// import { FormControlLabel, FormGroup, Grid, Switch } from '@material-ui/core'
// import React, { useState } from 'react'
// import IndiceCandleGraphs from './IndiceCandleGraphs'
// import FRECandleGraph from './FRECandleGraph'

// function IndiceCandleGraphView(props) {
//     const { value, dataFromChild } = props
//     const { token, companySymbol } = dataFromChild
//     const [view, setView] = useState(true)

//     const onViewClick = (e) => {
//         setView(e.target.checked)
//     }

//     return (
//         <>
//             {!view ?
//                 <IndiceCandleGraphs value={value} dataFromChild={dataFromChild} /> :
//                 <FRECandleGraph value={value} dataFromChild={dataFromChild} />}
//             <Grid style={{ margin: '-12px 10px 2px 0px', display: 'flex', justifyContent: 'end' }}>
//                 <FormGroup className="switch-view" onClick={onViewClick} >
//                     <FormControlLabel control={<Switch  checked={view} size="small" />} label="FRE Graph" />
//                 </FormGroup>
//             </Grid>
//         </>
//     )
// }

// export default IndiceCandleGraphView

import { useState } from 'react';
import { Radio, RadioGroup, FormControlLabel, Grid, FormGroup } from '@mui/material';
import IndiceCandleGraphs from './IndiceCandleGraphs'
import FRECandleGraph from './FRECandleGraph'
import GannCandleGraph from './GannCandleGraph';
function IndiceCandleGraphView(props) {
    const { value, dataFromChild, cmp } = props;
    const { token, companySymbol } = dataFromChild;
    const [view, setView] = useState('indice'); // Default view set to 'indice'

    const onViewClick = (e) => {
        setView(e.target.value); // Update view state based on the selected radio button
    };

    return (
        <>
            {/* {view === 'fre' && <FRECandleGraph value={value} dataFromChild={dataFromChild} />} */}
            {view === 'indice' && <IndiceCandleGraphs value={value} dataFromChild={dataFromChild} cmp={cmp} />}
            {/* {view === 'third' && <GannCandleGraph value={value} dataFromChild={dataFromChild} />} */}

            {/* <Grid style={{ margin: '-12px 20px 2px 0px', display: 'flex', justifyContent: 'end' }}>
                <FormGroup className="radio-view">
                    <RadioGroup row value={view} >
                        <FormControlLabel
                            value="fre"
                            control={<Radio size="small" />}
                            label="FRE "
                            onChange={onViewClick}
                        />
                        <FormControlLabel
                            value="indice"
                            control={<Radio size="small" />}
                            label="PP"
                            onChange={onViewClick}
                            className=''
                        />
                        <FormControlLabel
                            value="third"
                            control={<Radio size="small" />}
                            label="GANN "
                            onChange={onViewClick}
                        />
                    </RadioGroup>
                </FormGroup>
            </Grid> */}
        </>
    );
}

export default IndiceCandleGraphView;
