import { Grid } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from "react";
import "../HomePage/Homepage.css"
import { CurrentCompanyData, GetObservationsForCandle } from "../apiService/tickerApiService";
import TimeFrameContainer from '../HomePage/TimeFrameContainer';
import AnalysisTables from '../HomePage/AnalysisTables';
import { companyApiData, companyFibonacciData, companyZonesData } from '../apiService/tradaApiService';
import { cam_key_s, cpr_key_s, fpp_key_s, fre_key_s, spp_key_s, z_key_s } from '../../utils';



const timeFramesForDataTable = ["Daily", "Weekly", "Monthly"]

function EtfsTraderView(props) {
    const { value, ETFvalue, label, tickerResponse, token } = props;
    const [tickerRes, setTickerRes] = useState(0);
    const [data, setData] = useState([]);
    const [camarillaData, setCamarilladata] = useState([]);
    const [standardData, setStandarddata] = useState([]);
    const [fibonacciData, setFibonacciData] = useState([]);
    const [cprData, setCprData] = useState([]);
    const [ppData, setPpData] = useState([]);
    const [zonesData, setZonesData] = useState([]);
    const [fibonacciRes, setFibonacciRes] = useState({});

    const [prevDC, setPrevDC] = React.useState(0);
    const [prevWC, setPrevWC] = React.useState(0);
    const [prevMC, setPrevMC] = React.useState(0);
    const [closePrice, setClosePrice] = React.useState(0);
    const [curr_perc, setCurr_perc] = React.useState(0);

    const [cprtlDaily, setCprtlDaily] = React.useState(0);
    const [cprtlWeekly, setCprtlWeekly] = React.useState(0);
    const [cprtlMonthly, setCprtlMonthly] = React.useState(0);

    const [cprblDaily, setCprblDaily] = React.useState(0);
    const [cprblWeekly, setCprblWeekly] = React.useState(0);
    const [cprblMonthly, setCprblMonthly] = React.useState(0);

    const [cam_keys, setCam_Keys] = useState({ dailyKeys: [], weeklyKeys: [], monthlyKeys: [], });
    const [spp_keys, setSpp_Keys] = useState({ dailyKeys: [], weeklyKeys: [], monthlyKeys: [], });
    const [fb_keys, setFb_Keys] = useState({ dailyKeys: [], weeklyKeys: [], monthlyKeys: [], });
    const [cpr_keys, setCpr_Keys] = useState({ dailyKeys: [], weeklyKeys: [], monthlyKeys: [], });
    const [pp_keys, setPp_Keys] = useState({ dailyKeys: [], weeklyKeys: [], monthlyKeys: [], });
    const [z_keys, setZ_Keys] = useState({ dailyKeys: [], weeklyKeys: [], monthlyKeys: [], });
    const [f_keys, setF_Keys] = useState({ dailyKeys: [], weeklyKeys: [], monthlyKeys: [], });

    const [obj_observation_d_w, setObj_Observations_d_w] = useState({
        observe_dailyData: [],
        observe_weeklyData: [],
        observe_monthlyData: [],
    })

    const _getObservations_d_w = async (companySymbol, t) => {
        let _dailyData = await GetObservationsForCandle("D", companySymbol, t)
        let _weeklyData = await GetObservationsForCandle("W", companySymbol, t)
        let _monthlyData = await GetObservationsForCandle("M", companySymbol, t)
        setObj_Observations_d_w({
            observe_dailyData: _dailyData?.data,
            observe_weeklyData: _weeklyData?.data,
            observe_monthlyData: _monthlyData?.data,
        })
    }
    const currentMP = async (token) => {
        const CMPdata = await CurrentCompanyData(token);
        setTickerRes(CMPdata?.data)
        setClosePrice(CMPdata?.data?.closePrice)
        setCurr_perc((CMPdata?.data?.change)?.toFixed(2))
    }

    function filterData(obj, a, b) {
        const entries = Object.entries(obj);
        const filteredAndSorted = entries.filter(([key, value]) => key.startsWith(a) || key.startsWith(b))
            .sort((a, b) => b[1] - a[1]);

        const filteredAndSortedObj = Object.fromEntries(filteredAndSorted);
        return filteredAndSortedObj;
    }

    const fetchCompany = async () => {
        const response = await companyApiData(ETFvalue)
        if (response?.data?.success) {
            setData(response?.data)
            const cam_data = []
            const spp_data = []
            const fibonacci_data = []
            const cpr_data = []
            const pivortPoints_data = []

            let data = response?.data
            //   for camaralla 
            const camDailyData = filterData(data.dailyData, "CPH", "CPL");
            const dailyCamArr = Object.values(camDailyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _dailyCamKeys = Object.keys(camDailyData)

            const camWeeklyData = filterData(data.weeklyData, "CPH", "CPL");
            const weeklyCamArr = Object.values(camWeeklyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _weeklyCamKeys = Object.keys(camWeeklyData)

            const camMonthlyData = filterData(data.monthlyData, "CPH", "CPL");
            const monthlyCamArr = Object.values(camMonthlyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _monthlyCamKeys = Object.keys(camMonthlyData)

            //   for standard pp
            const sppDailyData = filterData(data.dailyData, "SPH", "SPL");
            const dailySppArr = Object.values(sppDailyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _dailySppKeys = Object.keys(sppDailyData)

            const sppWeeklyData = filterData(data.weeklyData, "SPH", "SPL");
            const weeklySppArr = Object.values(sppWeeklyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _weeklysppKeys = Object.keys(sppWeeklyData)

            const sppMonthlyData = filterData(data.monthlyData, "SPH", "SPL");
            const monthlySppArr = Object.values(sppMonthlyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _monthlySppKeys = Object.keys(sppMonthlyData)

            //  for fibonacci

            const fbDailyData = filterData(data.dailyData, "FPH", "FPL");
            const dailyFbArr = Object.values(fbDailyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _dailyFbKeys = Object.keys(fbDailyData)

            const fbWeeklyData = filterData(data.weeklyData, "FPH", "FPL");
            const weeklyFbArr = Object.values(fbWeeklyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _weeklyFbKeys = Object.keys(fbWeeklyData)

            const fbMonthlyData = filterData(data.monthlyData, "FPH", "FPL");
            const monthlyFbArr = Object.values(fbMonthlyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _monthlyFbKeys = Object.keys(fbMonthlyData)

            //  for CPR

            const CPRDailyData = filterData(data.dailyData, "CPR", "CPR");
            const dailyCPRArr = Object.values(CPRDailyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _dailyCPRKeys = Object.keys(CPRDailyData)

            const CPRWeeklyData = filterData(data.weeklyData, "CPR", "CPR");
            const weeklyCPRArr = Object.values(CPRWeeklyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _weeklyCPRKeys = Object.keys(CPRWeeklyData)

            const CPRMonthlyData = filterData(data.monthlyData, "CPR", "CPR");
            const monthlyCPRArr = Object.values(CPRMonthlyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _monthlyCPRKeys = Object.keys(CPRMonthlyData)

            // for all pivort points

            let dailyArr = Object.values(data.dailyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            let _dailyKeys = Object.keys(data.dailyData)

            let weeklyArr = Object.values(data.weeklyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            let _weeklyKeys = Object.keys(data.weeklyData)

            let monthlyArr = Object.values(data.monthlyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            let _monthlyKeys = Object.keys(data.monthlyData)

            dailyArr.map((e, i) => {
                pivortPoints_data.push({ daily: e, weekly: weeklyArr[i], monthly: monthlyArr[i] })
            })

            dailyCamArr.map((e, i) => {
                cam_data.push({ daily: e, weekly: weeklyCamArr[i], monthly: monthlyCamArr[i] })
            })

            dailySppArr.map((e, i) => {
                spp_data.push({ daily: e, weekly: weeklySppArr[i], monthly: monthlySppArr[i] })
            })
            dailyFbArr.map((e, i) => {
                fibonacci_data.push({ daily: e, weekly: weeklyFbArr[i], monthly: monthlyFbArr[i] })
            })
            dailyCPRArr.map((e, i) => {
                cpr_data.push({ daily: e, weekly: weeklyCPRArr[i], monthly: monthlyCPRArr[i] })
            })

            setCamarilladata(cam_data)
            setStandarddata(spp_data)
            setFibonacciData(fibonacci_data)
            setCprData(cpr_data)
            setPpData(pivortPoints_data)

            setPrevDC(data?.pre_CLOSE_DAY[0]?.PRE_CLOSE_DAY)
            setPrevWC(data?.pre_CLOSE_WEEK[0]?.PRE_CLOSE_WEEK)
            setPrevMC(data?.pre_CLOSE_MONTH[0]?.PRE_CLOSE_MONTH)

            setCprtlDaily(data?.dailyData?.CPRTL_D)
            setCprtlWeekly(data?.weeklyData?.CPRTL_W)
            setCprtlMonthly(data?.monthlyData?.CPRTL_M)

            setCprblDaily(data?.dailyData?.CPRBL_D)
            setCprblWeekly(data?.weeklyData?.CPRBL_W)
            setCprblMonthly(data?.monthlyData?.CPRBL_M)

            setCam_Keys({ dailyKeys: _dailyCamKeys, weeklyKeys: _weeklyCamKeys, monthlyKeys: _monthlyCamKeys })
            setSpp_Keys({ dailyKeys: _dailySppKeys, weeklyKeys: _weeklysppKeys, monthlyKeys: _monthlySppKeys })
            setFb_Keys({ dailyKeys: _dailyFbKeys, weeklyKeys: _weeklyFbKeys, monthlyKeys: _monthlyFbKeys })
            setCpr_Keys({ dailyKeys: _dailyCPRKeys, weeklyKeys: _weeklyCPRKeys, monthlyKeys: _monthlyCPRKeys })
            setPp_Keys({ dailyKeys: _dailyKeys, weeklyKeys: _weeklyKeys, monthlyKeys: _monthlyKeys })
        } else if (response?.data?.message) {
            setCamarilladata([])
        }
    }

    const fetchZones = async () => {
        let zdata = [];
        const zonesres = await companyZonesData(ETFvalue)
        if (zonesres?.data?.success) {
            setZonesData(zonesres?.data.data)

            let d_z_data = filterData(zonesres?.data.data, "d", "d");
            const dailyZArr = Object.values(d_z_data).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
            const _dailyZKeys = Object.keys(d_z_data)

            let w_z_data = filterData(zonesres?.data.data, "w", "w");
            const weeklyZArr = Object.values(w_z_data).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
            const _weeklyZKeys = Object.keys(w_z_data)

            let m_z_data = filterData(zonesres?.data.data, "m", "m");
            const monthlyZArr = Object.values(m_z_data).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
            const _monthlyZKeys = Object.keys(m_z_data)

            dailyZArr.map((e, i) => {
                zdata.push({ daily: e, weekly: weeklyZArr[i], monthly: monthlyZArr[i] })
            })

            setZ_Keys({ dailyKeys: _dailyZKeys, weeklyKeys: _weeklyZKeys, monthlyKeys: _monthlyZKeys, })
            setZonesData(zdata)
        } else {
            setZonesData([])
        }
    }
    const fetchFibonacciData = async () => {
        let fibonacciData = []
        const fibonacciRes = await companyFibonacciData(ETFvalue)

        if (fibonacciRes?.data?.success) {

            let Fib_data = fibonacciRes?.data

            let Fib_D_dailyArr = Object.values(Fib_data?.dailyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
            let Fib_D_dailyKeys = Object.keys(Fib_data?.dailyData)

            let Fib_D_weeklyArr = Object.values(Fib_data?.weeklyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
            let Fib_D_weeklyKeys = Object.keys(Fib_data?.weeklyData)

            let Fib_D_monthlyArr = Object.values(Fib_data?.monthlyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
            let Fib_D_monthlyKeys = Object.keys(Fib_data?.monthlyData)

            Fib_D_dailyArr.map((e, i) => {
                fibonacciData.push({ daily: e, weekly: Fib_D_weeklyArr[i], monthly: Fib_D_monthlyArr[i] })
            })
            setF_Keys({ dailyKeys: Fib_D_dailyKeys, weeklyKeys: Fib_D_weeklyKeys, monthlyKeys: Fib_D_monthlyKeys, })
            setFibonacciRes(fibonacciData)
        }
    }

    useEffect(() => {
        fetchCompany()
        fetchZones()
        fetchFibonacciData()
        const observationsInterVal_d_w = setInterval(() => {
            if (ETFvalue && token) {
                _getObservations_d_w(ETFvalue, token)
            }
        }, 30000);
        if (tickerResponse) {
            setTickerRes(tickerResponse)
        }
        // const intervalId = setInterval(() => {
        //     if (ETFvalue) {
        //         currentMP(ETFvalue);
        //     }
        // }, 2000);
        // return () => clearInterval(intervalId);
        return () => {
            clearInterval(observationsInterVal_d_w)
        };
    }, [ETFvalue,tickerResponse]);

    useMemo(() => {
        if (ETFvalue && token) {
            _getObservations_d_w(ETFvalue, token)
        }
    }, [ETFvalue, token])

    const get_p_p = (points, perc) => (
        <>
            <p style={{ fontSize: '11px', color: `${points >= 0 ? "#339249" : "#DA2424"}` }}>{points ? points : 0}</p>
            <p style={{ fontSize: '11px', color: `${perc >= 0 ? "#339249" : "#DA2424"}` }}>{`(${perc ? perc.toFixed(2) : 0}%)`}</p>
        </>)

    const getPercentage = (i) => {
        switch (i) {
            case 0:
                let prec_D = ((tickerRes?.lastTradedPrice - tickerResponse?.closePrice) / tickerResponse?.closePrice) * 100
                let D_points = (tickerRes?.lastTradedPrice - tickerResponse?.closePrice)?.toFixed(2)
                return get_p_p(D_points, prec_D)
            case 1:
                let W_points = (tickerRes?.lastTradedPrice - prevWC)?.toFixed(2)
                let prec_W = ((tickerRes?.lastTradedPrice - prevWC) / prevWC) * 100
                return get_p_p(W_points, prec_W)
            case 2:
                let M_points = (tickerRes?.lastTradedPrice - prevMC)?.toFixed(2)
                let prec_M = ((tickerRes?.lastTradedPrice - prevMC) / prevMC) * 100
                return get_p_p(M_points, prec_M)
            default:
                return <p>0</p>
        }
    }

    const getObservations = (tf) => {
        switch (tf) {
            case "D":
                let d_obser = {
                    arrow: obj_observation_d_w?.observe_dailyData[0]?.arrow, poscount: obj_observation_d_w?.observe_dailyData[0]?.poscount, negcount: obj_observation_d_w?.observe_dailyData[0]?.negcount,
                    neutralcount: obj_observation_d_w?.observe_dailyData[0]?.neutralcount, totalMetrics: obj_observation_d_w?.observe_dailyData[0]?.totalMetrics,rest_data: obj_observation_d_w?.observe_dailyData[0]
                }
                return d_obser
            case "W":
                let w_obser = {
                    arrow: obj_observation_d_w?.observe_weeklyData[0]?.arrow, poscount: obj_observation_d_w?.observe_weeklyData[0]?.poscount, negcount: obj_observation_d_w?.observe_weeklyData[0]?.negcount,
                    neutralcount: obj_observation_d_w?.observe_weeklyData[0]?.neutralcount, totalMetrics: obj_observation_d_w?.observe_weeklyData[0]?.totalMetrics,rest_data: obj_observation_d_w?.observe_weeklyData[0]
                }
                return w_obser
            case "M":
                let m_obser = {
                    arrow: obj_observation_d_w?.observe_monthlyData[0]?.arrow, poscount: obj_observation_d_w?.observe_monthlyData[0]?.poscount, negcount: obj_observation_d_w?.observe_monthlyData[0]?.negcount,
                    neutralcount: obj_observation_d_w?.observe_monthlyData[0]?.neutralcount, totalMetrics: obj_observation_d_w?.observe_monthlyData[0]?.totalMetrics,rest_data: obj_observation_d_w?.observe_monthlyData[0]
                }
                return m_obser
            default:
                return {};
        }
    }
    
    const timeFrames = [
        {
            timeFrame: "Daily",
            curr_open: tickerRes?.openPrice?.toFixed(2),
            curr_high: tickerRes?.highPrice?.toFixed(2),
            curr_low: tickerRes?.lowPrice?.toFixed(2),
            curr_range: (tickerRes?.highPrice - tickerRes?.lowPrice)?.toFixed(2),
            prev_Range_perc: data?.dailyLowHigh?.length > 0 ? (((tickerRes?.lastTradedPrice - data?.dailyLowHigh[0]?.pre_low) / (data?.dailyLowHigh[0]?.pre_high - data?.dailyLowHigh[0]?.pre_low))?.toFixed(2) * 100)?.toFixed(2) : 0,
            prev_high: data?.dailyLowHigh?.length > 0 ? data?.dailyLowHigh[0]?.pre_high?.toFixed(2) : 0,
            prev_low: data?.dailyLowHigh?.length > 0 ? data?.dailyLowHigh[0]?.pre_low?.toFixed(2) : 0,
            prev_close: data?.pre_CLOSE_DAY?.length > 0 ? data?.pre_CLOSE_DAY[0]?.PRE_CLOSE_DAY?.toFixed(2) : 0,
            percentage: getPercentage(0),
            observations: getObservations("D")
        },
        {
            timeFrame: "Weekly",
            curr_open: data?.open_WEEK?.length > 0 ? data?.open_WEEK[0]?.OPEN_WEEK?.toFixed(2) : 0,
            curr_high: data.weeklyLowHigh?.length > 0 ? (tickerRes ? (data.weeklyLowHigh[0].high > tickerRes?.highPrice ? data.weeklyLowHigh[0].high : tickerRes?.highPrice) : data.weeklyLowHigh[0].high)?.toFixed(2) : 0,
            curr_low: data.weeklyLowHigh?.length > 0 ? (tickerRes ? (data.weeklyLowHigh[0].low < tickerRes?.lowPrice ? (data.weeklyLowHigh[0].low == 0 ? tickerRes?.lowPrice : data.weeklyLowHigh[0].low) : tickerRes?.lowPrice) : data.weeklyLowHigh[0].low)?.toFixed(2) : 0,
            // curr_range: data.weeklyLowHigh?.length > 0 ? (tickerRes ? (data.weeklyLowHigh[0].high > tickerRes?.highPrice ? data.weeklyLowHigh[0].high : tickerRes?.highPrice) : data.weeklyLowHigh[0].high)?.toFixed(2) - (tickerRes ? (data.weeklyLowHigh[0].low < tickerRes?.lowPrice ? (data.weeklyLowHigh[0].low == 0 ? tickerRes?.lowPrice : data.weeklyLowHigh[0].low) : tickerRes?.lowPrice) : data.weeklyLowHigh[0].low)?.toFixed(2) : 0,
            prev_Range_perc: data?.pre_weeklyLowHigh?.length > 0 ? ((((tickerRes?.lastTradedPrice - data?.pre_weeklyLowHigh[0]?.pre_low) / (data?.pre_weeklyLowHigh[0]?.pre_high - data?.pre_weeklyLowHigh[0]?.pre_low))?.toFixed(2)) * 100)?.toFixed(2) : 0,
            prev_high: data?.pre_weeklyLowHigh?.length > 0 ? data?.pre_weeklyLowHigh[0]?.pre_high?.toFixed(2) : 0,
            prev_low: data?.pre_weeklyLowHigh?.length > 0 ? data?.pre_weeklyLowHigh[0]?.pre_low?.toFixed(2) : 0,
            prev_close: data?.pre_CLOSE_WEEK?.length > 0 ? data?.pre_CLOSE_WEEK[0]?.PRE_CLOSE_WEEK?.toFixed(2) : 0,
            percentage: getPercentage(1),
            observations: getObservations("W")
        },
        {
            timeFrame: "Monthly",
            curr_open: data?.open_MONTH?.length > 0 ? data?.open_MONTH[0]?.OPEN_MONTH?.toFixed(2) : 0,
            curr_high: data.monthlyLowHigh?.length > 0 ? (tickerRes ? (data.monthlyLowHigh[0].high > tickerRes.highPrice ? data.monthlyLowHigh[0].high : tickerRes.highPrice) : data.monthlyLowHigh[0].high)?.toFixed(2) : 0,
            curr_low: data.monthlyLowHigh?.length > 0 ? (tickerRes ? (data.monthlyLowHigh[0].low < tickerRes.lowPrice ? (data.monthlyLowHigh[0].low == 0 ? tickerRes.lowPrice : data.monthlyLowHigh[0].low) : tickerRes.lowPrice) : data.monthlyLowHigh[0].low)?.toFixed(2) : 0,
            // curr_range: data.monthlyLowHigh?.length > 0 ? (tickerRes ? (data.monthlyLowHigh[0].high > tickerRes.highPrice ? data.monthlyLowHigh[0].high : tickerRes.highPrice) : data.monthlyLowHigh[0].high)?.toFixed(2) - (tickerRes ? (data.monthlyLowHigh[0].low < tickerRes.lowPrice ? (data.monthlyLowHigh[0].low == 0 ? tickerRes.lowPrice : data.monthlyLowHigh[0].low) : tickerRes.lowPrice) : data.monthlyLowHigh[0].low)?.toFixed(2) : 0,
            prev_Range_perc: data?.pre_monthlyLowHigh?.length > 0 ? (((tickerRes?.lastTradedPrice - data?.pre_monthlyLowHigh[0]?.pre_low) / (data?.pre_monthlyLowHigh[0]?.pre_high - data?.pre_monthlyLowHigh[0]?.pre_low))?.toFixed(2) * 100)?.toFixed(2) : 0,
            prev_high: data?.pre_monthlyLowHigh?.length > 0 ? data?.pre_monthlyLowHigh[0]?.pre_high?.toFixed(2) : 0,
            prev_low: data?.pre_monthlyLowHigh?.length > 0 ? data?.pre_monthlyLowHigh[0]?.pre_low?.toFixed(2) : 0,
            prev_close: data?.pre_CLOSE_MONTH?.length > 0 ? data?.pre_CLOSE_MONTH[0]?.PRE_CLOSE_MONTH?.toFixed(2) : 0,
            percentage: getPercentage(2),
            observations: getObservations("M")
        },
    ]
    return (
        <>
            {/* <Grid className='trading-ticker'>
                <Grid className="tview-ticker">
                    <Grid style={{ display: 'flex', alignItems: 'baseline' }}>
                        <Grid className="tview-context">{label}</Grid>
                        <Grid
                            className="tviewpricing green"
                        >
                            <p> {tickerRes?.lastTradedPrice ? tickerRes?.lastTradedPrice : 0}</p>
                            <p style={{ fontSize: '11px', color: '#857d7d', marginLeft: '5px' }}>{tickerRes ? (tickerRes?.lastTradedPrice - tickerRes?.closePrice)?.toFixed(2) : 0}</p>
                            <p style={{ fontSize: '11px', color: '#857d7d', marginLeft: '5px' }}>{`(${tickerRes.change ? (tickerRes.change)?.toFixed(2) : 0}%)`}</p>
                        </Grid>
                    </Grid>
                </Grid>
                <TimeFrameContainer timeFrames={timeFrames} />
            </Grid> */}
            <Grid className='trading-ticker' style={{ borderBottom: "1px solid lightgrey", top: "138px", boxShadow: "none" }}>
                <TimeFrameContainer timeFrames={timeFrames} tickerData ={tickerRes}/>
            </Grid>
            <Grid className="invest-tables-etfs">
                <Grid className='etf-col'>
                    <AnalysisTables value={value} heading="Camarilla Pivot Point Analysis" timeFramesForDataTable={timeFramesForDataTable} data={camarillaData} keys={cam_keys} h_l_keys={cam_key_s} />
                    <AnalysisTables value={value} heading="Standard Pivot Point Analysis" timeFramesForDataTable={timeFramesForDataTable} data={standardData} keys={spp_keys} h_l_keys={spp_key_s} />
                </Grid>
                <Grid className='etf-col'>
                    <AnalysisTables value={value} heading="Zones Analysis" timeFramesForDataTable={timeFramesForDataTable} data={zonesData} keys={z_keys} zones={true} h_l_keys={z_key_s} />
                    <AnalysisTables value={value} heading="Fibonacci Pivot Point Analysis" timeFramesForDataTable={timeFramesForDataTable} data={fibonacciData} keys={fb_keys} h_l_keys={fpp_key_s} />
                    <AnalysisTables value={value} heading="Central Pivot Range (CPR) Analysis" timeFramesForDataTable={timeFramesForDataTable} data={cprData} keys={cpr_keys} h_l_keys={cpr_key_s} />
                </Grid>
                <Grid className='etf-col'>
                    <AnalysisTables value={value} heading="All Pivot Points Analysis" timeFramesForDataTable={timeFramesForDataTable} data={ppData} keys={pp_keys} />
                </Grid>
                <Grid className='etf-col'>
                    <AnalysisTables value={value} heading="Fibonacci Retracement & Extension Analysis" timeFramesForDataTable={timeFramesForDataTable} data={fibonacciRes} keys={f_keys} h_l_keys={fre_key_s} fibonacci={true} />
                </Grid>
            </Grid>
        </>
    )
}

export default EtfsTraderView
