import { Grid } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import './AppHeader.css';
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { IndexMoversData } from "../apiService/tickerApiService";

function HeaderTicker() {
    const [data, setData] = useState([]);

    const indiceDataForCandles = async () => {
        let _c = await IndexMoversData('nifty50', 0, 'D');
        setData(_c?.data);
    };

    useEffect(() => {
        let intervalId = setInterval(() => {
            indiceDataForCandles();
        }, 4000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    useMemo(() => {
        indiceDataForCandles();
    }, []);

    const renderCompanyDetails = (item) => (
        <Grid className="company-details" key={item.companyData?.companySymbol}>
            <Grid className="company-name">{item.companyData?.companySymbol}</Grid>
            <Grid className="company-currentprice" style={{ color: item?.currentprice > item.pre_close ? "green" : "red", margin:"0px 6px",fontWeight:'bold',fontFamily:'Futura PT Heavy' }} >{item?.currentprice}</Grid>
            {/* <Grid className="company-symbol">{item.changePercentage?.toFixed(2)}</Grid> */}
            {item.changePercentage > 0 ? (
                <Grid className="percent-green">
                    <span style={{fontSize:'12px'}}>{item.changePercentage?.toFixed(2)}</span>% <ArrowDropUp />
                </Grid>
            ) : (
                <Grid className="percent-red">
                     <span style={{fontSize:'12px'}}>{item.changePercentage?.toFixed(2)}</span>% <ArrowDropDown />
                </Grid>
            )}
        </Grid>
    );

    return (
        <Grid className="header-ticker">
            {data.map((item) => renderCompanyDetails(item))}
            {data.map((item) => renderCompanyDetails(item)) /* Duplicate content for seamless scrolling */}
        </Grid>
    );
}

export default HeaderTicker;
