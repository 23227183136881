import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppHeader from '../AppHeader/AppHeader';
import { AppLogo } from '../icons/AppIcon';
import clsx from 'clsx';
import HeaderTicker from '../AppHeader/HeaderTicker';
import { Grid } from '@material-ui/core';

const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // width: '200%',
  },
  // drawer: {
  //   [theme.breakpoints.up('sm')]: {
  //     width: drawerWidth,
  //     flexShrink: 0,
  //   },
  // },
  appBar: {
    // [theme.breakpoints.up('sm')]: {
    //   width: `calc(100% - ${drawerWidth}px)`,
    //   marginLeft: drawerWidth,
    // // },
    // transition: theme.transitions.create(['width', 'margin'], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor:'#ffffff'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  hide: {
    display: 'none',
  },
  // necessary for content to be below app bar
  toolbar: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'flex-end',
    // padding: theme.spacing(0, 1),
    // marginTop: `calc(100-10vh)`,
    ...theme.mixins.toolbar,
    marginTop : "4px"
  }
  ,
  drawerContainer: {
    overflow: 'auto',
  },
  // drawerOpen: {
  //   width: drawerWidth,
  //   transition: theme.transitions.create('width', {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  // },
  // drawerClose: {
  //   transition: theme.transitions.create('width', {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.leavingScreen,
  //   }),
  //   overflowX: 'hidden',
  //   width: theme.spacing(7) + 1,
  //   [theme.breakpoints.up('sm')]: {
  //     width: theme.spacing(9) + 1,
  //   },
  // },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
  },
  drawerPaper: {
    width: drawerWidth,
  }
}));

function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  // const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };



  // const drawer = (
  //   <div className='mt-4'>
  //     <AppLogo/>
  //     <Divider />
  //     <List>
  //       {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
  //         <ListItem button key={text}>
  //           <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
  //           <ListItemText primary={text} />
  //         </ListItem>
  //       ))}
  //     </List>
  //     <Divider />
  //     <List>
  //       {['All mail', 'Trash', 'Spam'].map((text, index) => (
  //         <ListItem button key={text}>
  //           <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
  //           <ListItemText primary={text} />
  //         </ListItem>
  //       ))}
  //     </List>
  //     <div className={classes.toolbar}>
  //     <IconButton onClick={handleDrawerToggle}>
  //           {theme.direction === 'rtl' ?  !mobileOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />: mobileOpen ? <ChevronLeftIcon/>: <ChevronRightIcon/>}
  //     </IconButton>
  //     </div>
  //   </div>
  // );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      {/* <CssBaseline /> */}
          <HeaderTicker />
      <AppBar position="fixed" className={classes.appBar} style={{ background: 'white', borderBottom:'1px solid lightgray',position:'fixed',top:'30px'}}>
        {/* <Toolbar disableGutters={true}> */}
      
          {/* <Grid className='d-flex' style={{width:'100%'}}> */}
            <AppHeader containerClass="m-0" NavabarList={props.NavabarList} page={props.page} setPage={props.setPage}
              submenu = {props.submenu} setSubmenu={props.setSubmenu}
              submenuEtfs={props.submenuEtfs} setSubmenuEtfs={props.setSubmenuEtfs}
              setSubmenuIndecies={props.setSubmenuIndecies}
              setSubmenuHm={props.setSubmenuHm}
            />
          {/* </Grid> */}
        {/* </Toolbar> */}
      </AppBar>
      {/* <nav className={classes.drawer} aria-label="mailbox folders">
        The implementation can be swapped with js to avoid SEO duplication of links.
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            // classes={{
            //   paper: classes.drawerPaper,
            // }}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: mobileOpen,
                [classes.drawerClose]: !mobileOpen,
              }),
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
             classes={{
              paper: clsx({
                [classes.drawerOpen]: mobileOpen,
                [classes.drawerClose]: !mobileOpen,
              }),
            }}
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: mobileOpen,
              [classes.drawerClose]: !mobileOpen,
            })}
            open
          >
            <>
            {drawer}
            </>
          </Drawer>
        </Hidden>
      </nav> */}
      <main className={classes.content}>
        {/* <div className={classes.toolbar} style={{minHeight:'20px !important'}} /> */}
        {props.mainContent}
      </main>
    </div>
  );
}

export default ResponsiveDrawer;
