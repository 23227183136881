import { validateCaptcha } from 'react-simple-captcha';
import { emailRegex, passwordRegex } from '../../utils/index';

export default function validateLogin(values) {
    let errors = {
        email: {},
        password: {},
        // captcha: {}
    };

    if (!values.email) {
        errors.email.message = "Enter your Email address"
    } else if (!emailRegex.test(values.email)) {
        errors.email.message = "Email address is invalid";
    }
    if (!values.password) {
        errors.password.message = "Password is required";
    } else if (!passwordRegex.test(values.password)) {
        errors.password.message = "Password should be min 8 characters having one number,one capital letter and one special character";
    }

    // if (!values.captcha) {
    //     errors.captcha.message = "Captcha is required";
    //     values.captcha=''
    // }
    // else if (!validateCaptcha(values.captcha)) {
    //     errors.captcha.message = "Invalid Captcha Code";
    //     values.captcha=''
    // }
    return errors;
}
