import React, { useEffect, useMemo, useState } from 'react'
import { NiftyPlusData } from '../apiService/tickerApiService'
import { Grid, Typography } from '@material-ui/core'
import MetricCandleStick from '../common/MetricCandleStick'
import './Indexmovers.css';
import { getColorPr, rangePerc } from '../../utils';
import GaugeChart from '../common/GaugeChart';
import GaugeChartForDatatable from '../common/GaugeChartForDatatable';

function NiftyPlus() {
    const [dailyData, setDailyData] = useState([])
    const [weeklyData, setWeeklyData] = useState([])
    const [monthlyData, setMonthlyData] = useState([])
    const [quarterlyData, setQuarterlyData] = useState([])
    const [halfYearlyData, setHalfYearlylyData] = useState([])
    const [yearlyData, setYearlyData] = useState([])

    const fetchDataForNiftyPlus = async () => {
        const d_data = await NiftyPlusData("D")
        const w_data = await NiftyPlusData("W")
        const m_data = await NiftyPlusData("M")
        const q_data = await NiftyPlusData("Q")
        const h_data = await NiftyPlusData("H")
        const y_data = await NiftyPlusData("Y")

        setDailyData(d_data?.data)
        setWeeklyData(w_data?.data)
        setMonthlyData(m_data?.data)
        setQuarterlyData(q_data?.data)
        setHalfYearlylyData(h_data?.data)
        setYearlyData(y_data?.data)
    }

    useEffect(() => {
        let intervalId = setInterval(() => {
            fetchDataForNiftyPlus()
        }, 4000)
        return () => clearInterval(intervalId)
    }, [dailyData])

    useMemo(() => {
        fetchDataForNiftyPlus()
    }, [])
    const getTF = (i) => {
        switch (i) {
            case 0: return "Daily"
            case 1: return "Weekly"
            case 2: return "Monthly"
            case 3: return "Quarterly"
            case 4: return "Half Yearly"
            case 5: return "Yearly"
        }
    }
    const getPerc = (cmp, pc) => {
        let a = ((cmp - pc) / pc) * 100
        return a?.toFixed(1)
    }
    return (
        <Grid className='niftyplus flex w-full h-full gap-1 -ml-2'>
            {[dailyData, weeklyData, monthlyData, quarterlyData, halfYearlyData, yearlyData]?.map((indice, index) => {
                const nifty50Data = indice?.find(e => e?.companyData?.companySymbol === "NIFTY50")
                const bnniftyData = indice?.find(e => e?.companyData?.companySymbol === "BANKNIFTY")
                const itniftyData = indice?.find(e => e?.companyData?.companySymbol === "ITNIFTY")
                const relData = indice?.find(e => e?.companyData?.companySymbol === "RELIANCE")
                return (
                    <Grid className='w-1/6 border-timeframe indice-border p-0'>
                        <Typography align='center' className='font-semibold header-timeframe' style={{ fontSize: '14px' }}>{getTF(index)}</Typography>
                        {[nifty50Data, bnniftyData, itniftyData, relData]?.map((s, i) => {
                            // debugger
                            return (
                                <Grid className='nse-border'>
                                    <GaugeChartForDatatable value={s?.percentage?.toFixed(2)} open={s?.current_open ? s?.current_open : s?.ticker_open} high={s?.current_high >= s?.ticker_high ? s?.current_high : s?.ticker_high} low={s?.current_low >= s?.ticker_low ? s?.ticker_low : s?.current_low == 0 ? s?.ticker_low : s?.current_low}
                                            close={s?.current_close}  prev_range={Number.isFinite(s?.pre_percentage) ? s?.pre_percentage : 0} cmp={s?.currentprice} previousclose={s?.pre_close?.toFixed(1)}/>
                                    <Grid className="flex justify-center">
                                        <Typography variant='body2' className="font-semibold flex whitespace-no-wrap" align="center" style={{ color: `${s?.currentprice > s?.pre_close ? "#000066" : "#DA2424"}` }}>{s?.companyData?.companySymbol}
                                            <span className="flex items-center" style={{ fontSize: '12px', marginLeft: '3px' }}>{s?.currentprice}</span>
                                            <span className="flex items-center" style={{ fontSize: '10px' }}>({(s?.currentprice - s?.pre_close).toFixed(1)})({getPerc(s?.currentprice, s?.pre_close)}%)</span>
                                        </Typography>
                                    </Grid>
                                    {/* <Grid className='im-ohlc flex w-full'>
                                        <Grid className="im-lc w-1/3">
                                            <Typography align="center" className="high-color">C.R: {((s?.current_high >= s?.ticker_high ? s?.current_high : s?.ticker_high) - (s?.current_low >= s?.ticker_low ? s?.ticker_low : s?.current_low == 0 ? s?.ticker_low : s?.current_low))?.toFixed(1)}</Typography>
                                            <Typography align="center" className={(s?.pre_high - s?.pre_low) > 0 ? "high-color" : "low-color"}>P.R: {Number(s?.pre_low) ? (s?.pre_high - s?.pre_low)?.toFixed(1) : 0}</Typography>
                                        </Grid>
                                        <Grid className="im-oh w-1/3">
                                            <Typography align="center" className="high-color">O: {s?.current_open == 0 ? s?.ticker_open : s?.current_open?.toFixed(1)}</Typography>
                                            <Typography align="center" className="high-color">P.C: {s?.pre_close?.toFixed(1)}</Typography>
                                        </Grid>
                                        <Grid className="im-rp w-1/3">
                                            <Typography align="center" className="high-color">H: {s?.current_high >= s?.ticker_high ? s?.current_high?.toFixed(1) : s?.ticker_high?.toFixed(1)}</Typography>
                                            <Typography align="center" className="low-color">L: {s?.current_low >= s?.ticker_low ? s?.ticker_low?.toFixed(1) : s?.current_low == "0" ? s?.ticker_low?.toFixed(1) : s?.current_low?.toFixed(1)} </Typography>
                                        </Grid>
                                    </Grid> */}
                                    <Grid className='standard-observ-indices'>
                                        <MetricCandleStick  cmp={s?.currentprice} percentage={s?.percentage} open={s?.current_open ? s?.current_open : s?.ticker_open} high={s?.current_high >= s?.ticker_high ? s?.current_high : s?.ticker_high} low={s?.current_low >= s?.ticker_low ? s?.ticker_low : s?.current_low == 0 ? s?.ticker_low : s?.current_low}
                                            close={s?.current_close} poscount={s?.poscount} negcount={s?.negcount} arrow={s?.arrow} totalMetrics={s?.totalMetrics} prev_range={s?.pre_percentage}
                                        />
                                    </Grid>
                                </Grid>)
                        })}
                    </Grid>)
            })}
        </Grid>
    )
}

export default NiftyPlus