export const emailRegex = /\S+@\S+\.\S+/;
export const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z]).{8,}$/
export const phoneNumberRegex = /^[6-9]\d{9}$/;
export const nameRegex = /^[a-zA-Z]+$/;

function dateToCustomString(date) {
  const year = date.getFullYear();
  const month = date.toLocaleString('default', { month: 'short' });
  const day = date.getDate();
  const customString = `${day.toString().padStart(2, '0')} ${month}  ${year}`;

  return customString;
}

function getCurrentWeekDates() {
  const now = new Date();
  const today = now.getDay();
  const monday = new Date(now);
  monday.setDate(now.getDate() - (today - 1));

  const friday = new Date(monday);
  friday.setDate(monday.getDate() + 4);

  return { monday: monday, friday: friday }
};

export const getDateDurationFromYearStartToEnd = () => {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const startOfYear = dateToCustomString(new Date(currentYear, 0, 1))
  const endOfYear = dateToCustomString(new Date(currentYear, 11, 31))

  return `${startOfYear} to ${endOfYear}`
}

export const getDateDurationFromYearStartToHalf = () => {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()

  if (currentMonth < 6) {
    const startOfYear = dateToCustomString(new Date(currentYear, 0, 1))
    const middleOfYear = dateToCustomString(new Date(currentYear, 6, 0))

    return `${startOfYear} to ${middleOfYear}`
  } else {
    const middleOfYear = dateToCustomString(new Date(currentYear, 5, 31))
    const endOfYear = dateToCustomString(new Date(currentYear, 11, 31))

    return `${middleOfYear} to ${endOfYear}`
  }
}

export const getDateDurationFromQuarterOfYear = () => {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth()

  if (0 <= currentMonth && currentMonth <= 2) {
    const startOfq = dateToCustomString(new Date(currentYear, 0, 1))
    const endOfq = dateToCustomString(new Date(currentYear, 3, 0))

    return `${startOfq} to ${endOfq}`
  } else if (3 <= currentMonth && currentMonth <= 5) {
    const startOfq = dateToCustomString(new Date(currentYear, 3, 1))
    const endOfq = dateToCustomString(new Date(currentYear, 6, 0))

    return `${startOfq} to ${endOfq}`
  } else if (6 <= currentMonth && currentMonth <= 8) {
    const startOfq = dateToCustomString(new Date(currentYear, 6, 1))
    const endOfq = dateToCustomString(new Date(currentYear, 9, 0))

    return `${startOfq} to ${endOfq}`
  } else if (9 <= currentMonth && currentMonth <= 11) {
    const startOfq = dateToCustomString(new Date(currentYear, 9, 1))
    const endOfq = dateToCustomString(new Date(currentYear, 12, 0))

    return `${startOfq} to ${endOfq}`
  }
}

export const getCurrentMonth = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();

  const firstDay = dateToCustomString(new Date(year, month, 1))
  const lastDay = dateToCustomString(new Date(year, month + 1, 0))

  return `${firstDay} to ${lastDay}`
}

export const getCurrentWeek = () => {
  const weekDates = getCurrentWeekDates();
  let mon = dateToCustomString(weekDates.monday)
  let fri = dateToCustomString(weekDates.friday)

  return `${mon} to ${fri}`
}

export const getCurrentDate = () => {
  const currentDate = new Date()
  return dateToCustomString(currentDate)
}

export const cam_key_s = ["H6", "H5", "H4", "H3", "H2", "H1", "L1", "L2", "L3", "L4", "L5", "L6"]
export const spp_key_s = ["R3", "R2", "R1", "S1", "S2", "S3",]
export const fpp_key_s = ["R2", "R1", "S1", "S2",]
export const cpr_key_s = ["TL", "PP", "BL"]
export const z_key_s = ["+2", "+2", "+1", "+1", "0", "0", "0", "-1", "-1", "-2", "-2"]
export const fre_key_s = ["250%", "238.2%", "223.6%", "200%", "178.6%", "161.8%", "150%", "138.2%", "123.6%",
  "100%", "78.6%", "61.8%", "50%", "38.2%", "23.6%", "0%", "-23.6%", "-38.2%", "-50%", "-61.8%", "-78.6%",
  "-100.0%", "-123.6%", "-138.2%", "-150.0%"]
export const gann_key_s = ["720", "675", "630", "585", "540", "495", "450", "405", "360",
  "315", "270", "225", "180", "135", "90", "45", "0", "-45", "-90", "-135", "-180",
  "-225", "-270", "-315", "-360","-405","-450","-495","-540","-585","-630","-675","-720"]

export const getTooltip = (e) => {
  switch (e) {
    case "Yearly":
      let stry = getDateDurationFromYearStartToEnd()
      return stry
    case "Half Yearly":
      let strhy = getDateDurationFromYearStartToHalf()
      return strhy
    case "Quarterly":
      let strq = getDateDurationFromQuarterOfYear()
      return strq
    case "Monthly":
      let strm = getCurrentMonth()
      return strm
    case "Weekly":
      let strw = getCurrentWeek()
      return strw
    case "Daily":
      let strd = getCurrentDate()
      return strd
    default:
      return e
  }
}

export function getColorPr(val) {
  if (val >= 61.8) return "#339249"
  else if (val <= 38.2) return "#DA2424"
  else return "#4D8FCC"
}

function getCurrentISTTime() {
  const now = new Date();
  // UTC time + 5:30 hours for IST
  const ISTOffset = 5.5 * 60 * 60 * 1000;
  const ISTTime = new Date(now.getTime() + ISTOffset);
  // Extract hours and minutes
  const hours = ISTTime.getUTCHours();
  const minutes = ISTTime.getUTCMinutes();
  return { hours, minutes };
}

export function isBetween9AMAnd340PM() {
  const { hours, minutes } = getCurrentISTTime();
  // Convert current time to minutes since midnight
  const currentTimeInMinutes = hours * 60 + minutes;
  // Calculate minutes since 9:00 AM (9 * 60)
  const startMinutes = 9 * 60;
  // Calculate minutes since 15:40 PM (15 * 60 + 40)
  const endMinutes = 15 * 60 + 30;
  // Check if current time is between 9:00 AM and 15:40 PM
  return currentTimeInMinutes >= startMinutes && currentTimeInMinutes <= endMinutes;
}

export function rangePerc(cmp, h, l) {
  const result = ((cmp - l) / (h - l)) * 100
  return result?.toFixed(2)
}

export function getPrevVal(obj, key, h_l_key) {
  if (Object.values(obj).length > 0) return obj[key][0][h_l_key]
} 