import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import { Typography } from '@material-ui/core';

const GaugeChartForDatatable = (props) => {
    // const { value, hold, bg, high, low, open, close, prev_range } = props
    const { value, high, low, open, close, pre_percentage, cmp, previousclose, prev_range, hold, bg, curr_range } = props
    const chartRef = useRef(null);
    const [high_v, setHigh_v] = useState(0);
    const [low_v, setLow_v] = useState(0);
    const h_l_diff = high_v - low_v
    const l_prec = ((close - low_v) / h_l_diff) * 100
    useEffect(() => {
        let h = Math.max(high, open, close)
        let l = Math.min(low, open, close)
        setHigh_v(h)
        setLow_v(l)

        const dom = chartRef.current;
        dom.style.padding = "-20px 0px 0px 0px";
        const myChart = echarts.init(dom, null, {
            renderer: 'canvas',
            useDirtyRect: false
        });

        const gaugeData = [
            {
                value: value,
                name: hold,
                title: {
                    offsetCenter: ['-10%', '0%'],
                    fontSize: "14px",
                    fontFamily: "Futura PT Demi",
                    color: "#000000"
                },
                detail: {
                    offsetCenter: ['-10%', '10%'],
                    fontSize: "11px",
                    formatter: () => `${value ? value : 0}%`,  // Dynamic
                    fontFamily: "Futura PT Demi",
                },
                pointer: {
                    length: '50%',
                    width: 7,
                    itemStyle: {
                        color: 'auto',
                        borderWidth: 1,
                        borderColor: '#D3D3D3',
                    },
                },
            },
        ]

        const option = {
            series: [
                {
                    type: 'gauge',
                    axisLine: {
                        lineStyle: {
                            width: 10,
                            color: [
                                [0.20, '#da2424'],   // Dark red for 20-40%
                                [0.40, '#f74f4f'],   // Red for 0-20%
                                [0.60, '#ffb200e3'], // Orange for 40-60%
                                [0.80, '#44db67'],      // Light green for 80-100%
                                [1, '#339249'],   // Green for 60-80%
                            ]
                        }
                    },
                    pointer: {
                        length: '90%',
                        itemStyle: {
                            color: 'auto'
                        }
                    },
                    axisTick: {
                        distance: 0,
                        length: 0,
                        lineStyle: {
                            color: '#fff',
                            width: 2
                        }
                    },
                    splitLine: {
                        distance: 0,
                        length: 0,
                        lineStyle: {
                            color: '#fff',
                            width: 4
                        }
                    },
                    axisLabel: {
                        color: 'inherit',
                        distance: 32,
                        fontSize: 0
                    },
                    detail: {
                        valueAnimation: true,
                        formatter: `${props?.value ? props?.value : 0}%`,
                        color: getColor(props?.value),
                        fontSize: 9
                    },
                    data: gaugeData
                }
            ]
        };

        if (option && typeof option === 'object') {
            myChart.setOption(option);
        }

        const interval = setInterval(() => {
            myChart.setOption({
                series: [
                    {
                        data: [
                            {
                                value: props?.value ? props?.value : 0
                            }
                        ]
                    }
                ]
            });
        }, 2000);

        window.addEventListener('resize', myChart.resize);

        // Cleanup on component unmount
        return () => {
            clearInterval(interval);
            window.removeEventListener('resize', myChart.resize);
            myChart.dispose();
        };
    }, [value, high, low]);

    const getHold = (v) => {
        if (v <= 20) return "Bearish++"
        else if (v > 20 && v <= 40) return "Bearish+"
        else if (v > 40 && v <= 60) return "Neutral"
        else if (v > 60 && v <= 80) return "Bullish+"
        else if (v > 80) return "Bullish++"
    }
    const getShortAndLong = (prev_range, previousClose, open, curr_range) => {
        // debugger
        let percentage = (prev_range + (curr_range ? curr_range : l_prec)) / 2

        if (percentage <= 38.2 && ((cmp < previousClose) || (cmp < open))) {
            return 'No Long';
        }
        else if (percentage >= 61.8 && ((cmp > previousClose || cmp > open))) {
            return 'No Short'
        }
        else {
            return 'No Action';
        }

    }
    const getShortAndLongColor = (prev_range, previousClose, open, curr_range) => {
        let percentage = (prev_range + (curr_range ? curr_range : l_prec)) / 2
        if ((percentage <= 38.2 && ((cmp < previousClose) || (cmp < open)))) return '#da2424';
        else if ((percentage >= 61.8 && ((cmp > previousClose) || (cmp > open)))) return '#339249';
        else {
            return '#ffa100e3';
        }
    }
    const getColor = (v) => {
        if (v <= 20) return "#da2424"
        else if (v > 20 && v <= 40) return "#f74f4f"
        else if (v > 40 && v <= 60) return "#ffb200e3"
        else if (v > 60 && v <= 80) return "#44db67"
        else if (v > 80) return "#339249"
    }

    return (
        <div className='-pt-4'>
            <div ref={chartRef} className='-pt-4 -mb-10' style={{ width: '100%', height: '180px', backgroundColor: bg ? bg : "#fff" }}></div>
            <Typography align='center' style={{ color: getColor(value), marginTop: "-4rem", fontWeight: "bold", fontSize: "12px" }}>{getHold(value)}</Typography>
            <Typography align='center' style={{ color: getShortAndLongColor(prev_range, previousclose, open, curr_range), marginTop: "0rem", fontWeight: "bold", fontSize: "12px" }}>{getShortAndLong(prev_range, previousclose, open, curr_range)}</Typography>
        </div>
    );
};

export default GaugeChartForDatatable;
