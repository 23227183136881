import { Grid, Typography } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { NiftyPlusPlus, superTrendsDatesApi, superTrendsMonthlyDatesApi, superTrendsWeeklyDatesApi } from '../apiService/tickerApiService'
import MetricCandleStick from '../common/MetricCandleStick'
import IndicesStrip from '../common/IndicesStrip'
import GaugeChartNV from '../common/GaugeChartNV'
import GaugeChartTF from '../common/GuageChartTF'
import IndiceStripNew from '../common/IndiceStripNew'
import { ArrowDownward, ArrowUpward } from '@material-ui/icons'

const styles = {
    container: {
        maxWidth: "600px",
        margin: "20px auto",
        padding: "10px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#f9f9f9",
    },
    heading: {
        textAlign: "center",
        fontSize: "1rem",
        color: "#333",
        marginBottom: "15px",
        fontWeight: "bold",
    },
    table: {
        width: "100%",
        borderCollapse: "collapse",
    },
    th: {
        backgroundColor: "#add8e6",
        color: "#333",
        fontWeight: "bold",
        padding: "5px 0px",
        border: "1px solid #ddd",
        textTransform: "uppercase",
        fontSize: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center",
    },
    td: {
        padding: "10px",
        textAlign: "center",
        border: "1px solid #ddd",
        color: "#555",
    },
    rowEven: {
        backgroundColor: "#f4f4f4",
    },
    rowOdd: {
        backgroundColor: "#ffffff",
    },
};

function GraphView(props) {
    const { value, dataFromChild, showTop, indices } = props
    const { token, companySymbol } = dataFromChild
    const [speedoMeterData, setSpeedoData] = useState([])
    const [SuperTrends, setSuperTrends] = useState([])
    const [SuperTrendsWeelky, setSuperTrendsWeelky] = useState([])
    const [SuperTrendsMonthly, setSuperTrendsMonthly] = useState([])

    const stKeys = Object.keys(SuperTrends).filter((key) => key.startsWith("st")).sort((a, b) => { return SuperTrends[b] - SuperTrends[a]; })

    const stWeeklyKeys = Object.keys(SuperTrendsWeelky).filter((key) => key.startsWith("st")).sort((a, b) => { return SuperTrendsWeelky[b] - SuperTrendsWeelky[a]; })

    const stMonthlyKeys = Object.keys(SuperTrendsMonthly).filter((key) => key.startsWith("st")).sort((a, b) => { return SuperTrendsMonthly[b] - SuperTrendsMonthly[a]; })

    const fetchDataForSpeedometer = async (token, companySymbol) => {
        const company_speedo_data = await NiftyPlusPlus(companySymbol, token)
        setSpeedoData(company_speedo_data?.data)
    }

    const superTrends = async () => {
        try {
            const _superTrends = await superTrendsDatesApi(companySymbol);
            if (_superTrends?.data?.data) {
                setSuperTrends(_superTrends.data.data);
            } else {
                setSuperTrends([]);
            }
        } catch (error) {
            setSuperTrends([]);
        }
    };
    // superTrendsMonthlyDatesApi
    const superTrendsWeekly = async () => {
        try {
            const _superTrendsWeekly = await superTrendsWeeklyDatesApi(companySymbol);
            if (_superTrendsWeekly?.data?.data) {
                setSuperTrendsWeelky(_superTrendsWeekly.data.data);
            } else {
                setSuperTrendsWeelky([])
            }
        } catch (error) {
            setSuperTrendsWeelky([])
        }
    };

    const superTrendsMonthly = async () => {
        try {
            const _superTrendsWeekly = await superTrendsMonthlyDatesApi(companySymbol);
            if (_superTrendsWeekly?.data?.data) {
                setSuperTrendsMonthly(_superTrendsWeekly.data.data);
            } else {
                setSuperTrendsMonthly([])
            }
        } catch (error) {
            setSuperTrendsMonthly([])
        }
    };

    useEffect(() => {
        let intervalId
        if (token && companySymbol) {
            intervalId = setInterval(() => {
                fetchDataForSpeedometer(token, companySymbol)
            }, 4000)
        }
        return () => {
            if (intervalId) {
                setSpeedoData([])
                clearInterval(intervalId)
            }
        }
    }, [token, companySymbol])


    // useMemo(() => {
    //     if (token && companySymbol) {
    //         fetchDataForSpeedometer(token, companySymbol)

    //         if (!(dataFromChild.inMidcap50 && dataFromChild.inMidcap150 && dataFromChild.inMidcap100)) {
    //             superTrends();
    //         } else {
    //             setSuperTrends([])
    //         }
    //     }
    // }, [token, companySymbol])

    // useMemo(() => {
    //     if (token && companySymbol) {
    //         fetchDataForSpeedometer(token, companySymbol)
    //         if (dataFromChild?.inNifty50 || indices) {
    //             superTrendsWeekly();
    //         } else {
    //             setSuperTrendsWeelky([]);
    //         }
    //     }
    // }, [token, companySymbol])

    useMemo(() => {
        if (token && companySymbol) {
            fetchDataForSpeedometer(token, companySymbol)

            if (!(dataFromChild.inMidcap50 && dataFromChild.inMidcap150 && dataFromChild.inMidcap100)) {
                superTrends();
                superTrendsWeekly();
                superTrendsMonthly()
            } else {
                setSuperTrends([])
                setSuperTrendsWeelky([]);
                setSuperTrendsMonthly([])
            }

            // if (dataFromChild?.inNifty50 || dataFromChild?.inNext50 || indices) {
            //     superTrendsWeekly();
            //     superTrendsMonthly()
            // } else {
            //     setSuperTrendsWeelky([]);
            //     setSuperTrendsMonthly([])
            // }
        }
    }, [token, companySymbol])


    const getPerc = (cmp, pc) => {
        let a = ((cmp - pc) / pc) * 100
        return a?.toFixed(2)
    }

    function roundToNearestFive(value) {
        return Math.ceil(value / 0.05) * 0.05;
    }
    const keyNames = (v) => {
        switch (v) {
            case "st1": return "1"
            case "st2": return "2"
            case "st3": return "3"
            case "st4": return "4"
            case "st5": return "5"
            case "st6": return "6"
            case "st7": return "7"
            case "st8": return "8"
            case "st9": return "9"
            case "st10": return "10"
        }
    }

    const calculatePositiveDifference = (keys, data) => {

        const positiveValues = keys
            .map((key) => data[key])
            .filter((value) => value > 0);
        if (positiveValues.length > 0) {
            const highest = Math.max(...positiveValues);
            const lowest = Math.min(...positiveValues);
            const highLowDifference = (highest - lowest).toFixed(2); // Difference between highest and lowest
            const cpmDifferenceLow = (speedoMeterData?.daily[0]?.currentprice - lowest).toFixed(2); // Lowest positive value (CPM)
            const percentage = ((cpmDifferenceLow / highLowDifference) * 100).toFixed(1);
            return percentage;
        }

        return "NDA";
    };
    return (
        <>
            {showTop && <Grid className="table-ticker">
                <Grid className='company-cmp'>
                    <Grid className="details">
                        <Grid className="details-context px-2 py-1"
                            style={{
                                color: '#fff',
                                marginLeft: '0px'
                            }}
                        >
                            {dataFromChild?.companySymbol}
                        </Grid>
                    </Grid>
                    <Grid >
                        <Grid className='pricing m-2'>
                            {speedoMeterData?.daily?.length > 0 &&
                                <Grid
                                    style={{
                                        color: `${speedoMeterData?.daily[0]?.pre_close <= speedoMeterData?.daily[0]?.currentprice ? 'rgb(51, 146, 73)' : 'rgb(218, 36, 36)'}`,
                                        display: "flex",
                                        alignItems: "center",
                                        backgroundColor: '#fff',
                                        padding: '0px 13px',
                                        borderRadius: '4px',
                                        //  height:'15px'
                                    }}>
                                    <p className='mr-1'>{speedoMeterData?.daily?.length > 0 ? speedoMeterData?.daily[0]?.currentprice : 0}</p>
                                    <p style={{ fontSize: '11px', color: '#857d7d' }}>{speedoMeterData?.daily?.length > 0 ? (speedoMeterData?.daily[0]?.currentprice - speedoMeterData?.daily[0]?.pre_close)?.toFixed(1) : 0}</p>
                                    <p style={{ fontSize: '11px', color: '#857d7d' }}>{`(${speedoMeterData?.daily?.length > 0 ? (((speedoMeterData?.daily[0]?.currentprice - speedoMeterData?.daily[0]?.pre_close) / speedoMeterData?.daily[0]?.pre_close) * 100)?.toFixed(1) : 0}%)`}</p>
                                    {/* ((ticker - prevDC) / prevDC) * 100 */}
                                </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className='flex'>
                    <IndiceStripNew showNifty50={true} showBankNifty={true} showITNifty={true} showNIFTYf={true} />
                </Grid>
            </Grid>}
            <Grid className='w-12/12 mt-1 mx-2 pb-8'>

                {Object.values(speedoMeterData)?.length > 0 ? [speedoMeterData].map((s, i) => {

                    const traderViewPercentages = [s?.daily?.length > 0 ? s?.daily[0]?.percentage?.toFixed(2) : 0, s?.weekly.length > 0 ? s?.weekly[0]?.percentage.toFixed(2) : 0, s?.monthly?.length > 0 ? s?.monthly[0]?.percentage.toFixed(2) : 0]

                    const traderOpen = [s?.daily[0]?.current_open ? s?.daily[0]?.current_open : s?.daily[0]?.ticker_open, s?.weekly[0]?.current_open ? s?.weekly[0]?.current_open : s?.weekly[0]?.ticker_open, s?.monthly[0]?.current_open ? s?.monthly[0]?.current_open : s?.monthly[0]?.ticker_open]

                    const tradaHigh = [s?.daily[0]?.current_high >= s?.daily[0]?.ticker_high ? s?.daily[0]?.current_high : s?.daily[0]?.ticker_high, s?.weekly[0]?.current_high >= s?.weekly[0]?.ticker_high ? s?.weekly[0]?.current_high : s?.weekly[0]?.ticker_high, s?.monthly[0]?.current_high >= s?.monthly[0]?.ticker_high ? s?.monthly[0]?.current_high : s?.monthly[0]?.ticker_high]

                    const traderLow = [s?.daily[0]?.current_low >= s?.daily[0]?.ticker_low ? s?.daily[0]?.ticker_low : s?.daily[0]?.current_low == 0 ? s?.daily[0]?.ticker_low : s?.daily[0]?.current_low, s?.weekly[0]?.current_low >= s?.weekly[0]?.ticker_low ? s?.weekly[0]?.ticker_low : s?.weekly[0]?.current_low == 0 ? s?.weekly[0]?.ticker_low : s?.weekly[0]?.current_low, s?.monthly[0]?.current_low >= s?.monthly[0]?.ticker_low ? s?.monthly[0]?.ticker_low : s?.monthly[0]?.current_low == 0 ? s?.monthly[0]?.ticker_low : s?.monthly[0]?.current_low]

                    const tradaClose = [s?.daily[0]?.current_close, s?.weekly[0]?.current_close, s?.monthly[0]?.current_close]

                    const tradaRange = [s?.daily[0]?.pre_percentage, s?.weekly[0]?.pre_percentage, s?.monthly[0]?.pre_percentage]

                    const previousclose = [s?.daily[0]?.pre_close, s?.weekly[0]?.pre_close, s?.monthly[0]?.pre_close]

                    const investorViewPercentages = [s?.quarterly?.length > 0 ? s?.quarterly[0]?.percentage.toFixed(2) : 0, s?.halfYearly.length > 0 ? s?.halfYearly[0]?.percentage.toFixed(2) : 0, s?.yearly?.length > 0 ? s?.yearly[0]?.percentage.toFixed(2) : 0]

                    const investorOpen = [s?.quarterly[0]?.current_open ? s?.quarterly[0]?.current_open : s?.quarterly[0]?.ticker_open, s?.halfYearly[0]?.current_open ? s?.halfYearly[0]?.current_open : s?.halfYearly[0]?.ticker_open, s?.yearly[0]?.current_open ? s?.yearly[0]?.current_open : s?.yearly[0]?.ticker_open]

                    const investorHigh = [s?.quarterly[0]?.current_high >= s?.quarterly[0]?.ticker_high ? s?.quarterly[0]?.current_high : s?.quarterly[0]?.ticker_high, s?.halfYearly[0]?.current_high >= s?.halfYearly[0]?.ticker_high ? s?.halfYearly[0]?.current_high : s?.halfYearly[0]?.ticker_high, s?.yearly[0]?.current_high >= s?.yearly[0]?.ticker_high ? s?.yearly[0]?.current_high : s?.yearly[0]?.ticker_high]

                    const investorLow = [s?.quarterly[0]?.current_low >= s?.quarterly[0]?.ticker_low ? s?.quarterly[0]?.ticker_low : s?.quarterly[0]?.current_low == 0 ? s?.quarterly[0]?.ticker_low : s?.quarterly[0]?.current_low, s?.halfYearly[0]?.current_low >= s?.halfYearly[0]?.ticker_low ? s?.halfYearly[0]?.ticker_low : s?.halfYearly[0]?.current_low == 0 ? s?.halfYearly[0]?.ticker_low : s?.halfYearly[0]?.current_low, s?.yearly[0]?.current_low >= s?.yearly[0]?.ticker_low ? s?.yearly[0]?.ticker_low : s?.yearly[0]?.current_low == 0 ? s?.yearly[0]?.ticker_low : s?.yearly[0]?.current_low]

                    const investorClose = [s?.quarterly[0]?.current_close, s?.halfYearly[0]?.current_close, s?.yearly[0]?.current_close]

                    const investorRange = [s?.quarterly[0]?.pre_percentage, s?.halfYearly[0]?.pre_percentage, s?.yearly[0]?.pre_percentage]
                    const previousclose_i = [s?.quarterly[0]?.pre_close, s?.halfYearly[0]?.pre_close, s?.yearly[0]?.pre_close]
                    return (
                        <>
                            <Grid className='w-full flex gap-2'>
                                <Grid className='w-1/3 border-timeframe plusplus-border p-0 bg-white'>
                                    <Grid className='header-timeframe'>
                                        {/* <Typography align='center' className='font-semibold font-futura' style={{ fontSize: '14px' }}>{companySymbol}</Typography> */}
                                        <Typography align='center' className='font-semibold font-futura' style={{ fontSize: '14px' }}>SHORT TERM </Typography>
                                    </Grid>
                                    <Grid className=''>
                                        <GaugeChartNV tf={["Daily", "Weekly", "Monthly"]} previousclose={previousclose} tradaRange={tradaRange} tradaClose={tradaClose} tradaHigh={tradaHigh} traderOpen={traderOpen} traderLow={traderLow} viewPercentages={traderViewPercentages} mean={((Number(traderViewPercentages[0]) + Number(traderViewPercentages[1]) + Number(traderViewPercentages[2])) / 3)?.toFixed(2)} cmp={s?.daily[0]?.currentprice} />
                                        <Grid className='indice-candle-nsePlusePluse' style={{ backgroundColor: 'lightblue' }}>
                                            <Grid className="flex justify-center -mb-1">
                                                <Typography variant='body2' className="font-black font-futura flex whitespace-no-wrap" align="center" style={{ fontSize: '12px', color: `${s?.daily[0]?.currentprice > s?.daily[0]?.pre_close ? "#000066" : "#DA2424"}` }}>(D) - {companySymbol}
                                                    <span className="flex items-center" style={{ fontSize: '12px', marginLeft: '3px' }}>{s?.daily[0]?.currentprice}</span>
                                                    <span className="flex items-center" style={{ fontSize: '10px' }}>({(s?.daily[0]?.currentprice - s?.daily[0]?.pre_close).toFixed(2)})({getPerc(s?.daily[0]?.currentprice, s?.daily[0]?.pre_close)}%)</span>
                                                </Typography>
                                            </Grid>
                                            <Grid style={{ marginTop: '4px' }}>
                                                <MetricCandleStick cmp={s?.daily[0]?.currentprice} open={s?.daily[0]?.current_open ? s?.daily[0]?.current_open : s?.daily[0]?.ticker_open}
                                                    high={s?.daily[0]?.current_high >= s?.daily[0]?.ticker_high ? s?.daily[0]?.current_high : s?.daily[0]?.ticker_high}
                                                    low={s?.daily[0]?.current_low >= s?.daily[0]?.ticker_low ? s?.daily[0]?.ticker_low : s?.daily[0]?.current_low == 0 ? s?.daily[0]?.ticker_low : s?.daily[0]?.current_low}
                                                    close={s?.daily[0]?.current_close} poscount={s?.daily[0]?.poscount} negcount={s?.daily[0]?.negcount} arrow={s?.daily[0]?.arrow} totalMetrics={s?.daily[0]?.totalMetrics} prev_range={s?.daily[0]?.pre_percentage}
                                                    percentage={s?.daily?.[0]?.percentage}
                                                />
                                            </Grid>
                                            <Grid className='im-ohlc flex w-full' >
                                                <Grid className="im-lc w-1/3">
                                                    <Typography align="center" className="high-color">C.R: {((s?.daily[0]?.current_high >= s?.daily[0]?.ticker_high ? s?.daily[0]?.current_high : s?.daily[0]?.ticker_high) - (s?.daily[0]?.current_low >= s?.daily[0]?.ticker_low ? s?.daily[0]?.ticker_low : s?.daily[0]?.current_low == 0 ? s?.daily[0]?.ticker_low : s?.daily[0]?.current_low))?.toFixed(1)}</Typography>
                                                    <Typography align="center" className={(s?.daily[0]?.pre_high - s?.daily[0]?.pre_low) > 0 ? "high-color" : "low-color"}>P.R: {Number(s?.daily[0]?.pre_low) ? (s?.daily[0]?.pre_high - s?.daily[0]?.pre_low)?.toFixed(1) : 0}</Typography>
                                                </Grid>
                                                <Grid className="im-oh w-1/3">
                                                    <Typography align="center" className="high-color">C.O: {s?.daily[0]?.current_open == 0 ? s?.daily[0]?.ticker_open : s?.daily[0]?.current_open?.toFixed(1)}</Typography>
                                                    <Typography align="center" className="high-color">P.C: {s?.daily[0]?.pre_close?.toFixed(1)}</Typography>
                                                </Grid>
                                                <Grid className="im-rp w-1/3">
                                                    <Typography align="center" className="high-color">C.H: {s?.daily[0]?.current_high >= s?.daily[0]?.ticker_high ? s?.daily[0]?.current_high?.toFixed(1) : s?.daily[0]?.ticker_high?.toFixed(1)}</Typography>
                                                    <Typography align="center" className="low-color">C.L: {s?.daily[0]?.current_low >= s?.daily[0]?.ticker_low ? s?.daily[0]?.ticker_low?.toFixed(1) : s?.daily[0]?.current_low == "0" ? s?.daily[0]?.ticker_low?.toFixed(1) : s?.daily[0]?.current_low?.toFixed(1)} </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid className='indice-candle-nsePlusePluse' style={{ backgroundColor: 'lightblue' }}>
                                            <Grid className="flex justify-center -mb-1">
                                                <Typography variant='body2' className="font-black font-futura flex whitespace-no-wrap" align="center" style={{ fontSize: '12px', color: `${s?.weekly[0]?.currentprice > s?.weekly[0]?.pre_close ? "#000066" : "#DA2424"}` }}>(W) - {companySymbol}
                                                    <span className="flex items-center" style={{ fontSize: '12px', marginLeft: '3px' }}>{s?.weekly[0]?.currentprice}</span>
                                                    <span className="flex items-center" style={{ fontSize: '10px' }}>({(s?.weekly[0]?.currentprice - s?.weekly[0]?.pre_close).toFixed(2)})({getPerc(s?.weekly[0]?.currentprice, s?.weekly[0]?.pre_close)}%)</span>
                                                </Typography>
                                            </Grid>
                                            <Grid style={{ marginTop: '4px' }}>
                                                <MetricCandleStick cmp={s?.daily[0]?.currentprice} open={s?.weekly[0]?.current_open ? s?.weekly[0]?.current_open : s?.weekly[0]?.ticker_open}
                                                    high={s?.weekly[0]?.current_high >= s?.weekly[0]?.ticker_high ? s?.weekly[0]?.current_high : s?.weekly[0]?.ticker_high}
                                                    low={s?.weekly[0]?.current_low >= s?.weekly[0]?.ticker_low ? s?.weekly[0]?.ticker_low : s?.weekly[0]?.current_low == 0 ? s?.weekly[0]?.ticker_low : s?.weekly[0]?.current_low}
                                                    close={s?.weekly[0]?.current_close} poscount={s?.weekly[0]?.poscount} negcount={s?.weekly[0]?.negcount} arrow={s?.weekly[0]?.arrow} totalMetrics={s?.weekly[0]?.totalMetrics} prev_range={s?.weekly[0]?.pre_percentage}
                                                    percentage={s?.weekly?.[0]?.percentage} />
                                            </Grid>
                                            <Grid className='im-ohlc flex w-full'>
                                                <Grid className="im-lc w-1/3">
                                                    <Typography align="center" className="high-color">C.R: {((s?.weekly[0]?.current_high >= s?.weekly[0]?.ticker_high ? s?.weekly[0]?.current_high : s?.weekly[0]?.ticker_high) - (s?.weekly[0]?.current_low >= s?.weekly[0]?.ticker_low ? s?.weekly[0]?.ticker_low : s?.weekly[0]?.current_low == 0 ? s?.weekly[0]?.ticker_low : s?.weekly[0]?.current_low))?.toFixed(1)}</Typography>
                                                    <Typography align="center" className={(s?.weekly[0]?.pre_high - s?.weekly[0]?.pre_low) > 0 ? "high-color" : "low-color"}>P.R: {Number(s?.weekly[0]?.pre_low) ? (s?.weekly[0]?.pre_high - s?.weekly[0]?.pre_low)?.toFixed(1) : 0}</Typography>
                                                </Grid>
                                                <Grid className="im-oh w-1/3">
                                                    <Typography align="center" className="high-color">C.O: {s?.weekly[0]?.current_open == 0 ? s?.weekly[0]?.ticker_open : s?.weekly[0]?.current_open?.toFixed(1)}</Typography>
                                                    <Typography align="center" className="high-color">P.C: {s?.weekly[0]?.pre_close?.toFixed(1)}</Typography>
                                                </Grid>
                                                <Grid className="im-rp w-1/3">
                                                    <Typography align="center" className="high-color">C.H: {s?.weekly[0]?.current_high >= s?.weekly[0]?.ticker_high ? s?.weekly[0]?.current_high?.toFixed(1) : s?.weekly[0]?.ticker_high?.toFixed(1)}</Typography>
                                                    <Typography align="center" className="low-color">C.L: {s?.weekly[0]?.current_low >= s?.weekly[0]?.ticker_low ? s?.weekly[0]?.ticker_low?.toFixed(1) : s?.weekly[0]?.current_low == "0" ? s?.weekly[0]?.ticker_low?.toFixed(1) : s?.weekly[0]?.current_low?.toFixed(1)} </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid className='indice-candle-nsePlusePluse' style={{ backgroundColor: 'lightblue' }}>
                                            <Grid className="flex justify-center -mb-1">
                                                <Typography variant='body2' className="font-black font-futura flex whitespace-no-wrap" align="center" style={{ fontSize: '12px', color: `${s?.monthly[0]?.currentprice > s?.monthly[0]?.pre_close ? "#000066" : "#DA2424"}` }}>(M) - {companySymbol}
                                                    <span className="flex items-center" style={{ fontSize: '12px', marginLeft: '3px' }}>{s?.monthly[0]?.currentprice}</span>
                                                    <span className="flex items-center" style={{ fontSize: '10px' }}>({(s?.monthly[0]?.currentprice - s?.monthly[0]?.pre_close).toFixed(2)})({getPerc(s?.monthly[0]?.currentprice, s?.monthly[0]?.pre_close)}%)</span>
                                                </Typography>
                                            </Grid>
                                            <Grid style={{ marginTop: '4px' }}>
                                                <MetricCandleStick cmp={s?.daily[0]?.currentprice} open={s?.monthly[0]?.current_open ? s?.monthly[0]?.current_open : s?.monthly[0]?.ticker_open}
                                                    high={s?.monthly[0]?.current_high >= s?.monthly[0]?.ticker_high ? s?.monthly[0]?.current_high : s?.monthly[0]?.ticker_high}
                                                    low={s?.monthly[0]?.current_low >= s?.monthly[0]?.ticker_low ? s?.monthly[0]?.ticker_low : s?.monthly[0]?.current_low == 0 ? s?.monthly[0]?.ticker_low : s?.monthly[0]?.current_low}
                                                    close={s?.monthly[0]?.current_close} poscount={s?.monthly[0]?.poscount} negcount={s?.monthly[0]?.negcount} arrow={s?.monthly[0]?.arrow} totalMetrics={s?.monthly[0]?.totalMetrics} prev_range={s?.monthly[0]?.pre_percentage}
                                                    percentage={s?.monthly?.[0]?.percentage} />
                                            </Grid>
                                            <Grid className='im-ohlc flex w-full'>
                                                <Grid className="im-lc w-1/3">
                                                    <Typography align="center" className="high-color">C.R: {((s?.monthly[0]?.current_high >= s?.monthly[0]?.ticker_high ? s?.monthly[0]?.current_high : s?.monthly[0]?.ticker_high) - (s?.monthly[0]?.current_low >= s?.monthly[0]?.ticker_low ? s?.monthly[0]?.ticker_low : s?.monthly[0]?.current_low == 0 ? s?.monthly[0]?.ticker_low : s?.monthly[0]?.current_low))?.toFixed(1)}</Typography>
                                                    <Typography align="center" className={(s?.monthly[0]?.pre_high - s?.monthly[0]?.pre_low) > 0 ? "high-color" : "low-color"}>P.R: {Number(s?.monthly[0]?.pre_low) ? (s?.monthly[0]?.pre_high - s?.monthly[0]?.pre_low)?.toFixed(1) : 0}</Typography>
                                                </Grid>
                                                <Grid className="im-oh w-1/3">
                                                    <Typography align="center" className="high-color">C.O: {s?.monthly[0]?.current_open == 0 ? s?.monthly[0]?.ticker_open : s?.monthly[0]?.current_open?.toFixed(1)}</Typography>
                                                    <Typography align="center" className="high-color">P.C: {s?.monthly[0]?.pre_close?.toFixed(1)}</Typography>
                                                </Grid>
                                                <Grid className="im-rp w-1/3">
                                                    <Typography align="center" className="high-color">C.H: {s?.monthly[0]?.current_high >= s?.monthly[0]?.ticker_high ? s?.monthly[0]?.current_high?.toFixed(1) : s?.monthly[0]?.ticker_high?.toFixed(1)}</Typography>
                                                    <Typography align="center" className="low-color">C.L: {s?.monthly[0]?.current_low >= s?.monthly[0]?.ticker_low ? s?.monthly[0]?.ticker_low?.toFixed(1) : s?.monthly[0]?.current_low == "0" ? s?.monthly[0]?.ticker_low?.toFixed(1) : s?.monthly[0]?.current_low?.toFixed(1)} </Typography>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid className='w-1/3 border-timeframe plusplus-border p-0 bg-white'>
                                    <Grid className='header-timeframe'>
                                        {/* <Typography align='center' className='font-semibold font-futura' style={{ fontSize: '14px' }}>{companySymbol}</Typography> */}
                                        <Typography align='center' className='font-semibold font-futura' style={{ fontSize: '14px' }}>LONG TERM</Typography>
                                    </Grid>
                                    <Grid className=''>
                                        <GaugeChartNV tf={["Quarterly", "HalfYearly", "Yearly"]} cmp={s?.daily[0]?.currentprice} previousclose={previousclose_i} tradaRange={investorRange} tradaClose={investorClose} tradaHigh={investorHigh} traderOpen={investorOpen} traderLow={investorLow} viewPercentages={investorViewPercentages} mean={((Number(investorViewPercentages[0]) + Number(investorViewPercentages[1]) + Number(investorViewPercentages[2])) / 3)?.toFixed(2)} />
                                        <Grid className='indice-candle-nsePlusePluse' style={{ backgroundColor: 'lightblue' }}>
                                            <Grid className="flex justify-center -mb-1">
                                                <Typography variant='body2' className="font-black font-futura flex whitespace-no-wrap" align="center" style={{ fontSize: '12px', color: `${s?.quarterly[0]?.currentprice > s?.quarterly[0]?.pre_close ? "#000066" : "#DA2424"}` }}>(Q) - {companySymbol}
                                                    <span className="flex items-center" style={{ fontSize: '12px', marginLeft: '3px' }}>{s?.quarterly[0]?.currentprice}</span>
                                                    <span className="flex items-center" style={{ fontSize: '10px' }}>({(s?.quarterly[0]?.currentprice - s?.quarterly[0]?.pre_close).toFixed(2)})({getPerc(s?.quarterly[0]?.currentprice, s?.quarterly[0]?.pre_close)}%)</span>
                                                </Typography>
                                            </Grid>
                                            <Grid style={{ marginTop: '4px' }}>
                                                <MetricCandleStick cmp={s?.daily[0]?.currentprice} open={s?.quarterly[0]?.current_open ? s?.quarterly[0]?.current_open : s?.quarterly[0]?.ticker_open}
                                                    high={s?.quarterly[0]?.current_high >= s?.quarterly[0]?.ticker_high ? s?.quarterly[0]?.current_high : s?.quarterly[0]?.ticker_high}
                                                    low={s?.quarterly[0]?.current_low >= s?.quarterly[0]?.ticker_low ? s?.quarterly[0]?.ticker_low : s?.quarterly[0]?.current_low == 0 ? s?.quarterly[0]?.ticker_low : s?.quarterly[0]?.current_low}
                                                    close={s?.quarterly[0]?.current_close} poscount={s?.quarterly[0]?.poscount} negcount={s?.quarterly[0]?.negcount} arrow={s?.quarterly[0]?.arrow} totalMetrics={s?.quarterly[0]?.totalMetrics} prev_range={s?.quarterly[0]?.pre_percentage}
                                                    percentage={s?.quarterly?.[0]?.percentage} />
                                            </Grid>
                                            <Grid className='im-ohlc flex w-full'>
                                                <Grid className="im-lc w-1/3">
                                                    <Typography align="center" className="high-color">C.R: {((s?.quarterly[0]?.current_high >= s?.quarterly[0]?.ticker_high ? s?.quarterly[0]?.current_high : s?.quarterly[0]?.ticker_high) - (s?.quarterly[0]?.current_low >= s?.quarterly[0]?.ticker_low ? s?.quarterly[0]?.ticker_low : s?.quarterly[0]?.current_low == 0 ? s?.quarterly[0]?.ticker_low : s?.quarterly[0]?.current_low))?.toFixed(1)}</Typography>
                                                    <Typography align="center" className={(s?.quarterly[0]?.pre_high - s?.quarterly[0]?.pre_low) > 0 ? "high-color" : "low-color"}>P.R: {Number(s?.quarterly[0]?.pre_low) ? (s?.quarterly[0]?.pre_high - s?.quarterly[0]?.pre_low)?.toFixed(1) : 0}</Typography>
                                                </Grid>
                                                <Grid className="im-oh w-1/3">
                                                    <Typography align="center" className="high-color">C.O: {s?.quarterly[0]?.current_open == 0 ? s?.quarterly[0]?.ticker_open : s?.quarterly[0]?.current_open?.toFixed(1)}</Typography>
                                                    <Typography align="center" className="high-color">P.C: {s?.quarterly[0]?.pre_close?.toFixed(1)}</Typography>
                                                </Grid>
                                                <Grid className="im-rp w-1/3">
                                                    <Typography align="center" className="high-color">C.H: {s?.quarterly[0]?.current_high >= s?.quarterly[0]?.ticker_high ? s?.quarterly[0]?.current_high?.toFixed(1) : s?.quarterly[0]?.ticker_high?.toFixed(1)}</Typography>
                                                    <Typography align="center" className="low-color">C.L: {s?.quarterly[0]?.current_low >= s?.quarterly[0]?.ticker_low ? s?.quarterly[0]?.ticker_low?.toFixed(1) : s?.quarterly[0]?.current_low == "0" ? s?.quarterly[0]?.ticker_low?.toFixed(1) : s?.quarterly[0]?.current_low?.toFixed(1)} </Typography>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                        <Grid className='indice-candle-nsePlusePluse' style={{ backgroundColor: 'lightblue' }}>
                                            <Grid className="flex justify-center -mb-1">
                                                <Typography variant='body2' className="font-black font-futura flex whitespace-no-wrap" align="center" style={{ fontSize: '12px', color: `${s?.halfYearly[0]?.currentprice > s?.halfYearly[0]?.pre_close ? "#000066" : "#DA2424"}` }}>(HY) - {companySymbol}
                                                    <span className="flex items-center" style={{ fontSize: '12px', marginLeft: '3px' }}>{s?.halfYearly[0]?.currentprice}</span>
                                                    <span className="flex items-center" style={{ fontSize: '10px' }}>({(s?.halfYearly[0]?.currentprice - s?.halfYearly[0]?.pre_close).toFixed(2)})({getPerc(s?.halfYearly[0]?.currentprice, s?.halfYearly[0]?.pre_close)}%)</span>
                                                </Typography>
                                            </Grid>
                                            <Grid style={{ marginTop: '4px' }}>
                                                <MetricCandleStick cmp={s?.daily[0]?.currentprice} open={s?.halfYearly[0]?.current_open ? s?.halfYearly[0]?.current_open : s?.halfYearly[0]?.ticker_open}
                                                    high={s?.halfYearly[0]?.current_high >= s?.halfYearly[0]?.ticker_high ? s?.halfYearly[0]?.current_high : s?.halfYearly[0]?.ticker_high}
                                                    low={s?.halfYearly[0]?.current_low >= s?.halfYearly[0]?.ticker_low ? s?.halfYearly[0]?.ticker_low : s?.halfYearly[0]?.current_low == 0 ? s?.halfYearly[0]?.ticker_low : s?.halfYearly[0]?.current_low}
                                                    close={s?.halfYearly[0]?.current_close} poscount={s?.halfYearly[0]?.poscount} negcount={s?.halfYearly[0]?.negcount} arrow={s?.halfYearly[0]?.arrow} totalMetrics={s?.halfYearly[0]?.totalMetrics} prev_range={s?.halfYearly[0]?.pre_percentage}
                                                    percentage={s?.halfYearly?.[0]?.percentage} />
                                            </Grid>
                                            <Grid className='im-ohlc flex w-full'>
                                                <Grid className="im-lc w-1/3">
                                                    <Typography align="center" className="high-color">C.R: {((s?.halfYearly[0]?.current_high >= s?.halfYearly[0]?.ticker_high ? s?.halfYearly[0]?.current_high : s?.halfYearly[0]?.ticker_high) - (s?.halfYearly[0]?.current_low >= s?.halfYearly[0]?.ticker_low ? s?.halfYearly[0]?.ticker_low : s?.halfYearly[0]?.current_low == 0 ? s?.halfYearly[0]?.ticker_low : s?.halfYearly[0]?.current_low))?.toFixed(1)}</Typography>
                                                    <Typography align="center" className={(s?.halfYearly[0]?.pre_high - s?.halfYearly[0]?.pre_low) > 0 ? "high-color" : "low-color"}>P.R: {Number(s?.halfYearly[0]?.pre_low) ? (s?.halfYearly[0]?.pre_high - s?.halfYearly[0]?.pre_low)?.toFixed(1) : 0}</Typography>
                                                </Grid>
                                                <Grid className="im-oh w-1/3">
                                                    <Typography align="center" className="high-color">C.O: {s?.halfYearly[0]?.current_open == 0 ? s?.halfYearly[0]?.ticker_open : s?.halfYearly[0]?.current_open?.toFixed(1)}</Typography>
                                                    <Typography align="center" className="high-color">P.C: {s?.halfYearly[0]?.pre_close?.toFixed(1)}</Typography>
                                                </Grid>
                                                <Grid className="im-rp w-1/3">
                                                    <Typography align="center" className="high-color">C.H: {s?.halfYearly[0]?.current_high >= s?.halfYearly[0]?.ticker_high ? s?.halfYearly[0]?.current_high?.toFixed(1) : s?.halfYearly[0]?.ticker_high?.toFixed(1)}</Typography>
                                                    <Typography align="center" className="low-color">C.L: {s?.halfYearly[0]?.current_low >= s?.halfYearly[0]?.ticker_low ? s?.halfYearly[0]?.ticker_low?.toFixed(1) : s?.halfYearly[0]?.current_low == "0" ? s?.halfYearly[0]?.ticker_low?.toFixed(1) : s?.halfYearly[0]?.current_low?.toFixed(1)} </Typography>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                        <Grid className='indice-candle-nsePlusePluse' style={{ backgroundColor: 'lightblue' }}>
                                            <Grid className="flex justify-center -mb-1">
                                                <Typography variant='body2' className="font-black font-futura flex whitespace-no-wrap" align="center" style={{ fontSize: '12px', color: `${s?.yearly[0]?.currentprice > s?.yearly[0]?.pre_close ? "#000066" : "#DA2424"}` }}>(Y) - {companySymbol}
                                                    <span className="flex items-center" style={{ fontSize: '12px', marginLeft: '3px' }}>{s?.yearly[0]?.currentprice}</span>
                                                    <span className="flex items-center" style={{ fontSize: '10px' }}>({(s?.yearly[0]?.currentprice - s?.yearly[0]?.pre_close).toFixed(2)})({getPerc(s?.yearly[0]?.currentprice, s?.yearly[0]?.pre_close)}%)</span>
                                                </Typography>
                                            </Grid>
                                            <Grid style={{ marginTop: '4px' }}>
                                                <MetricCandleStick cmp={s?.daily[0]?.currentprice} open={s?.yearly[0]?.current_open ? s?.yearly[0]?.current_open : s?.yearly[0]?.ticker_open}
                                                    high={s?.yearly[0]?.current_high >= s?.yearly[0]?.ticker_high ? s?.yearly[0]?.current_high : s?.yearly[0]?.ticker_high}
                                                    low={s?.yearly[0]?.current_low >= s?.yearly[0]?.ticker_low ? s?.yearly[0]?.ticker_low : s?.yearly[0]?.current_low == 0 ? s?.yearly[0]?.ticker_low : s?.yearly[0]?.current_low}
                                                    close={s?.yearly[0]?.current_close} poscount={s?.yearly[0]?.poscount} negcount={s?.yearly[0]?.negcount} arrow={s?.yearly[0]?.arrow} totalMetrics={s?.yearly[0]?.totalMetrics} prev_range={s?.yearly[0]?.pre_percentage}
                                                    percentage={s?.yearly?.[0]?.percentage} />
                                            </Grid>
                                            <Grid className='im-ohlc flex w-full'>
                                                <Grid className="im-lc w-1/3">
                                                    <Typography align="center" className="high-color">C.R: {((s?.yearly[0]?.current_high >= s?.yearly[0]?.ticker_high ? s?.yearly[0]?.current_high : s?.yearly[0]?.ticker_high) - (s?.yearly[0]?.current_low >= s?.yearly[0]?.ticker_low ? s?.yearly[0]?.ticker_low : s?.yearly[0]?.current_low == 0 ? s?.yearly[0]?.ticker_low : s?.yearly[0]?.current_low))?.toFixed(1)}</Typography>
                                                    <Typography align="center" className={(s?.yearly[0]?.pre_high - s?.yearly[0]?.pre_low) > 0 ? "high-color" : "low-color"}>P.R: {Number(s?.yearly[0]?.pre_low) ? (s?.yearly[0]?.pre_high - s?.yearly[0]?.pre_low)?.toFixed(1) : 0}</Typography>
                                                </Grid>
                                                <Grid className="im-oh w-1/3">
                                                    <Typography align="center" className="high-color">C.O: {s?.yearly[0]?.current_open == 0 ? s?.yearly[0]?.ticker_open : s?.yearly[0]?.current_open?.toFixed(1)}</Typography>
                                                    <Typography align="center" className="high-color">P.C: {s?.yearly[0]?.pre_close?.toFixed(1)}</Typography>
                                                </Grid>
                                                <Grid className="im-rp w-1/3">
                                                    <Typography align="center" className="high-color">C.H: {s?.yearly[0]?.current_high >= s?.yearly[0]?.ticker_high ? s?.yearly[0]?.current_high?.toFixed(1) : s?.yearly[0]?.ticker_high?.toFixed(1)}</Typography>
                                                    <Typography align="center" className="low-color">C.L: {s?.yearly[0]?.current_low >= s?.yearly[0]?.ticker_low ? s?.yearly[0]?.ticker_low?.toFixed(1) : s?.yearly[0]?.current_low == "0" ? s?.yearly[0]?.ticker_low?.toFixed(1) : s?.yearly[0]?.current_low?.toFixed(1)} </Typography>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid className='w-1/2 border-timeframe plusplus-border p-0 bg-white'>
                                    <Grid className='header-timeframe'>
                                        <Typography align='center' className='font-semibold font-futura' style={{ fontSize: '16px' }}>Best Fair Value</Typography>
                                    </Grid>
                                    <Grid style={{ display: 'flex', width: '100%' }}>
                                        <Grid style={{ width: '50%' }}>
                                            <table style={styles.table}>
                                                <thead>
                                                    <tr>
                                                        <th style={styles.th}>Short Term
                                                            {SuperTrends.st1 < speedoMeterData?.daily[0]?.currentprice
                                                                ? (
                                                                    <Grid style={{ marginLeft: '5px', color: '#339249', background: '#fff', borderRadius: '5px', fontSize: '8px !important', display: 'flex', alignItems: 'center' }}>
                                                                        <span > <ArrowUpward style={{ fontSize: '18px' }} /></span>
                                                                        <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                                                            {calculatePositiveDifference(stKeys, SuperTrends)}%
                                                                        </span>
                                                                    </Grid>
                                                                )
                                                                : (
                                                                    <Grid style={{ marginLeft: '5px', color: '#DA2424', background: '#fff', borderRadius: '5px', fontSize: '8px !important', display: 'flex', alignItems: 'center' }}>
                                                                        <ArrowDownward style={{ fontSize: '18px' }} />
                                                                        <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                                                            {calculatePositiveDifference(stKeys, SuperTrends)}%
                                                                        </span>
                                                                    </Grid>
                                                                )
                                                            }
                                                        </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {stKeys.length > 0 ? stKeys.map((key, index) => (

                                                        <tr
                                                            key={key}
                                                            style={index % 2 === 0 ? styles.rowEven : styles.rowOdd}
                                                        >
                                                            <td
                                                                style={{
                                                                    color: speedoMeterData?.daily[0]?.currentprice > SuperTrends[key] ? "#339249" : "#da2424",
                                                                    padding: '10px',
                                                                    justifyContent: 'center',
                                                                    border: '1px solid #ddd',
                                                                    display: 'flex',
                                                                    fontWeight: '600',
                                                                    fontSize: '13px',
                                                                    fontFamily: key === 'st1' ? 'Futura PT Heavy' : 'Futura PT Book',

                                                                }}>
                                                                {roundToNearestFive(SuperTrends[key]) <= 0 ? "NDA" : roundToNearestFive(SuperTrends[key]).toFixed(2)}  ({keyNames(key)})
                                                            </td>
                                                        </tr>
                                                    )) :
                                                        <tr>
                                                            <td style={styles.td}>
                                                                No Data Avaliable
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </Grid>
                                        <Grid style={{ width: '50%' }}>
                                            <table style={styles.table}>
                                                <thead>
                                                    <tr>
                                                        <th style={styles.th}>Mid Term
                                                            {SuperTrendsWeelky.st1 < speedoMeterData?.daily[0]?.currentprice
                                                                ? (
                                                                    <Grid style={{ marginLeft: '5px', color: '#339249', background: '#fff', borderRadius: '5px', fontSize: '8px !important', display: 'flex', alignItems: 'center' }}>
                                                                        <span > <ArrowUpward style={{ fontSize: '18px' }} /></span>
                                                                        <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                                                            {calculatePositiveDifference(stWeeklyKeys, SuperTrendsWeelky)}%
                                                                        </span>
                                                                    </Grid>
                                                                )
                                                                : (
                                                                    <Grid style={{ marginLeft: '5px', color: '#DA2424', background: '#fff', borderRadius: '5px', fontSize: '8px !important', display: 'flex', alignItems: 'center' }}>
                                                                        <ArrowDownward style={{ fontSize: '18px' }} />
                                                                        <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                                                            {calculatePositiveDifference(stWeeklyKeys, SuperTrendsWeelky)}%
                                                                        </span>
                                                                    </Grid>
                                                                )
                                                            }
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {stWeeklyKeys.length > 0 ? stWeeklyKeys.map((key, index) => (

                                                        <tr
                                                            key={key}
                                                            style={index % 2 === 0 ? styles.rowEven : styles.rowOdd}
                                                        >
                                                            <td
                                                                style={{
                                                                    color: speedoMeterData?.daily[0]?.currentprice > SuperTrendsWeelky[key] ? "#339249" : "#da2424",
                                                                    padding: '10px',
                                                                    justifyContent: 'center',
                                                                    border: '1px solid #ddd',
                                                                    display: 'flex',
                                                                    fontWeight: "600",
                                                                    fontSize: '13px',
                                                                    fontFamily: key === 'st1' ? 'Futura PT Heavy' : 'Futura PT Book',

                                                                }}>
                                                                {roundToNearestFive(SuperTrendsWeelky[key]) <= 0 ? "NDA" : roundToNearestFive(SuperTrendsWeelky[key]).toFixed(2)}  ({keyNames(key)})
                                                            </td>
                                                        </tr>
                                                    )) :
                                                        <tr>
                                                            <td style={styles.td}>
                                                                No Data Avaliable
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </Grid>
                                        <Grid style={{ width: '50%' }}>
                                            <table style={styles.table}>
                                                <thead>
                                                    <tr>
                                                        <th style={styles.th}>Long Term
                                                            {SuperTrendsMonthly.st1 < speedoMeterData?.daily[0]?.currentprice
                                                                ? (
                                                                    <Grid style={{ marginLeft: '5px', color: '#339249', background: '#fff', borderRadius: '5px', fontSize: '8px !important', display: 'flex', alignItems: 'center' }}>
                                                                        <span > <ArrowUpward style={{ fontSize: '18px' }} /></span>
                                                                        <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                                                            {calculatePositiveDifference(stMonthlyKeys, SuperTrendsMonthly)}%
                                                                        </span>
                                                                    </Grid>
                                                                )
                                                                : (
                                                                    <Grid style={{ marginLeft: '5px', color: '#DA2424', background: '#fff', borderRadius: '5px', fontSize: '8px !important', display: 'flex', alignItems: 'center' }}>
                                                                        <ArrowDownward style={{ fontSize: '18px' }} />
                                                                        <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                                                            {calculatePositiveDifference(stMonthlyKeys, SuperTrendsMonthly)}%
                                                                        </span>
                                                                    </Grid>
                                                                )
                                                            }
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {stMonthlyKeys.length > 0 ? stMonthlyKeys.map((key, index) => (

                                                        <tr
                                                            key={key}
                                                            style={index % 2 === 0 ? styles.rowEven : styles.rowOdd}
                                                        >
                                                            <td
                                                                style={{
                                                                    color: speedoMeterData?.daily[0]?.currentprice > SuperTrendsMonthly[key] ? "#339249" : "#da2424",
                                                                    padding: '10px',
                                                                    justifyContent: 'center',
                                                                    border: '1px solid #ddd',
                                                                    display: 'flex',
                                                                    fontWeight: "600",
                                                                    fontSize: '13px',
                                                                    fontFamily: key === 'st1' ? 'Futura PT Heavy' : 'Futura PT Book',
                                                                }}>
                                                                {roundToNearestFive(SuperTrendsMonthly[key]) <= 0 ? "NDA" : roundToNearestFive(SuperTrendsMonthly[key]).toFixed(2)}  ({keyNames(key)})
                                                            </td>
                                                        </tr>
                                                    )) :
                                                        <tr>
                                                            <td style={styles.td}>
                                                                No Data Avaliable
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )
                }) : <Typography align='center' className='font-semibold font-futura'>Loading...</Typography>}
            </Grid>
        </>
    )
}

export default GraphView