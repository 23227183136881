import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TawkToChat = () => {
  const location = useLocation();

  useEffect(() => {
    // List of routes where you want the Tawk.to chat to appear
    const allowedRoutes = ['/', '/about',"/service","/exploreus","/blogs"]; // Home page and Contact page

    if (allowedRoutes.includes(location.pathname)) {
      // Create the script element and append to the document
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://embed.tawk.to/670f9a172480f5b4f58e30e3/1iaaf3rjg';
      script.charset = 'UTF-8';
      script.setAttribute('crossorigin', '*');
      document.body.appendChild(script);

      // Clean up the script on component unmount
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [location.pathname]);

  return null; // No UI element, only loads the script
};

export default TawkToChat;