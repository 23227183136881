import React, { useEffect, useMemo, useState } from 'react'
import { IndexMoversMidCapSelectTenData } from '../apiService/tickerApiService'
import { Grid, Tab, Typography } from '@material-ui/core'
import MetricCandleStick from '../common/MetricCandleStick'
import './Indexmovers.css';
import { getColorPr, rangePerc } from '../../utils';
import { ArrowDownward, ArrowUpward, Height } from '@material-ui/icons';
import { TabContext, TabList } from '@material-ui/lab';
import GaugeChartForDatatable from '../common/GaugeChartForDatatable';
import GaugeChart from '../common/GaugeChart';

function MidCapSelectTen() {
    const [dailyData, setDailyData] = useState([])
    const [weeklyData, setWeeklyData] = useState([])
    const [monthlyData, setMonthlyData] = useState([])
    const [quarterlyData, setQuarterlyData] = useState([])
    const [halfYearlyData, setHalfYearlylyData] = useState([])
    const [yearlyData, setYearlyData] = useState([])
    const viewTabs = [
        { value: '1', label: 'Standard View', },
        { value: '2', label: "Expert View", }
    ]
    const [value, setValue] = React.useState('1');
    const [label, setlabel] = React.useState(viewTabs[0].label)
    const handleChange = (event, newValue) => {
        setValue(newValue);
        let a = viewTabs.filter((e, i) => e.value === newValue)
        setlabel(a.length ? a[0].label : viewTabs[0].label)
    };
    const fetchDataForNiftyTen = async () => {
        const d_data = await IndexMoversMidCapSelectTenData("D")
        const w_data = await IndexMoversMidCapSelectTenData("W")
        const m_data = await IndexMoversMidCapSelectTenData("M")
        const q_data = await IndexMoversMidCapSelectTenData("Q")
        const h_data = await IndexMoversMidCapSelectTenData("H")
        const y_data = await IndexMoversMidCapSelectTenData("Y")

        setDailyData(d_data?.data)
        setWeeklyData(w_data?.data)
        setMonthlyData(m_data?.data)
        setQuarterlyData(q_data?.data)
        setHalfYearlylyData(h_data?.data)
        setYearlyData(y_data?.data)
    }

    // const downCount_D = dailyData.filter(item => item.arrow === "DOWN").length;

    useEffect(() => {
        let intervalId = setInterval(() => {
            fetchDataForNiftyTen()
        }, 4000)
        return () => clearInterval(intervalId)
    }, [dailyData])

    useMemo(() => {
        fetchDataForNiftyTen()
    }, [])
    const getTF = (i) => {
        switch (i) {
            case 0: return "Daily"
            case 1: return "Weekly"
            case 2: return "Monthly"
            case 3: return "Quarterly"
            case 4: return "Half Yearly"
            case 5: return "Yearly"
        }
    }
    const getPerc = (cmp, pc) => {
        let a = ((cmp - pc) / pc) * 100
        return a?.toFixed(2)
    }
    return (
        <Grid className='niftyplus flex w-full h-full gap-1 -ml-2'>
             <Grid className="investers-trading-tabs" >
                <TabContext value={value}>
                    <TabList className="tabs-grid"
                        onChange={handleChange}
                        aria-label="lab API tabs example">
                        {viewTabs.map((e, i) => (
                            <Tab key={i} className="tab-button" label={e.label} value={e.value} />
                        ))}
                    </TabList>
                </TabContext>
            </Grid>
            {[dailyData, weeklyData, monthlyData, quarterlyData, halfYearlyData, yearlyData]?.map((indice, index) => {
                let _indices = indice.filter(item => item.companyData.companySymbol !== "MIDCAPSELECT");
                let downCount = _indices.filter(item => item.arrow === "DOWN").length;
                let normalCount = _indices.filter(item => item.arrow === "NORMAL").length;
                let upCount = _indices.filter(item => item.arrow === "UP").length;
                let maxCount = Math.max(downCount, normalCount, upCount);
                // let maxType;
                // if (maxCount === downCount) {
                //     maxType = "d";
                // } else if (maxCount === normalCount) {
                //     maxType = "n";
                // } else if (maxCount === upCount) {
                //     maxType = "u";
                // }
                let Count = _indices.length;
                let sortedArr = indice.sort((a, b) => b?.companyData?.midcapselectWeightage - a?.companyData?.midcapselectWeightage)
                return (
                    <Grid className='w-1/6 border-timeframe '>
                        <Grid className=' header-timeframe'>
                            <Typography align='center' className='font-black font-futura' style={{ fontSize: '14px' }}>{getTF(index)}</Typography>
                            <Typography variant='body2' className='font-black font-futura mb-2 mt-1' align='center'>
                                <span style={{ border: '1px solid #fff', borderRadius: '5px', padding: '2px 5px', background: `${maxCount === upCount ? '#339249' : ''}`, fontSize: `${maxCount === upCount ? '14px' : '10px'}` }}><ArrowUpward style={{ fontSize: "18px", marginTop: "-3px" }} />{upCount}/{Count}</span>
                                <span style={{ border: '1px solid #fff', borderRadius: '5px', margin: '0px 5px', padding: '2px 5px', background: `${maxCount === normalCount ? '#ffa100e3' : ''}`, fontSize: `${maxCount === normalCount ? '14px' : '10px'}` }}><Height style={{ rotate: "90deg", marginTop: "-3px" }} />{normalCount}/{Count}</span>
                                <span style={{ border: '1px solid #fff', borderRadius: '5px', padding: '2px 5px', background: `${maxCount == downCount ? '#da2424' : ''}`, fontSize: `${maxCount === downCount ? '14px' : '10px'}` }}><ArrowDownward style={{ fontSize: "18px", marginTop: "-3px" }} />{downCount}/{Count}</span>
                            </Typography>
                        </Grid>
                        {sortedArr?.map((s, i) => {
                            return (<>
                            
                              { value == 1 &&  <Grid className='nse-border'>
                                    <Grid className="flex justify-center">
                                        <Typography variant='body2' className="font-black font-futura flex whitespace-no-wrap" align="center" style={{ color: `${s?.currentprice > s?.pre_close ? "#000066" : "#DA2424"}`, fontSize: '12px' }}>{s?.companyData?.companySymbol}
                                            <span className="flex items-center" style={{ color: "", fontSize: '12px', marginLeft: '3px' }}>{s?.currentprice}</span>
                                            <span className="flex items-center" style={{ color: "", fontSize: '10px' }}>({(s?.currentprice - s?.pre_close).toFixed(2)})({getPerc(s?.currentprice, s?.pre_close)}%)</span>
                                        </Typography>
                                    </Grid>
                                    {/* <Grid className='im-ohlc flex w-full'>
                                        <Grid className="im-lc w-1/3">
                                            <Typography align="center" className="high-color">R: {((s?.current_high >= s?.ticker_high ? s?.current_high : s?.ticker_high) - (s?.current_low >= s?.ticker_low ? s?.ticker_low : s?.current_low))?.toFixed(2)}</Typography>
                                            <Typography align="center" className="" style={{ color: getColorPr(s?.pre_percentage?.toFixed(2)) }}>P.R: {s?.pre_percentage?.toFixed(2)}%</Typography>
                                        </Grid>
                                        <Grid className="im-oh w-1/3">
                                            <Typography align="center" className="high-color">O: {s?.current_open}</Typography>
                                            <Typography align="center" className="high-color">P.C: {s?.pre_close}</Typography>
                                        </Grid>
                                        <Grid className="im-rp w-1/3">
                                            <Typography align="center" className="high-color">H: {s?.current_high >= s?.ticker_high ? s?.current_high : s?.ticker_high}</Typography>
                                            <Typography align="center" className="low-color">L: {s?.current_low >= s?.ticker_low ? s?.ticker_low : s?.current_low == "0" ? s?.ticker_low : s?.current_low} </Typography>
                                        </Grid>
                                    </Grid> */}
                                    <Grid className='standard-observ-indices'>
                                        <MetricCandleStick  cmp={s?.currentprice} percentage={s?.percentage} open={s?.current_open ? s?.current_open : s?.ticker_open} high={s?.current_high >= s?.ticker_high ? s?.current_high : s?.ticker_high} low={s?.current_low >= s?.ticker_low ? s?.ticker_low : s?.current_low == 0 ? s?.ticker_low : s?.current_low}
                                            close={s?.current_close} poscount={s?.poscount} negcount={s?.negcount} arrow={s?.arrow} totalMetrics={s?.totalMetrics} prev_range={Number.isFinite(s?.pre_percentage) ? s?.pre_percentage : 0}
                                        />
                                    </Grid>
                                </Grid>}
                                { value == 2 &&  <Grid className='nse-border'>
                                    <GaugeChart  value={s?.percentage?.toFixed(2)} previousclose={s?.pre_close}
                                    curr_range={s?.current_percentage} 
                                    // current_percentage
                                     cmp={s?.currentprice} 
                                      open={s?.current_open ? s?.current_open : s?.ticker_open}
                                       high={s?.current_high >= s?.ticker_high ? s?.current_high : s?.ticker_high} 
                                       low={s?.current_low >= s?.ticker_low ? s?.ticker_low : s?.current_low == 0 ? s?.ticker_low : s?.current_low} close={s?.current_close} prev_range={Number.isFinite(s?.pre_percentage) ? s?.pre_percentage : 0}/>
                                    {/* <GaugeChartForDatatable value={s?.percentage?.toFixed(2)}open={s?.current_open ? s?.current_open : s?.ticker_open} high={s?.current_high >= s?.ticker_high ? s?.current_high : s?.ticker_high} low={s?.current_low >= s?.ticker_low ? s?.ticker_low : s?.current_low == 0 ? s?.ticker_low : s?.current_low}
                                            close={s?.current_close}  prev_range={Number.isFinite(s?.pre_percentage) ? s?.pre_percentage : 0}  /> */}
                                    <Grid className="flex justify-center" style={{marginTop:'5px'}}>
                                        <Typography variant='body2' className="font-black font-futura flex whitespace-no-wrap" align="center" style={{ color: `${s?.currentprice > s?.pre_close ? "#000066" : "#DA2424"}`, fontSize: '12px' }}>{s?.companyData?.companySymbol}
                                            <span className="flex items-center" style={{ color: "", fontSize: '12px', marginLeft: '3px' }}>{s?.currentprice}</span>
                                            <span className="flex items-center" style={{ color: "", fontSize: '10px' }}>({(s?.currentprice - s?.pre_close).toFixed(2)})({getPerc(s?.currentprice, s?.pre_close)}%)</span>
                                        </Typography>
                                    </Grid>
                                    {/* <Grid className='im-ohlc flex w-full'>
                                        <Grid className="im-lc w-1/3">
                                            <Typography align="center" className="high-color">R: {((s?.current_high >= s?.ticker_high ? s?.current_high : s?.ticker_high) - (s?.current_low >= s?.ticker_low ? s?.ticker_low : s?.current_low))?.toFixed(2)}</Typography>
                                            <Typography align="center" className="" style={{ color: getColorPr(s?.pre_percentage?.toFixed(2)) }}>P.R: {s?.pre_percentage?.toFixed(2)}%</Typography>
                                        </Grid>
                                        <Grid className="im-oh w-1/3">
                                            <Typography align="center" className="high-color">O: {s?.current_open}</Typography>
                                            <Typography align="center" className="high-color">P.C: {s?.pre_close}</Typography>
                                        </Grid>
                                        <Grid className="im-rp w-1/3">
                                            <Typography align="center" className="high-color">H: {s?.current_high >= s?.ticker_high ? s?.current_high : s?.ticker_high}</Typography>
                                            <Typography align="center" className="low-color">L: {s?.current_low >= s?.ticker_low ? s?.ticker_low : s?.current_low == "0" ? s?.ticker_low : s?.current_low} </Typography>
                                        </Grid>
                                    </Grid> */}
                                    <Grid className='standard-observ-indices'>
                                        <MetricCandleStick  cmp={s?.currentprice} percentage={s?.percentage} open={s?.current_open ? s?.current_open : s?.ticker_open} high={s?.current_high >= s?.ticker_high ? s?.current_high : s?.ticker_high} low={s?.current_low >= s?.ticker_low ? s?.ticker_low : s?.current_low == 0 ? s?.ticker_low : s?.current_low}
                                            close={s?.current_close} poscount={s?.poscount} negcount={s?.negcount} arrow={s?.arrow} totalMetrics={s?.totalMetrics} prev_range={Number.isFinite(s?.pre_percentage) ? s?.pre_percentage : 0}
                                        />
                                    </Grid>
                                </Grid>}
                                </>
                                )
                        }
                        )}
                    </Grid>)
            })}
        </Grid>
    )
}

export default MidCapSelectTen