import React, { useEffect, useMemo, useState } from 'react'
import { CurrentCompanyData, GetObservationsForCandle } from '../apiService/tickerApiService';
import AnalysisTables from './AnalysisTables';
import TimeFrameContainer from './TimeFrameContainer';
import { Grid } from '@material-ui/core';
import { companyApiData, companyFibonacciData, companyGannData, companyZonesData } from '../apiService/tradaApiService';
import { cam_key_s, cpr_key_s, fpp_key_s, fre_key_s, gann_key_s, spp_key_s, z_key_s } from '../../utils';
import IndicesStrip from '../common/IndicesStrip';
import IndiceStripNew from '../common/IndiceStripNew';

const timeFramesForDataTable = ["Quarterly", "Half Yearly", "Yearly"]

function InvestorView(props) {
  const { dataFromChild } = props;
  const [tickerRes, setTickerRes] = useState(0);
  const [Response, setResponse] = useState([]);
  const [camarillaData, setCamarilladata] = useState([]);
  const [standardData, setStandarddata] = useState([]);
  const [fibonacciData, setFibonacciData] = useState([]);
  const [cprData, setCprData] = useState([]);
  const [ppData, setPpData] = useState([]);
  const [zonesData, setZonesData] = useState([]);
  const [fibonacciRes, setFibonacciRes] = useState({});
  const [GannRes, setGannRes] = useState({});

  const [cprtl_QY, setCprtl_QY] = React.useState(0);
  const [cprtl_HY, setCprtl_HY] = React.useState(0);
  const [cprtl_Y, setCprtl_Y] = React.useState(0);

  const [cprbl_QY, setCprbl_QY] = React.useState(0);
  const [cprbl_HY, setCprbl_HY] = React.useState(0);
  const [cprbl_Y, setCprbl_Y] = React.useState(0);

  const [prevQC, setPrecQC] = React.useState(0);
  const [prevHYC, setPrevHYC] = React.useState(0);
  const [prevYC, setPrevYC] = React.useState(0);

  const [closePrice, setClosePrice] = React.useState(0);
  const [curr_perc, setCurr_perc] = React.useState(0);

  const [cam_keys, setCam_Keys] = useState({ quarterlyKeys: [], halfYearlyKeys: [], yearlyKeys: [], });
  const [spp_keys, setSpp_Keys] = useState({ quarterlyKeys: [], halfYearlyKeys: [], yearlyKeys: [], });
  const [fb_keys, setFb_Keys] = useState({ quarterlyKeys: [], halfYearlyKeys: [], yearlyKeys: [], });
  const [cpr_keys, setCpr_Keys] = useState({ quarterlyKeys: [], halfYearlyKeys: [], yearlyKeys: [], });
  const [pp_keys, setPp_Keys] = useState({ quarterlyKeys: [], halfYearlyKeys: [], yearlyKeys: [], });
  const [z_keys, setZ_Keys] = useState({ quarterlyKeys: [], halfYearlyKeys: [], yearlyKeys: [], });
  const [f_keys, setF_Keys] = useState({ quarterlyKeys: [], halfYearlyKeys: [], yearlyKeys: [], });
  const [Gann_keys, setGann_Keys] = useState({ quarterlyKeys: [], halfYearlyKeys: [], yearlyKeys: [], });

  const [obj_observation_rest, setObj_Observations_rest] = useState({
    observe_quaerterlyData: [],
    observe_halfYearlylyData: [],
    observe_yearlyData: []
  })

  const _getObservations_rest = async (companySymbol, t) => {
    let _quaerterlyData = await GetObservationsForCandle("Q", companySymbol, t)
    let _halfYearlylyData = await GetObservationsForCandle("H", companySymbol, t)
    let _yearlyData = await GetObservationsForCandle("Y", companySymbol, t)

    setObj_Observations_rest({
      observe_quaerterlyData: _quaerterlyData?.data,
      observe_halfYearlylyData: _halfYearlylyData?.data,
      observe_yearlyData: _yearlyData?.data
    })
  }

  const currentMP = async (token) => {
    const CMPdata = await CurrentCompanyData(token);
    setTickerRes(CMPdata?.data)
    setClosePrice(CMPdata?.data?.closePrice)
    setCurr_perc((CMPdata?.data?.change)?.toFixed(2))
  }

  function filterData(obj, a, b) {
    const entries = Object.entries(obj);
    const filteredAndSorted = entries.filter(([key, value]) => key.startsWith(a) || key.startsWith(b))
      .sort((a, b) => b[1] - a[1]);

    const filteredAndSortedObj = Object.fromEntries(filteredAndSorted);
    return filteredAndSortedObj;
  }
  const fetchCompany = async () => {
    const response = await companyApiData(dataFromChild?.companySymbol)
    if (response?.data?.success) {
      setResponse(response?.data)
      const cam_data = []
      const spp_data = []
      const fibonacci_data = []
      const cpr_data = []
      const pivortPoints_data = []

      let data = response?.data
      //   for camaralla 
      const camQuarterlyData = filterData(data.quarterlyData, "CPH", "CPL");
      const quarterlyCamData = Object.values(camQuarterlyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
      const _quarterlyCamData = Object.keys(camQuarterlyData)

      const camHalfYearlyData = filterData(data.halfYearlyData, "CPH", "CPL");
      const HalfYearlyCamArr = Object.values(camHalfYearlyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
      const _halfYearlyCamKeys = Object.keys(camHalfYearlyData)

      const camYearlyData = filterData(data.yearlyData, "CPH", "CPL");
      const yearlyCamArr = Object.values(camYearlyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
      const _yearlyCamArr = Object.keys(camYearlyData)

      //   for standard pp
      const sppQuarterlyData = filterData(data.quarterlyData, "SPH", "SPL");
      const quarterlysppData = Object.values(sppQuarterlyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
      const _quarterlySppKeys = Object.keys(sppQuarterlyData)

      const sppHalfYearlyData = filterData(data.halfYearlyData, "SPH", "SPL");
      const HalfyearlySppArr = Object.values(sppHalfYearlyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
      const _halfyearlySppArr = Object.keys(sppHalfYearlyData)

      const sppYearlyData = filterData(data.yearlyData, "SPH", "SPL");
      const yearlySppArr = Object.values(sppYearlyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
      const _yearlySppKeys = Object.keys(sppYearlyData)

      //  for fibonacci

      const fbDailyData = filterData(data.quarterlyData, "FPH", "FPL");
      const dailyFbArr = Object.values(fbDailyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
      const _dailyFbKeys = Object.keys(fbDailyData)

      const fbWeeklyData = filterData(data.halfYearlyData, "FPH", "FPL");
      const weeklyFbArr = Object.values(fbWeeklyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
      const _weeklyFbKeys = Object.keys(fbWeeklyData)

      const fbMonthlyData = filterData(data.yearlyData, "FPH", "FPL");
      const monthlyFbArr = Object.values(fbMonthlyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
      const _monthlyFbKeys = Object.keys(fbMonthlyData)

      //  for CPR

      const CPRDailyData = filterData(data.quarterlyData, "CPR", "CPR");
      const dailyCPRArr = Object.values(CPRDailyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
      const _dailyCPRKeys = Object.keys(CPRDailyData)

      const CPRWeeklyData = filterData(data.halfYearlyData, "CPR", "CPR");
      const weeklyCPRArr = Object.values(CPRWeeklyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
      const _weeklyCPRKeys = Object.keys(CPRWeeklyData)

      const CPRMonthlyData = filterData(data.yearlyData, "CPR", "CPR");
      const monthlyCPRArr = Object.values(CPRMonthlyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
      const _monthlyCPRKeys = Object.keys(CPRMonthlyData)

      // for all pivort points

      let dailyArr = Object.values(data.quarterlyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
      let _dailyKeys = Object.keys(data.quarterlyData)

      let weeklyArr = Object.values(data.halfYearlyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
      let _weeklyKeys = Object.keys(data.halfYearlyData)

      let monthlyArr = Object.values(data.yearlyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
      let _monthlyKeys = Object.keys(data.yearlyData)

      dailyArr.map((e, i) => {
        pivortPoints_data.push({ daily: e, weekly: weeklyArr[i], monthly: monthlyArr[i] })
      })

      quarterlyCamData.map((e, i) => {
        cam_data.push({ daily: e, weekly: HalfYearlyCamArr[i], monthly: yearlyCamArr[i] })
      })

      quarterlysppData.map((e, i) => {
        spp_data.push({ daily: e, weekly: HalfyearlySppArr[i], monthly: yearlySppArr[i] })
      })
      dailyFbArr.map((e, i) => {
        fibonacci_data.push({ daily: e, weekly: weeklyFbArr[i], monthly: monthlyFbArr[i] })
      })
      dailyCPRArr.map((e, i) => {
        cpr_data.push({ daily: e, weekly: weeklyCPRArr[i], monthly: monthlyCPRArr[i] })
      })

      setCamarilladata(cam_data)
      setStandarddata(spp_data)
      setFibonacciData(fibonacci_data)
      setCprData(cpr_data)
      setPpData(pivortPoints_data)

      setPrecQC(response?.data?.pre_CLOSE_QY[0]?.PRE_CLOSE_QY)
      setPrevHYC(response?.data?.pre_CLOSE_HY[0]?.PRE_CLOSE_HY)
      setPrevYC(response?.data?.pre_CLOSE_Y[0]?.PRE_CLOSE_Y)

      setCprtl_QY(data?.quarterlyData?.CPRTL_Q)
      setCprtl_HY(data?.halfYearlyData?.CPRTL_HY)
      setCprtl_Y(data?.yearlyData?.CPRTL_Y)

      setCprbl_QY(data?.quarterlyData?.CPRBL_Q)
      setCprbl_HY(data?.halfYearlyData?.CPRBL_HY)
      setCprbl_Y(data?.yearlyData?.CPRBL_Y)

      setCam_Keys({ quarterlyKeys: _quarterlyCamData, halfYearlyKeys: _halfYearlyCamKeys, yearlyKeys: _yearlyCamArr })
      setSpp_Keys({ quarterlyKeys: _quarterlySppKeys, halfYearlyKeys: _halfyearlySppArr, yearlyKeys: _yearlySppKeys })
      setFb_Keys({ quarterlyKeys: _dailyFbKeys, halfYearlyKeys: _weeklyFbKeys, yearlyKeys: _monthlyFbKeys })
      setCpr_Keys({ quarterlyKeys: _dailyCPRKeys, halfYearlyKeys: _weeklyCPRKeys, yearlyKeys: _monthlyCPRKeys })
      setPp_Keys({ quarterlyKeys: _dailyKeys, halfYearlyKeys: _weeklyKeys, yearlyKeys: _monthlyKeys })
    } else if (response?.data?.message) {
      setCamarilladata([])
    }
  }

  const fetchZones = async () => {
    let zdata = [];
    const zonesres = await companyZonesData(dataFromChild?.companySymbol)
    if (zonesres?.data?.success) {
      setZonesData(zonesres?.data?.data)

      let q_z_data = filterData(zonesres?.data?.data, "q", "q");
      const quarterlyZArr = Object.values(q_z_data).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
      const _quarterlyZKeys = Object.keys(q_z_data)

      let h_z_data = filterData(zonesres?.data?.data, "h", "h");
      const halfyearlyZArr = Object.values(h_z_data).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
      const _halfyearlyZArr = Object.keys(h_z_data)

      let y_z_data = filterData(zonesres?.data?.data, "y", "y");
      const yearlyZArr = Object.values(y_z_data).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
      const _yearlyZArr = Object.keys(y_z_data)

      quarterlyZArr.map((e, i) => {
        zdata.push({ daily: e, weekly: halfyearlyZArr[i], monthly: yearlyZArr[i] })//"Quarterly", "Half Yearly", "Yearly"
      })

      setZ_Keys({ quarterlyKeys: _quarterlyZKeys, halfYearlyKeys: _halfyearlyZArr, yearlyKeys: _yearlyZArr })
      setZonesData(zdata)
    } else {
      setZonesData([])
    }
  }
  const fetchFibonacciData = async () => {
    let fibonacciData = []
    const fibonacciRes = await companyFibonacciData(dataFromChild?.companySymbol)

    if (fibonacciRes?.data?.success) {

      let Fib_data = fibonacciRes?.data

      let Fib_D_quarArr = Object.values(Fib_data?.quarterlyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
      let Fib_D_quarKeys = Object.keys(Fib_data?.quarterlyData)

      let Fib_D_halfArr = Object.values(Fib_data?.halfYearlyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
      let Fib_D_halfKeys = Object.keys(Fib_data?.halfYearlyData)

      let Fib_D_yearArr = Object.values(Fib_data?.yearlyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
      let Fib_D_yearKeys = Object.keys(Fib_data?.yearlyData)

      Fib_D_quarArr.map((e, i) => {
        fibonacciData.push({ daily: e, weekly: Fib_D_halfArr[i], monthly: Fib_D_yearArr[i] })
      })
      setF_Keys({ quarterlyKeys: Fib_D_quarKeys, halfYearlyKeys: Fib_D_halfKeys, yearlyKeys: Fib_D_yearKeys, })
      setFibonacciRes(fibonacciData)
    }
  }
  const fetchGannData = async () => {
    let GannData = []
    const GannResponse = await companyGannData(dataFromChild?.companySymbol)
    if (GannResponse?.data?.success) {

      let gann_data = GannResponse?.data

      let Gann_D_quarArr = Object.values(gann_data?.quarterlyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
      let Gannb_D_quarKeys = Object.keys(gann_data?.quarterlyData)

      let Gann_D_halfArr = Object.values(gann_data?.halfYearlyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
      let Gannb_D_halfKeys = Object.keys(gann_data?.halfYearlyData)

      let Gann_D_yearArr = Object.values(gann_data?.yearlyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
      let Gannb_D_yearKeys = Object.keys(gann_data?.yearlyData)

      Gann_D_quarArr.map((e, i) => {
        GannData.push({ daily: e, weekly: Gann_D_halfArr[i], monthly: Gann_D_yearArr[i] })
      })
      setGann_Keys({ dailyKeys: Gannb_D_quarKeys, weeklyKeys: Gannb_D_halfKeys, monthlyKeys: Gannb_D_yearKeys, })
      setGannRes(GannData)
    }
  }

  useEffect(() => {
    fetchCompany()
    fetchZones()
    fetchFibonacciData()
    fetchGannData()
    const observationsInterVal_d_w = setInterval(() => {
      if (dataFromChild?.companySymbol && dataFromChild?.token) {
        _getObservations_rest(dataFromChild?.companySymbol, dataFromChild?.token)
      }
    }, 30000);
    const intervalId = setInterval(() => {
      if (dataFromChild?.token) {
        currentMP(dataFromChild?.token);
      }
    }, 2000);
    return () => {
      clearInterval(intervalId);
      clearInterval(observationsInterVal_d_w)
    }
  }, [dataFromChild]);

  useMemo(() => {
    if (dataFromChild?.companySymbol && dataFromChild?.token) {
      _getObservations_rest(dataFromChild?.companySymbol, dataFromChild?.token)
    }
  }, [dataFromChild])

  const getPercentage = (i) => {
    switch (i) {
      case 3:
        let prec_Q = ((tickerRes?.lastTradedPrice - prevQC) / prevQC) * 100
        let Q_points = (tickerRes?.lastTradedPrice - prevQC)?.toFixed(2)
        return <>
          <p style={{ fontSize: '11px', color: `${Q_points >= 0 ? "#339249" : "#DA2424"}` }}>{Q_points ? Q_points : 0}</p>
          <p style={{ fontSize: '11px', color: `${prec_Q >= 0 ? "#339249" : "#DA2424"}` }}>{`(${prec_Q ? prec_Q.toFixed(2) : 0}%)`}</p>
        </>
      case 4:
        let prec_HY = ((tickerRes?.lastTradedPrice - prevHYC) / prevHYC) * 100
        let HY_points = (tickerRes?.lastTradedPrice - prevHYC)?.toFixed(2)
        return <>
          <p style={{ fontSize: '11px', color: `${HY_points >= 0 ? "#339249" : "#DA2424"}` }}>{HY_points ? HY_points : 0}</p>
          <p style={{ fontSize: '11px', color: `${prec_HY >= 0 ? "#339249" : "#DA2424"}` }}>{`(${prec_HY ? prec_HY.toFixed(2) : 0}%)`}</p>
        </>
      case 5:
        let prec_Y = ((tickerRes?.lastTradedPrice - prevYC) / prevYC) * 100
        let Y_points = (tickerRes?.lastTradedPrice - prevYC)?.toFixed(2)
        return <>
          <p style={{ fontSize: '11px', color: `${Y_points >= 0 ? "#339249" : "#DA2424"}` }}>{Y_points ? Y_points : 0}</p>
          <p style={{ fontSize: '11px', color: `${prec_Y >= 0 ? "#339249" : "#DA2424"}` }}>{`(${prec_Y ? prec_Y.toFixed(2) : 0}%)`}</p>
        </>
      default:
        return <p>0</p>
    }
  }
  const getObservations = (tf) => {
    switch (tf) {
      case "Q":
        let q_obser = {
          arrow: obj_observation_rest?.observe_quaerterlyData[0]?.arrow, poscount: obj_observation_rest?.observe_quaerterlyData[0]?.poscount, negcount: obj_observation_rest?.observe_quaerterlyData[0]?.negcount,
          neutralcount: obj_observation_rest?.observe_quaerterlyData[0]?.neutralcount, totalMetrics: obj_observation_rest?.observe_quaerterlyData[0]?.totalMetrics, rest_data: obj_observation_rest?.observe_quaerterlyData[0]
        }
        return q_obser
      case "H":
        let h_obser = {
          arrow: obj_observation_rest?.observe_halfYearlylyData[0]?.arrow, poscount: obj_observation_rest?.observe_halfYearlylyData[0]?.poscount, negcount: obj_observation_rest?.observe_halfYearlylyData[0]?.negcount,
          neutralcount: obj_observation_rest?.observe_halfYearlylyData[0]?.neutralcount, totalMetrics: obj_observation_rest?.observe_halfYearlylyData[0]?.totalMetrics, rest_data: obj_observation_rest?.observe_halfYearlylyData[0]
        }
        return h_obser
      case "Y":
        let y_obser = {
          arrow: obj_observation_rest?.observe_yearlyData[0]?.arrow, poscount: obj_observation_rest?.observe_yearlyData[0]?.poscount, negcount: obj_observation_rest?.observe_yearlyData[0]?.negcount,
          neutralcount: obj_observation_rest?.observe_yearlyData[0]?.neutralcount, totalMetrics: obj_observation_rest?.observe_yearlyData[0]?.totalMetrics, rest_data: obj_observation_rest?.observe_yearlyData[0]
        }
        return y_obser
      default:
        return {};
    }
  }

  const timeFrames = [
    {
      timeFrame: "Quarterly",
      curr_open: Response?.open_QY?.length > 0 ? Response?.open_QY[0]?.OPEN_QY?.toFixed(2) : 0,
      curr_high: Response.quarterlyLowHigh?.length > 0 ? (tickerRes ? (Response.quarterlyLowHigh[0].high > tickerRes.highPrice ? Response.quarterlyLowHigh[0].high : tickerRes.highPrice) : Response.quarterlyLowHigh[0].high)?.toFixed(2) : 0,
      curr_low: Response.quarterlyLowHigh?.length > 0 ? (tickerRes ? (Response.quarterlyLowHigh[0].low < tickerRes.lowPrice ? (Response.quarterlyLowHigh[0].low == 0 ? tickerRes.lowPrice : Response.quarterlyLowHigh[0].low) : tickerRes.lowPrice) : Response.quarterlyLowHigh[0].low)?.toFixed(2) : 0,
      // curr_range: (tickerRes ? (Response.quarterlyLowHigh[0].high > tickerRes.highPrice ? Response.quarterlyLowHigh[0].high : tickerRes.highPrice) : Response.quarterlyLowHigh[0].high)?.toFixed(2) - (tickerRes ? (Response.quarterlyLowHigh[0].low < tickerRes.lowPrice ? (Response.quarterlyLowHigh[0].low == 0 ? tickerRes.lowPrice : Response.quarterlyLowHigh[0].low) : tickerRes.lowPrice) : Response.quarterlyLowHigh[0].low)?.toFixed(2),
      prev_Range_perc: Response?.pre_quarterlyLowHigh?.length > 0 ? (((tickerRes?.lastTradedPrice - Response?.pre_quarterlyLowHigh[0]?.pre_low) / (Response?.pre_quarterlyLowHigh[0]?.pre_high - Response?.pre_quarterlyLowHigh[0]?.pre_low)) * 100)?.toFixed(2) : 0,
      prev_high: Response?.pre_quarterlyLowHigh?.length > 0 ? Response?.pre_quarterlyLowHigh[0]?.pre_high?.toFixed(2) : 0,
      prev_low: Response?.pre_quarterlyLowHigh?.length > 0 ? Response?.pre_quarterlyLowHigh[0]?.pre_low?.toFixed(2) : 0,
      prev_close: Response?.pre_CLOSE_QY?.length > 0 ? Response?.pre_CLOSE_QY[0]?.PRE_CLOSE_QY?.toFixed(2) : 0,
      percentage: getPercentage(3),
      observations: getObservations("Q")
    },
    {
      timeFrame: "Half Yearly",
      curr_open: Response?.open_HY?.length > 0 ? Response?.open_HY[0]?.OPEN_HY?.toFixed(2) : 0,
      curr_high: Response.halfYearlyLowHigh?.length > 0 ? (tickerRes ? (Response.halfYearlyLowHigh[0].high > tickerRes.highPrice ? Response.halfYearlyLowHigh[0].high : tickerRes.highPrice) : Response.halfYearlyLowHigh[0].high)?.toFixed(2) : 0,
      curr_low: Response.halfYearlyLowHigh?.length > 0 ? (tickerRes ? (Response.halfYearlyLowHigh[0].low < tickerRes.lowPrice ? (Response.halfYearlyLowHigh[0].low == 0 ? tickerRes.lowPrice : Response.halfYearlyLowHigh[0].low) : tickerRes.lowPrice) : Response.halfYearlyLowHigh[0].low)?.toFixed(2) : 0,
      // curr_range: (tickerRes ? (Response.halfYearlyLowHigh[0].high > tickerRes.highPrice ? Response.halfYearlyLowHigh[0].high : tickerRes.highPrice) : Response.halfYearlyLowHigh[0].high)?.toFixed(2) - (tickerRes ? (Response.halfYearlyLowHigh[0].low < tickerRes.lowPrice ? (Response.halfYearlyLowHigh[0].low == 0 ? tickerRes.lowPrice : Response.halfYearlyLowHigh[0].low) : tickerRes.lowPrice) : Response.halfYearlyLowHigh[0].low)?.toFixed(2),
      prev_Range_perc: Response?.pre_halfYearlyLowHigh?.length > 0 ? (((tickerRes?.lastTradedPrice - Response?.pre_halfYearlyLowHigh[0]?.pre_low) / (Response?.pre_halfYearlyLowHigh[0]?.pre_high - Response?.pre_halfYearlyLowHigh[0]?.pre_low)) * 100)?.toFixed(2) : 0,
      prev_high: Response?.pre_halfYearlyLowHigh?.length > 0 ? Response?.pre_halfYearlyLowHigh[0]?.pre_high?.toFixed(2) : 0,
      prev_low: Response?.pre_halfYearlyLowHigh?.length > 0 ? Response?.pre_halfYearlyLowHigh[0]?.pre_low?.toFixed(2) : 0,
      prev_close: Response?.pre_CLOSE_HY?.length > 0 ? Response?.pre_CLOSE_HY[0]?.PRE_CLOSE_HY?.toFixed(2) : 0,
      percentage: getPercentage(4),
      observations: getObservations("H")
    },
    {
      timeFrame: "Yearly",
      curr_open: Response?.open_Y?.length > 0 ? Response?.open_Y[0]?.OPEN_YEAR?.toFixed(2) : 0,
      curr_high: Response.yearlyLowHigh?.length > 0 ? (tickerRes ? (Response.yearlyLowHigh[0].high > tickerRes.highPrice ? Response.yearlyLowHigh[0].high : tickerRes.highPrice) : Response.yearlyLowHigh[0].high)?.toFixed(2) : 0,
      curr_low: Response.yearlyLowHigh?.length > 0 ? (tickerRes ? (Response.yearlyLowHigh[0].low < tickerRes.lowPrice ? (Response.yearlyLowHigh[0].low == 0 ? tickerRes.lowPrice : Response.yearlyLowHigh[0].low) : tickerRes.lowPrice) : Response.yearlyLowHigh[0].low)?.toFixed(2) : 0,
      // curr_range: (tickerRes ? (Response.yearlyLowHigh[0].high > tickerRes.highPrice ? Response.yearlyLowHigh[0].high : tickerRes.highPrice) : Response.yearlyLowHigh[0].high)?.toFixed(2) - (tickerRes ? (Response.yearlyLowHigh[0].low < tickerRes.lowPrice ? (Response.yearlyLowHigh[0].low == 0 ? tickerRes.lowPrice : Response.yearlyLowHigh[0].low) : tickerRes.lowPrice) : Response.yearlyLowHigh[0].low)?.toFixed(2),
      prev_Range_perc: Response.yearlyLowHigh?.length > 0 ? (((tickerRes?.lastTradedPrice - Response?.pre_yearlyLowHigh[0]?.pre_low) / (Response?.pre_yearlyLowHigh[0]?.pre_high - Response?.pre_yearlyLowHigh[0]?.pre_low)) * 100)?.toFixed(2) : 0,
      prev_high: Response?.pre_yearlyLowHigh?.length > 0 ? Response?.pre_yearlyLowHigh[0]?.pre_high?.toFixed(2) : 0,
      prev_low: Response?.pre_yearlyLowHigh?.length > 0 ? Response?.pre_yearlyLowHigh[0]?.pre_low?.toFixed(2) : 0,
      prev_close: Response?.pre_CLOSE_Y?.length > 0 ? Response?.pre_CLOSE_Y[0]?.PRE_CLOSE_Y?.toFixed(2) : 0,
      percentage: getPercentage(5),
      observations: getObservations("Y")
    },
  ]

  return (
    <>
      <Grid className='trading-ticker'>
        <Grid className="table-ticker">
          <Grid className='company-cmp'>
            <Grid className="details">
              <Grid className="details-context px-2 py-1"
                style={{ color: '#fff', marginLeft: '0px' }}>
                {dataFromChild?.companySymbol}
              </Grid>
            </Grid>
            <Grid >
              <Grid className='pricing m-2'>
                <Grid style={{
                  color: `${closePrice <= tickerRes?.lastTradedPrice ? 'rgb(51, 146, 73)' : 'rgb(218, 36, 36)'}`, display: "flex", alignItems: "center", backgroundColor: '#fff', padding: '0px 13px', borderRadius: '4px',
                }}>
                  <p className='mr-1'>{tickerRes?.lastTradedPrice ? tickerRes?.lastTradedPrice : 0}</p>
                  <p style={{ fontSize: '11px', color: '#857d7d' }}>{tickerRes ? (tickerRes?.lastTradedPrice - tickerRes?.closePrice)?.toFixed(2) : 0}</p>
                  <p style={{ fontSize: '11px', color: '#857d7d' }}>{`(${tickerRes.change ? (tickerRes.change)?.toFixed(2) : 0}%)`}</p>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
          <Grid className='flex'>
            {/* <IndicesStrip token="256265" IndiceLabel="NIFTY50" />
            <IndicesStrip token="260105" IndiceLabel="BANK NIFTY" />
            <IndicesStrip token="259849" IndiceLabel="IT NIFTY" /> */}
            <IndiceStripNew showNifty50={true} showBankNifty={true} showITNifty={true} showNIFTYf={true}/>
          </Grid>
        </Grid>
        <TimeFrameContainer timeFrames={timeFrames} tickerData={tickerRes} />
      </Grid>
      <Grid className="invest-tables">
        <Grid className='etf-col'>
          <AnalysisTables heading="Camarilla Pivot Point Analysis" timeFramesForDataTable={timeFramesForDataTable} data={camarillaData} keys={cam_keys} h_l_keys={cam_key_s} />
          <AnalysisTables heading="Standard Pivot Point Analysis" timeFramesForDataTable={timeFramesForDataTable} data={standardData} keys={spp_keys} h_l_keys={spp_key_s} />
          <AnalysisTables heading="Zones Analysis" zones={true} timeFramesForDataTable={timeFramesForDataTable} data={zonesData} keys={z_keys} h_l_keys={z_key_s} />
        </Grid>
        <Grid className='etf-col'>
          <AnalysisTables heading="All Pivot Points Analysis" timeFramesForDataTable={timeFramesForDataTable} data={ppData} keys={pp_keys} />
          <AnalysisTables heading="Central Pivot Range (CPR) Analysis" timeFramesForDataTable={timeFramesForDataTable} data={cprData} keys={cpr_keys} h_l_keys={cpr_key_s} />
        </Grid>
        <Grid className='etf-col'>
          <AnalysisTables heading="Fibonacci Retracement & Extension Analysis" timeFramesForDataTable={timeFramesForDataTable} data={fibonacciRes} keys={f_keys} h_l_keys={fre_key_s} fibonacci={true} />
          <AnalysisTables heading="Fibonacci Pivot Point Analysis" timeFramesForDataTable={timeFramesForDataTable} data={fibonacciData} keys={fb_keys} h_l_keys={fpp_key_s} />
        </Grid>
        <Grid className='etf-col'>
          <AnalysisTables heading="Gann Analysis" timeFramesForDataTable={timeFramesForDataTable} data={GannRes} keys={Gann_keys} h_l_keys={gann_key_s} Gaan={true} />
        </Grid>
      </Grid>
    </>
  )
}

export default InvestorView
