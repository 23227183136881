import { FormControl, Grid, MenuItem, Select, TableBody, TableCell, TableContainer, TableHead, Typography } from '@material-ui/core'
import React, { useEffect, useMemo } from 'react'
import { useState } from 'react'
import { fetchOptioOi } from '../apiService/tickerApiService'
import { Box, Table, TableRow } from '@mui/material'
import IndicesStrip from '../common/IndicesStrip'
import IndiceStripNew from '../common/IndiceStripNew'

const headerStyle = {
    padding: "5px 10px",
    color: '#fff',
    borderRight: "1px solid white",
    textAlign: "center",
    fontSize: "13px"
}

const cellStyle = {
    padding: "5px ",
    borderRight: "1px solid gray",
    textAlign: "right"
}
const optionsList = [
    { label: "NIFTY 50 - Weekly", value: "NIFTY50-W", data: "500", },
    { label: "NIFTY 50 - Monthly", value: "NIFTY50-M", data: "500", },
    { label: "Bank Nifty - Monthly", value: "BANKNIFTY", data: "1100" },
];

const arrHead = ["CALL OI", "CALL OI CHANGE", "PRICE CHANGE%", "LTP", "STRIKE PRICE", "LTP", "PRICE CHANGE%", "PUT OI CHANGE", "PUT OI", "CALL COI %", "PUT COI %", "OI CHANGE", "IMBALANCE %", "STRIKE PRICE", "OUTLOOK"]

function OptionsOI() {

    const [data, setData] = useState([])
    const [max, setMax] = useState();
    const [min, setMin] = useState();
    const [callCOIChange, setCallCOIChange] = useState(0);
    const [putCOIChange, setPutCOIChange] = useState(0);
    const [callCOI, setCallCOI] = useState(0);
    const [putCOI, setPutCOI] = useState(0);
    const [oiChange, setOiChange] = useState(0);
    const [imbalance, setImbalance] = useState(0);
    const [maxCallOIChange, setMaxCallOIChange] = useState(0);
    const [maxPutOIChange, setMaxPutOIChange] = useState(0);

    const [selectedIndex, setSelectedIndex] = useState(optionsList?.[0]);

    const handleChange = (event) => {
        const selectedValue = event.target.value;
        const selectedObject = optionsList.find(option => option.value === selectedValue);  // Find the full object
        setSelectedIndex(selectedObject);
    };

    const fetchData = async (selectedIndex) => {
        const w = selectedIndex.label.split("-")?.[1]?.toLowerCase().trim()
        const a = await fetchOptioOi(selectedIndex?.value.split("-")?.[0], selectedIndex?.data, w === "weekly" ? w : '')
        const res = await a?.data.sort((a, b) => b.strikePrice - a.strikePrice)
        // const diffValues = res.map(item => item.Diff);

        // Get the maximum and minimum values of `Diff`
        // const maxDiff = Math.max(...diffValues)*100;
        // const minDiff = Math.min(...diffValues)*100;


        const diffOiChange = res.map(el => {
            let CEIndex = el.companies?.[0]?.companySymbol.endsWith("CE") ? 0 : 1
            let PEIndex = el.companies?.[1]?.companySymbol.endsWith("PE") ? 1 : 0
            return (el.companies[PEIndex]?.oichange - el.companies[CEIndex]?.oichange)
        })
        const maxOi = Math.max(...diffOiChange);
        const minOi = Math.min(...diffOiChange);

        const oiChangeAvg = diffOiChange.reduce((a, b) => a + b, 0)
        const callCOIAvg = res.reduce((a, b) => a + b.CallCOI, 0)
        const putCOIAvg = res.reduce((a, b) => a + b.PutCOI, 0)
        const diffCOIAvg = res.reduce((a, b) => a + b.Diff, 0)

        const totalOiChange = res.reduce((acc, item) => {
            const ceSum = item.companies.filter(company => company.companySymbol.endsWith("CE")).reduce((sum, company) => sum + company.oichange, 0);
            const peSum = item.companies.filter(company => company.companySymbol.endsWith("PE")).reduce((sum, company) => sum + company.oichange, 0);

            return { totalCE: acc.totalCE + ceSum, totalPE: acc.totalPE + peSum }
        }, { totalCE: 0, totalPE: 0, ceArray: [], peArray: [] });


        let ceOiChanges = [];
        let peOiChanges = [];

        res.forEach((strike) => {
            strike.companies.forEach((company) => {
                if (company.companySymbol.includes("CE")) {
                    ceOiChanges.push(company.oichange);
                } else if (company.companySymbol.includes("PE")) {
                    peOiChanges.push(company.oichange);
                }
            });
        });

        setMaxCallOIChange(Math.max(...ceOiChanges))
        setMaxPutOIChange(Math.max(...peOiChanges))

        setData(res)
        setMax(maxOi)
        setMin(minOi)
        setCallCOIChange(totalOiChange.totalCE / res?.length)
        setPutCOIChange(totalOiChange.totalPE / res?.length)
        setOiChange(oiChangeAvg / res?.length)
        setCallCOI((callCOIAvg * 100) / res?.length)
        setPutCOI(putCOIAvg * 100 / res?.length)
        setImbalance(diffCOIAvg * 100 / res?.length)
    }

    useEffect(() => {
        let intervalId = setInterval(() => {
            selectedIndex.value && fetchData(selectedIndex)
        }, 60000)

        return () => {
            clearInterval(intervalId)
        }
    }, [selectedIndex])

    useMemo(() => {
        console.log("selectedIndex",selectedIndex)
        selectedIndex.value && fetchData(selectedIndex)
    }, [selectedIndex])

    const getRemarks = (v) => {
        if (v >= 30) {
            return "SUPPORT"
        } else if (v <= -30) {
            return "RESISTANCE"
        } else {
            return "NEUTRAL"
        }
    }

    const getColor = (str) => {
        switch (str) {
            case "SUPPORT": return "#339249"
            case "RESISTANCE": return "#DA2424"
            case "NEUTRAL": return "#ffb200e3"
        }
    }
    // console.log('selectedIndex', selectedIndex);

    // const highestOi = Math.max(...data.map(el => el.companies?.[CEIndex]?.oi || 0));
    return (
        <>
            <Box sx={{ display: "block", justifyContent: "center" }}>
                <Grid className="table-ticker" style={{ height: '' }}>
                    <Grid className='flex'>
                        <FormControl className='select-dropdown' >
                            {/* <Select value={selectedIndex} onChange={handleChange} style={{ fontFamily: "Futura PT Book", fontWeight: "bold", display: 'flex', width: '100%' }} labelId="demo-simple-select-label" id="demo-simple-select">
                                <MenuItem value="NIFTY50" style={{ fontSize: "13px", fontFamily: "Futura PT Book", borderBottom: "1px solid lightgrey" }}>NIFTY50</MenuItem>
                                <MenuItem value="BANKNIFTY" style={{ fontSize: "13px", fontFamily: "Futura PT Book", borderBottom: "1px solid lightgrey" }}>BANK NIFTY</MenuItem>
                            </Select> */}
                            <Select value={selectedIndex.value} onChange={handleChange} style={{ fontFamily: "Futura PT Book", fontWeight: "bold", display: 'flex', width: '100%' }} labelId="demo-simple-select-label" id="demo-simple-select"
                            MenuProps={{anchorOrigin: {vertical: "bottom",horizontal: "left"},transformOrigin: {vertical: "top",horizontal: "left"},getContentAnchorEl: null}}>
                                {optionsList.map((e, i) => (
                                    <MenuItem value={e.value} key={i} style={{ fontSize: "13px", fontFamily: "Futura PT Book", borderBottom: "1px solid lightgrey" }}>{e.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid className='flex'>
                        {/* <IndicesStrip token="256265" IndiceLabel="NIFTY 50" />
                        <IndicesStrip token="260105" IndiceLabel="BANK NIFTY" />
                        <IndicesStrip token="259849" IndiceLabel="IT NIFTY" /> */}
                        <IndiceStripNew showNifty50={true} showBankNifty={true} showITNifty={true}/>
                    </Grid>
                </Grid>
                <Grid>
                    <TableContainer style={{ borderRadius: "10px", height: `calc(100vh - 148px)` }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead style={{ backgroundColor: "#154a72" }}>
                                <TableRow key={1}>
                                    <TableCell style={{ ...headerStyle, fontWeight: "bold" }} colSpan={4} align="center">
                                        {selectedIndex.value.includes("NIFTY50") ? "NIFTY CALLS" : "BANK NIFTY CALLS"}
                                    </TableCell>
                                    <TableCell style={{ ...headerStyle }}></TableCell>
                                    <TableCell style={{ ...headerStyle, fontWeight: "bold" }} colSpan={4} align="center">
                                        {/* PUTS */}
                                        {selectedIndex.value.includes("NIFTY50") ? "NIFTY PUTS" : "BANK NIFTY PUTS"}
                                    </TableCell>
                                    <TableCell style={{ ...headerStyle, fontWeight: "bold" }} colSpan={6}>
                                        {selectedIndex.value.includes("NIFTY50") ? "NIFTY OPTION CHAIN STATS" : "BANK NIFTY OPTION CHAIN STATS"}
                                    </TableCell>
                                </TableRow>
                                <TableRow key={2}>
                                    {/* {arrHead.map((str,i) => (<TableCell key={i} style={{ ...headerStyle }}>{str}</TableCell>))} */}
                                    <TableCell style={{ ...headerStyle }}>CALL OI</TableCell>
                                    <TableCell style={{ ...headerStyle }}>CALL OI CHANGE</TableCell>
                                    <TableCell style={{ ...headerStyle }}>PRICE CHANGE%</TableCell>
                                    <TableCell style={{ ...headerStyle }}>LTP</TableCell>
                                    <TableCell style={{ ...headerStyle }}>STRIKE PRICE</TableCell>
                                    <TableCell style={{ ...headerStyle }}>LTP</TableCell>
                                    <TableCell style={{ ...headerStyle }}>PRICE CHANGE%</TableCell>
                                    <TableCell style={{ ...headerStyle }}>PUT OI CHANGE</TableCell>
                                    <TableCell style={{ ...headerStyle }}>PUT OI</TableCell>
                                    <TableCell style={{ ...headerStyle }}>CALL COI %</TableCell>
                                    <TableCell style={{ ...headerStyle }}>PUT COI %</TableCell>
                                    <TableCell style={{ ...headerStyle }}>OI CHANGE</TableCell>
                                    <TableCell style={{ ...headerStyle }}>IMBALANCE %</TableCell>
                                    <TableCell style={{ ...headerStyle }}>STRIKE PRICE</TableCell>
                                    <TableCell style={{ ...headerStyle }}>OUTLOOK</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.length > 0 ?
                                    data?.map((el, i) => {
                                        let CEIndex = el.companies?.[0]?.companySymbol.endsWith("CE") ? 0 : 1
                                        let PEIndex = el.companies?.[1]?.companySymbol.endsWith("PE") ? 1 : 0
                                        const highestCallOi = Math.max(...data.map(el => el.companies?.[CEIndex]?.oi || 0));
                                        const highestPEOi = Math.max(...data.map(el => el.companies?.[PEIndex]?.oi || 0));
                                        return (
                                            <>
                                                <TableRow key={i}>
                                                    <TableCell style={{
                                                        ...cellStyle, borderLeft: "1px solid gray",
                                                        color: Number.isFinite(el.companies?.[CEIndex]?.oi) && el.companies?.[CEIndex]?.oi === highestCallOi ? "#fff" : "inherit", fontWeight: 'bold',
                                                        backgroundColor: Number.isFinite(el.companies?.[CEIndex]?.oi) && el.companies?.[CEIndex]?.oi === highestCallOi ? "#DA2424" : "inherit"
                                                    }}>{Number.isFinite(el.companies?.[CEIndex]?.oi) ? (el.companies?.[CEIndex]?.oi / 100000)?.toFixed(2) : 0}L</TableCell>

                                                    <TableCell style={{
                                                        ...cellStyle, fontWeight: 'bold',
                                                        color: el.companies?.[CEIndex]?.oichange == maxCallOIChange ? "#fff" : el.companies?.[CEIndex]?.oichange > 0 ? "#339249" : "#DA2424",
                                                        backgroundColor: el.companies?.[CEIndex]?.oichange == maxCallOIChange ? "#DA2424" : "inherit"
                                                    }}>
                                                        {Number.isFinite(el.companies?.[CEIndex]?.oichange) ? (el.companies?.[CEIndex]?.oichange / 100000)?.toFixed(2) : 0}L
                                                    </TableCell>

                                                    <TableCell style={{ ...cellStyle, color: el.companies?.[CEIndex]?.priceChangePercentage > 0 ? "#339249" : "#DA2424" }}>{Number.isFinite(el.companies?.[CEIndex]?.priceChangePercentage) ? el.companies?.[CEIndex]?.priceChangePercentage?.toFixed(2) : 0}%</TableCell>
                                                    <TableCell style={{ ...cellStyle, color: el.companies?.[CEIndex]?.priceChangePercentage > 0 ? "#339249" : "#DA2424" }}>{Number.isFinite(el.companies?.[CEIndex]?.lastTradedPrice) ? el.companies?.[CEIndex]?.lastTradedPrice?.toFixed(2) : 0}</TableCell>

                                                    <TableCell style={{ ...cellStyle, textAlign: 'center', fontWeight: "bold", color: "#4D8FCC", fontSize: "15px" }}>{Number.isFinite(el.strikePrice) ? el.strikePrice : 0}</TableCell>

                                                    <TableCell style={{ ...cellStyle, color: el.companies?.[PEIndex]?.priceChangePercentage > 0 ? "#339249" : "#DA2424" }}>{Number.isFinite(el.companies?.[PEIndex]?.lastTradedPrice) ? el.companies?.[PEIndex]?.lastTradedPrice?.toFixed(2) : 0}</TableCell>
                                                    <TableCell style={{ ...cellStyle, color: el.companies?.[PEIndex]?.priceChangePercentage > 0 ? "#339249" : "#DA2424" }}>{Number.isFinite(el.companies?.[PEIndex]?.priceChangePercentage) ? el.companies?.[PEIndex]?.priceChangePercentage?.toFixed(2) : 0}%</TableCell>

                                                    <TableCell style={{
                                                        ...cellStyle, fontWeight: 'bold',
                                                        color: el.companies?.[PEIndex]?.oichange == maxPutOIChange ? "#fff" : el.companies?.[PEIndex]?.oichange > 0 ? "#339249" : "#DA2424",
                                                        backgroundColor: el.companies?.[PEIndex]?.oichange == maxPutOIChange ? "#339249" : "inherit"
                                                    }}>
                                                        {Number.isFinite(el.companies?.[PEIndex]?.oichange) ? (el.companies?.[PEIndex]?.oichange / 100000)?.toFixed(2) : 0}L
                                                    </TableCell>

                                                    <TableCell style={{
                                                        ...cellStyle,
                                                        color: Number.isFinite(el.companies?.[PEIndex]?.oi) && el.companies?.[PEIndex]?.oi === highestPEOi ? "#fff" : "inherit", fontWeight: 'bold',
                                                        backgroundColor: Number.isFinite(el.companies?.[PEIndex]?.oi) && el.companies?.[PEIndex]?.oi === highestPEOi ? "#339249" : "inherit"
                                                    }}>
                                                        {Number.isFinite(el.companies?.[PEIndex]?.oi) ? (el.companies?.[PEIndex]?.oi / 100000)?.toFixed(2) : 0}L</TableCell>

                                                    <TableCell style={{ ...cellStyle, color: el.Diff * 100 === max ? '#fff' : el.Diff * 100 === min ? '#fff' : getColor(getRemarks(el.Diff * 100)), backgroundColor: el.Diff * 100 === max ? '#339249' : el.Diff * 100 === min ? '#DA2424' : "#fff" }}>{Number.isFinite(el.CallCOI) ? (el.CallCOI * 100)?.toFixed(2) : 0}%</TableCell>
                                                    <TableCell style={{ ...cellStyle, color: el.Diff * 100 === max ? '#fff' : el.Diff * 100 === min ? '#fff' : getColor(getRemarks(el.Diff * 100)), backgroundColor: el.Diff * 100 === max ? '#339249' : el.Diff * 100 === min ? '#DA2424' : "#fff" }}>{Number.isFinite(el.PutCOI) ? (el.PutCOI * 100)?.toFixed(2) : 0}%</TableCell>

                                                    <TableCell style={{
                                                        ...cellStyle, fontSize: "15px", fontWeight: "bold",
                                                        color: (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === max || (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === min ? '#fff' : getColor(getRemarks(el.Diff * 100)),
                                                        backgroundColor: (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === max ? '#339249' : (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === min ? "#DA2424" : '#fff'
                                                    }}>{Number.isFinite(el.companies?.[PEIndex]?.oichange) ? ((el.companies?.[PEIndex]?.oichange - el.companies?.[CEIndex]?.oichange) / 100000)?.toFixed(2) : 0}L
                                                    </TableCell>

                                                    <TableCell style={{
                                                        ...cellStyle, fontSize: "15px", fontWeight: "bold", color: (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === max || (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === min ? '#fff' : getColor(getRemarks(el.Diff * 100)),
                                                        backgroundColor: (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === max ? '#339249' : (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === min ? "#DA2424" : '#fff'
                                                    }}>{Number.isFinite(el.Diff) ? (el.Diff * 100)?.toFixed(2) : 0}%</TableCell>

                                                    <TableCell style={{
                                                        ...cellStyle, textAlign: 'center', fontWeight: "bold", color: "#4D8FCC", fontSize: "15px", color: (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === max || (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === min ? '#fff' : getColor(getRemarks(el.Diff * 100)),
                                                        backgroundColor: (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === max ? '#339249' : (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === min ? "#DA2424" : '#fff'
                                                    }}>{Number.isFinite(el.strikePrice) ? el.strikePrice : 0}</TableCell>

                                                    <TableCell style={{
                                                        ...cellStyle, textAlign: 'center', fontWeight: "bold", color: (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === max || (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === min ? '#fff' : getColor(getRemarks(el.Diff * 100)),
                                                        backgroundColor: (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === max ? '#339249' : (el.companies?.[PEIndex]?.oichange?.toFixed(2) - el.companies?.[CEIndex]?.oichange?.toFixed(2)) === min ? "#DA2424" : '#fff'
                                                    }}>{Number.isFinite(el.Diff) ? getRemarks(el.Diff * 100) : 0}</TableCell>
                                                </TableRow>
                                            </>
                                        )
                                    })
                                    : <TableRow>
                                        <TableCell style={{ ...cellStyle, borderBottom: "none" }} colSpan={15}>
                                            <Typography align='center'>{data?.length == 0 ? 'No Data Found' : 'Loading...'}</Typography>
                                        </TableCell>
                                    </TableRow>}
                                {data?.length > 0 &&
                                    <TableRow>
                                        <TableCell style={{ ...cellStyle, borderBottom: "none" }} colSpan={1} ></TableCell>
                                        <TableCell style={{ ...cellStyle, borderLeft: "1px solid gray", borderBottom: "1px solid gray" }} colSpan={1} >{(callCOIChange / 100000)?.toFixed(2)}L</TableCell>
                                        <TableCell style={{ ...cellStyle, borderBottom: "none" }} colSpan={5} ></TableCell>
                                        <TableCell style={{ ...cellStyle, borderLeft: "1px solid gray", borderBottom: "1px solid gray" }} colSpan={1} >{(putCOIChange / 100000)?.toFixed(2)}L</TableCell>
                                        <TableCell style={{ ...cellStyle, borderBottom: "none" }} colSpan={1} ></TableCell>
                                        <TableCell style={{ ...cellStyle, borderLeft: "1px solid gray", borderBottom: "1px solid gray" }} colSpan={1}>{callCOI?.toFixed(2)}%</TableCell>
                                        <TableCell style={{ ...cellStyle, borderLeft: "1px solid gray", borderBottom: "1px solid gray" }} colSpan={1}>{putCOI?.toFixed(2)}%</TableCell>
                                        <TableCell style={{ ...cellStyle, borderLeft: "1px solid gray", borderBottom: "1px solid gray" }} colSpan={1}>{(oiChange / 100000)?.toFixed(2)}L</TableCell>
                                        <TableCell style={{ ...cellStyle, borderLeft: "1px solid gray", borderBottom: "1px solid gray" }} colSpan={1}>{imbalance?.toFixed(2)}%</TableCell>
                                        <TableCell style={{ ...cellStyle, borderBottom: "none" }} colSpan={1} ></TableCell>
                                        <TableCell style={{ ...cellStyle, borderLeft: "1px solid gray", borderBottom: "1px solid gray", color: callCOI > putCOI ? "#DA2424" : '#339249', fontWeight: 'bold' }} colSpan={1}>{callCOI > putCOI ? "Down Trend" : "Up Trend"}</TableCell>
                                    </TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Box>
        </>
    )
}

export default OptionsOI