import { Box, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import React, { useEffect, useMemo, useState } from 'react'
import "../../App.css"
import { CurrentCompanyData } from '../apiService/tickerApiService';
import IndeciesStandartView from './IndeciesStandartView';
import IndeciesTraderView from './IndeciesTradersView';
import IndeciesInvestorView from './IndeciesInvestorsView';
import IndicesStrip from '../common/IndicesStrip';
import GraphView from '../HomePage/GraphView';
import IndiceCandleGraphs from './IndiceCandleGraphs';
import IndiceCandleGraphView from './IndiceCandleGraphView';
import withAuth from '../../utils/withAuth';
import IndiceStripNew from '../common/IndiceStripNew';
import OIChangeOptions from '../OptionsOI/OIChangeOptions';
import OiChangeIndices from './OiChangeIndices';

function Indecies(props) {
  const { submenuIndecies, setSubmenuEtfs, tabsList, viewTabs } = props
  const [value, setValue] = useState(submenuIndecies ? submenuIndecies : '');
  const [label, setlabel] = useState(submenuIndecies ? tabsList[submenuIndecies]?.label : tabsList[0].label);
  const [view, setView] = useState("5");

  const [tickerRes, setTickerRes] = useState(0);
  const [closePrice, setClosePrice] = useState(0);
  const [curr_perc, setCurr_perc] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    let a = tabsList.filter((e, i) => e.value === newValue)
    setlabel(a.length ? a[0].label : tabsList[0].label)
  };

  const currentMP = async (token) => {
    const CMPdata = await CurrentCompanyData(token);
    setTickerRes(CMPdata?.data)
    setClosePrice(CMPdata?.data?.closePrice)
    setCurr_perc((CMPdata?.data?.change)?.toFixed(2))
  }

  useMemo(() => {
    if (submenuIndecies) {
      setValue(submenuIndecies)
      const a = tabsList.filter(e => e.value == submenuIndecies ? e.label : "")
      setlabel(a.length > 0 ? a[0].label : tabsList[0].label)
    } else {
      setValue("0");
      setlabel(tabsList[0].label)
    }
  }, [submenuIndecies])

  const handleChangeView = (e, v) => {
    setView(v)
  }
  const filterLabel = (v) => {
    switch (v) {
      case "0": return "nifty50"
      case "1": return "banknifty"
      case "2": return "itnifty"
      case "3": return "finnifty"
      case "4": return "midcapselect"
      case "5": return "niftynext50"
      default: return ""
    }
  }
  const filterToken = (v) => {
    switch (v) {
      case "0": return 256265
      case "1": return 260105
      case "2": return 259849
      case "3": return 257801
      case "4": return 288009
      case "5": return 270857
      default: return 0
    }
  }

  useEffect(() => {
    let a = filterToken(value)
    const intervalId = setInterval(() => {
      if (submenuIndecies) {
        currentMP(a);
      }
    }, 1000);
    return () => {
      setTickerRes(0)
      clearInterval(intervalId)
    }
  }, [value])

  return (
    <Grid className="tt-container">
      <Grid className='tabs-container'>
        <Box className="tt-tablist">
          <Tabs value={value} onChange={handleChange} orientation="vertical" variant="scrollable" aria-label="Vertical tabs example" style={{ borderRight: 1, borderColor: 'divider', width: "max-content" }}>
            {tabsList?.map((e, i) => (
              <Tab value={e.value} label={e.label} key={i} className='tt-tab' />
            ))}
          </Tabs>
        </Box>
      </Grid>
      <Grid className='tbl-container' style={{ width: tabsList[value].value !== "6" ? '' : '87%' }}>
        {tabsList[value].value !== "6" ? <TabContext value={value}>
          <TabPanel value={value} className="tt-tablepanel">
            <Grid className="contributors">
              <Grid className="p-contributors" style={{ width: "100%" }}>
                {view !== 1 &&
                  <Grid className='bees-ticker' style={{ justifyContent: "space-between" }}>
                    {/* <Grid className='flex'>
                    <Grid className="tview-context">{label}</Grid>
                    <Grid className="tviewpricing green">
                      <p style={{ color: `${closePrice <= tickerRes?.lastTradedPrice ? 'rgb(51, 146, 73)' : 'rgb(218, 36, 36)'}`, }}> {tickerRes ? tickerRes?.lastTradedPrice : 0}</p>
                      <p style={{ fontSize: '11px', color: '#857d7d', marginLeft: '5px' }}>{tickerRes ? (tickerRes?.lastTradedPrice - closePrice)?.toFixed(2) : 0}</p>
                      <p style={{ fontSize: '11px', color: '#857d7d', marginLeft: '5px' }}>{`(${curr_perc ? curr_perc : 0}%)`}</p>
                    </Grid>
                  </Grid> */}
                    <Grid className='company-cmp'>

                      <Grid className="details">
                        <Grid className="details-context px-2 py-1" style={{ color: '#fff', marginLeft: '0px' }}>
                          {/* {dataFromChild?.companySymbol} */}
                          {label}
                        </Grid>
                      </Grid>
                      <Grid >
                        <Grid className='pricing m-2'>
                          <Grid
                            style={{
                              color: `${closePrice <= tickerRes?.lastTradedPrice ? 'rgb(51, 146, 73)' : 'rgb(218, 36, 36)'}`,
                              display: "flex",
                              alignItems: "center",
                              backgroundColor: '#fff',
                              padding: '0px 13px',
                              borderRadius: '4px',
                              //  height:'15px'
                            }}>
                            <p className='mr-1'>{tickerRes?.lastTradedPrice ? tickerRes?.lastTradedPrice : 0}</p>
                            <p style={{ fontSize: '11px', color: '#857d7d' }}>{tickerRes?.lastTradedPrice ? (tickerRes?.lastTradedPrice - closePrice)?.toFixed(2) : 0}</p>
                            <p style={{ fontSize: '11px', color: '#857d7d' }}>{`(${curr_perc ? curr_perc : 0}%)`}</p>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className='flex'>
                      {/* {label !== "NIFTY 50" && <IndicesStrip token="256265" IndiceLabel="NIFTY50" />}
                      {label !== "BANK NIFTY" && <IndicesStrip token="260105" IndiceLabel="BANK NIFTY" />}
                      {label !== "NIFTY IT" && <IndicesStrip token="259849" IndiceLabel="IT NIFTY" />} */}
                      <IndiceStripNew
                        showNifty50={label !== "NIFTY 50"}
                        showBankNifty={label !== "BANK NIFTY"}
                        showITNifty={label !== "IT NIFTY"}
                        showBankNiftyF={label == "BANK NIFTY"}
                        showNIFTYf={label == "NIFTY 50" || label == "IT NIFTY" || label == "FIN NIFTY"}
                      />
                    </Grid>
                  </Grid>}
                {view === '1' && <IndeciesStandartView value={view} label={tabsList[value].label} tickerResponse={tickerRes} IndiceValue={filterLabel(value)} token={filterToken(value)} />}
                {view === '2' && <IndeciesTraderView value={view} label={tabsList[value].label} tickerResponse={tickerRes} IndiceValue={filterLabel(value)} token={filterToken(value)} />}
                {view === '3' && <IndeciesInvestorView value={view} label={tabsList[value].label} tickerResponse={tickerRes} IndiceValue={filterLabel(value)} token={filterToken(value)} />}
                {view === '4' && <GraphView value={value} dataFromChild={{ token: filterToken(value), companySymbol: filterLabel(value)?.toUpperCase() }} showTop={false} indices={true} />}
                {/* {view === '5' && <IndiceCandleGraphView value={value} dataFromChild={{ token: filterToken(value), companySymbol: filterLabel(value)?.toUpperCase() }} showTop={false}/>} */}
                {view === '5' && <IndiceCandleGraphView value={value} dataFromChild={{ token: filterToken(value), companySymbol: filterLabel(value)?.toUpperCase() }} showTop={false} cmp={tickerRes?.lastTradedPrice} />}
              </Grid>
            </Grid>
            <Grid className="investers-trading-tabs">
              <TabContext value={view}>
                <TabList className="tabs-grid"
                  onChange={handleChangeView}
                  aria-label="lab API tabs example">
                  {viewTabs.map((e, i) => (
                    <Tab key={i} className="tab-button" label={e.label} value={e.value} />
                  ))}
                </TabList>
              </TabContext>
            </Grid>
          </TabPanel>
        </TabContext>
          :
          <OiChangeIndices />
        }
      </Grid>
    </Grid>
  )
}

export default withAuth(Indecies)