import React, { useEffect, useRef, useState } from "react";
import founder from '../../assets/images/founder.png';
import QR from "../../assets/images/Qrcode.jpeg"
import trada from '../../assets/images/tradaAbout.png';
import videoCB from '../../assets/videos/ab_1.mp4'
import './aboutus.css';
import SiteFooter from "./SiteFooter";
import Portfolio from "./Portfolio";
import LandingHeader from "./header";
import VideoCarousel from "./VideoCarousel";
import { useMediaQuery } from "@material-ui/core";
import HeaderTicker from "../AppHeader/HeaderTicker";

function Aboutus() {
    const [isHidden, setIsHidden] = useState(false);
    const matches = useMediaQuery('(min-width:900px)');
    const services = {
        "F&O": {
            text: "Futures and Options (F&O) are financial derivatives that allow traders to hedge or speculate on the future price of assets."
        },
        "Equity": {
            text: "Equity trading involves buying and selling shares of publicly traded companies to gain ownership and profit from their growth."
        },
        "ETFs": {
            text: "Exchange-Traded Funds (ETFs) are investment funds traded on stock exchanges, holding assets like stocks, commodities, or bonds."
        },
        "Commodities": {
            text: "Commodity trading involves buying and selling raw materials like gold, oil, and agricultural products."
        },
        "Currencies": {
            text: "Currency trading, or Forex, involves exchanging one currency for another in the global marketplace."
        },
        "Crypto Currencies": {
            text: "Cryptocurrency trading involves buying, selling, and holding digital currencies like Bitcoin and Ethereum."
        }
    };

    const [activeHeading, setActiveHeading] = useState(0); // State to track which heading is active

    // Array of headings and their corresponding information
    const headings = [
        { title: 'Vision', info: 'An in-depth analysis of stock markets with the trends, industry insights, and financial analysis from our team of dedicated and experienced Market Analysts and Researchers.' },
        { title: 'Mission', info: 'To provide our clients with accurate, timely, and actionable market information, enabling them to make well-informed investment decisions.' },
        { title: 'Philosophy', info: 'Our philosophy is grounded in a commitment to excellence, integrity, and client satisfaction. We believe in transparency and the highest standards of professional conduct.' },
    ];

    useEffect(() => {
        const intervalId = setInterval(() => {
            // Update the active heading every 10 seconds
            setActiveHeading((prevHeading) => (prevHeading + 1) % headings.length);
        }, 5000);

        return () => clearInterval(intervalId); // Cleanup the interval on component unmount
    }, [headings.length]);

    return (
        <div className="main-width" >
            {/* <HeaderTicker/> */}
            <LandingHeader />
            <div className="main-width"style={{ position: 'absolute', top: '68px', fontFamily: 'Futura PT Book' }}>
                <div className="about-page">
                    <div className="founder">
                        <div className="founder-img">
                            <img src={founder} alt="Founder" />
                            {matches&& <div className="about-founder">
                                <h1>MEET OUR FOUNDER</h1>
                                <h2>Dr. VENKAT GADDAM (FOUNDER)</h2>
                                <p>
                                    Venkat's journey reflects resilience and innovation. With an M.Tech, he started his tech career at Mindtree and worked globally as a Data Scientist and Blockchain Architect. Passionate about the stock market, he invested and studied it for 14 years, developing effective trading strategies.
                                </p>
                                <p>
                                    Upon returning to Hyderabad, he founded a company offering advanced trading tools. His startup gained recognition for its unique approach, leading to the creation of TRADABULLS, which provides comprehensive analysis and advisory for trading and investment.
                                </p>
                            </div>}
                        </div>
                    {!matches &&
                     <div className="founder-img">
                        <div className="about-founder">
                                <h1>MEET OUR FOUNDER</h1>
                                <h2>Dr. VENKAT GADDAM (FOUNDER)</h2>
                                <p>
                                    Venkat's journey reflects resilience and innovation. With an M.Tech, he started his tech career at Mindtree and worked globally as a Data Scientist and Blockchain Architect. Passionate about the stock market, he invested and studied it for 14 years, developing effective trading strategies.
                                </p>
                                <p>
                                    Upon returning to Hyderabad, he founded a company offering advanced trading tools. His startup gained recognition for its unique approach, leading to the creation of TRADABULLS, which provides comprehensive analysis and advisory for trading and investment.
                                </p>
                            </div>
                     </div>
                     }
                    </div>
                    <div className="videoCarousel"style={{ marginTop: '20px' }}>
                        <VideoCarousel />
                    </div>
                    {/* <Portfolio /> */}
                    <div className="portfolio-container"><Portfolio /></div>
                    <div className="about-tradabulls">
                        <div className="content">
                            <p>
                                <h1>ABOUT   <span style={{ color: "#D0111C", marginLeft: '5px' }}>Trada</span><span style={{ color: '#134873' }}>Bulls</span></h1>
                                We offer a wide range of resources to meet diverse investment needs. Whether you're new to investing or a seasoned trader, our platform provides tools and information to help you make informed decisions and maximize returns. Using a holistic approach, we combine quantitative and qualitative factors to optimize investment outcomes, manage risks, and adapt to market changes. We simplify the investment process and reduce stress by providing expert research and resources. Our experienced team is dedicated to helping you achieve your investment goals with confidence and precision.
                            </p>
                            <div className="image-container">
                                <img src={trada} alt="Tradabulls" />
                            </div>
                        </div>
                        <div className="line-container">
                            <div className="line">
                                <span className="dot"></span>
                            </div>
                        </div>
                    </div>
                    <SiteFooter />
                    <div>
                        {!isHidden && (
                            <div className="qr-wrapper">
                                <img src={QR} alt="QR Code" className="qr-code" draggable="false"/>
                                <button className="close-btn" onClick={() => setIsHidden(true)}>&times;</button>
                            </div>
                        )}

                        {isHidden && (
                            <button className="slider-btn" onClick={() => setIsHidden(false)}>
                                Show QR Code
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Aboutus;
