// import React, { useState, useRef, useEffect } from 'react';
// import './Portfolio.css';

// const Portfolio = () => {
//   const services = {
//     "F&O": "Mainly traded stock derivatives on the share market are futures and options. On the specified date and at the agreed upon price, a futures contract to purchase or sell an underlying security must be fulfilled. However, if a buyer makes a profit on a transaction, an options contract gives them the opportunity to continue that way.",

//     "Equity": "A company's equity, also known as shareholder equity, is the amount of money that will be given back to its owners if all its assets are liquidated, and all its debt is settled. Securities are investments that are tied to the market and do not guarantee a certain rate of return. Thus, returns on equity are contingent upon the performance of the underlying asset. ",

//     "ETFs": "A group of investments, including bonds or stocks, is called an exchange-traded fund (ETF). ETFs allow you to invest in several different assets at once, and their costs are frequently less than those of other fund kinds. Additionally, trading ETFs is simpler. Stock index-tracking ETFs may offer lump sum dividend payments or reinvestments for the index's member companies to its investors. ",

//     "Commodities": "An individual can engage in the purchasing, selling, and trading of raw materials or even primary goods on a commodities market. The several types of commodities that can be traded are: soft commodities (agriculture, livestock, and meat) and hard commodities (precious metals, energy) ",

//     "Currencies": "Currency futures, also referred to as FX futures, are futures contracts that promise to exchange one currency for another at a certain future date for a defined price (exchange rate) on the purchase date. Currency options, sometimes referred to as FX options, are like futures in that the buyer is granted the right, but not the duty, to exchange one currency for another at a future date at a price (exchange rate) that is established on the day of purchase.",

//     "Crypto Currencies": "A digital money that, instead of being controlled by a single entity, is confirmed by a decentralized system employing encryption to keep records. The stock market has experienced higher volatility and more investment options because of crypto currencies like Bitcoin and Ethereum. The emergence of blockchain technology presents opportunities outside of the bitcoin market."
//   };

//   const [selectedService, setSelectedService] = useState("F&O");
//   const [pathData, setPathData] = useState('');
//   const serviceRefs = useRef({});
//   const videoRef = useRef(null);

//   useEffect(() => {
//     updatePath();
//     // Recalculate on window resize to maintain responsiveness
//     window.addEventListener('resize', updatePath);
//     return () => window.removeEventListener('resize', updatePath);
//   }, [selectedService]);

//   const updatePath = () => {
//     const serviceElement = serviceRefs.current[selectedService];
//     const videoElement = videoRef.current;

//     if (serviceElement && videoElement) {
//       const serviceRect = serviceElement.getBoundingClientRect();
//       const videoRect = videoElement.getBoundingClientRect();

//       const containerRect = videoElement.parentElement.getBoundingClientRect();

//       // Calculate positions relative to the container
//       const startX = serviceRect.right - containerRect.left;
//       const startY = serviceRect.top + serviceRect.height / 2 - containerRect.top;
//       const endX = videoRect.left - containerRect.left;
//       const endY = videoRect.top + videoRect.height / 2 - containerRect.top;

//       // Define control point for curve
//       const controlPointX = (startX + endX) / 2;
//       const controlPointY = Math.min(startY, endY) - 50;  // Adjusted for smaller height

//       // Generate path data for the curve
//       setPathData(`M${startX},${startY} Q${controlPointX},${controlPointY} ${endX},${endY}`);
//     }
//   };

//   const handleServiceClick = (service) => {
//     setSelectedService(service);
//   };

//   return (
//     <div style={{backgroundColor:'#333',padding:"20px 0px"}}>
//       <div className='portfolio-text'>
//         <h1>Portfolio</h1>
//         <p>we offer a range of services designed to empower investors with the insights and tools they need to succeed in the stock market.</p>
//       </div>
//       <div className="portfolio">
//         <div className="services">
//           {Object.keys(services).map((service) => (
//             <button
//               key={service}
//               ref={(el) => (serviceRefs.current[service] = el)}
//               className={`service-btn ${selectedService === service ? 'active' : ''}`}
//               onClick={() => handleServiceClick(service)}
//             >
//               {service} →
//             </button>
//           ))}
//         </div>

//         <svg className="line-connection">
//           <defs>
//             <marker id="arrow" markerWidth="10" markerHeight="10" refX="9" refY="5" orient="auto-start-reverse">
//               <path d="M0,0 L10,5 L0,10 z" fill="#f5deb3" />
//             </marker>
//           </defs>
//           <path d={pathData} stroke="#f5deb3" strokeWidth="2" fill="transparent" markerEnd="url(#arrow)" className="path" />
//         </svg>

//         <div className="video" ref={videoRef}>
//           <div className="video-placeholder">
//             <p>{services[selectedService]}</p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Portfolio;

import React, { useState, useRef, useEffect } from 'react';
import './Portfolio.css';
import Card from './BestCard'; // Import your Card component here
import image1 from '../../assets/images/portfolio/image.png';
import image2 from '../../assets/images/portfolio/eq.png';
import image3 from '../../assets/images/portfolio/etf.png';
import image4 from '../../assets/images/portfolio/com.png';
import image5 from '../../assets/images/portfolio/cur.png';
import image6 from '../../assets/images/portfolio/cycur.png';
import { useMediaQuery } from '@material-ui/core';

const Portfolio = () => {
  const matches = useMediaQuery('(min-width:900px)');
  const services = {
    "F&O": {
      text: "Mainly traded stock derivatives on the share market are futures and options. On the specified date and at the agreed upon price, a futures contract to purchase or sell an underlying security must be fulfilled. However, if a buyer makes a profit on a transaction, an options contract gives them the opportunity to continue that way.",
      image: image1
    },
    "Equity": {
      text: "A company's equity, also known as shareholder equity, is the amount of money that will be given back to its owners if all its assets are liquidated, and all its debt is settled. Securities are investments that are tied to the market and do not guarantee a certain rate of return. Thus, returns on equity are contingent upon the performance of the underlying asset.",
      image: image2
    },
    "ETFs": {
      text: "A group of investments, including bonds or stocks, is called an exchange-traded fund (ETF). ETFs allow you to invest in several different assets at once, and their costs are frequently less than those of other fund kinds. Additionally, trading ETFs is simpler. Stock index-tracking ETFs may offer lump sum dividend payments or reinvestments for the index's member companies to its investors.",
      image: image3
    },
    "Commodities": {
      text: "An individual can engage in the purchasing, selling, and trading of raw materials or even primary goods on a commodities market. The several types of commodities that can be traded are: soft commodities (agriculture, livestock, and meat) and hard commodities (precious metals, energy)",
      image: image4
    },
    "Currencies": {
      text: "Currency futures, also referred to as FX futures, are futures contracts that promise to exchange one currency for another at a certain future date for a defined price (exchange rate) on the purchase date. Currency options, sometimes referred to as FX options, are like futures in that the buyer is granted the right, but not the duty, to exchange one currency for another at a future date at a price (exchange rate) that is established on the day of purchase.",
      image: image5
    },
    "Crypto Currencies": {
      text: "A digital money that, instead of being controlled by a single entity, is confirmed by a decentralized system employing encryption to keep records. The stock market has experienced higher volatility and more investment options because of crypto currencies like Bitcoin and Ethereum. The emergence of blockchain technology presents opportunities outside of the bitcoin market.",
      image: image6
    }
  };

  const [selectedService, setSelectedService] = useState("F&O");
  const [pathData, setPathData] = useState('');
  const serviceRefs = useRef({});
  const cardRef = useRef(null);

  useEffect(() => {
    updatePath();
    window.addEventListener('resize', updatePath);
    return () => window.removeEventListener('resize', updatePath);
  }, [selectedService]);

  const updatePath = () => {
    const serviceElement = serviceRefs.current[selectedService];
    const cardElement = cardRef.current;

    if (serviceElement && cardElement) {
      const serviceRect = serviceElement.getBoundingClientRect();
      const cardRect = cardElement.getBoundingClientRect();

      const containerRect = cardElement.parentElement.getBoundingClientRect();

      const startX = serviceRect.right - containerRect.left;
      const startY = serviceRect.top + serviceRect.height / 2 - containerRect.top;
      const endX = cardRect.left - containerRect.left;
      const endY = cardRect.top + cardRect.height / 2 - containerRect.top;

      const controlPointX = (startX + endX) / 2;
      const controlPointY = Math.min(startY, endY) - 50;

      setPathData(`M${startX},${startY} Q${controlPointX},${controlPointY} ${endX},${endY}`);
    }
  };

  const handleServiceClick = (service) => {
    setSelectedService(service);
  };

  return (
    <div style={{ backgroundColor: '#333', padding: "20px 0px" }}>
      <div className='portfolio-text'>
        <h1 style={{ display: 'flex', justifyContent: 'center' }}>Portfolio</h1>
        <p style={{ color: '#fff' }}>We offer a range of services designed to empower investors with the insights and tools they need to succeed in the stock market.</p>
      </div>
      <div className="portfolio">
        <div className="services">
          {Object.keys(services).map((service) => (
            <button
              key={service}
              ref={(el) => (serviceRefs.current[service] = el)}
              className={`service-btn ${selectedService === service ? 'active' : ''}`}
              onClick={() => handleServiceClick(service)}
            >
              {service} →
            </button>
          ))}
        </div>

        {matches && <svg className="line-connection">
          <defs>
            <marker id="arrow" markerWidth="10" markerHeight="10" refX="9" refY="5" orient="auto-start-reverse">
              <path d="M0,0 L10,5 L0,10 z" fill="#f5deb3" />
            </marker>
          </defs>
          <path d={pathData} stroke="#f5deb3" strokeWidth="2" fill="transparent" markerEnd="url(#arrow)" className="path" />
        </svg>}

        {/* Replacing the .video div with Card component */}
        {matches && <div ref={cardRef} style={{ position: "relative", right: '13%' }}>
          <Card
            imageUrl={services[selectedService].image}
            title={selectedService}
            description={services[selectedService].text}
          />
        </div>}
      </div>
    </div>
  );
};

export default Portfolio;