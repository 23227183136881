import { Button, Grid, Typography } from "@material-ui/core";
import "./landing.css";
import Testominals from "./Testimonial";
import { Services } from "./Services.js";
import SiteFooter from "./SiteFooter.js";
import HeroComponent from "./heroComponent.js";
import LandingHeader from "./header.js";
import { EmailOutlined, PhoneOutlined } from "@material-ui/icons";
import { FAQSections } from "./FAQs.js";
import HeaderTicker from "../AppHeader/HeaderTicker.js";
import About from "./about.js";
import serviceImg from '../../assets/images/servicesBg.jpg'
import VideoBar from "./VideoBar.js";
import YoutubeVideos from "./YoutubeVideos.js";
import ContactUs from "./ContactUs.js";
import Reviews from "./Reviews.js";
import ServicePage from "./ServicePage.js";
import Aboutus from "./Aboutus.js";
import Portfolio from "./Portfolio.js";

const LandingComponent = (props) => {
  return (
    <div>
      <div
        className="major-container"
        style={{
          // background: `transparent linear-gradient(183deg, #8F278F 20%, #10346F 100%) 0% 0% no-repeat padding-box`,
          // height: "100vh",
          overflowY: "auto",
          overflowX: 'clip'
        }}
      >
        {/* <section id="top-bar" className="flex items-center">
          <div class="container flex justify-center">
            <div class="contact-info flex items-center">
              <EmailOutlined style={{ fontSize: "1em", margin: "2px" }} />
              <a href="mailto:contact@example.com">info@tradabulls.com</a>
              <PhoneOutlined style={{ fontSize: "1em", margin: "2px" }} />{" "}
              <span>+91 040-44724242</span>
            </div>
          </div>
        </section> */}
        <HeaderTicker />
        <LandingHeader />
        <Grid style={{ position: 'absolute', top: '68px', width: '100%' }}>

          <VideoBar />

          {/* <ServicePage /> */}
          
          {/* <Aboutus /> */}

          {/* <Portfolio/> */}
        </Grid>
        {/* <HeroComponent/> */}
        {/* <Grid container id="main" className="py-4 px-12"
          style={{ padding: "40px, 0" }}
        >
          <Grid id="about" item className="flex ml-8 mt-8">
            <Grid className="py-4 px-12">
              <h2 className="text-black" style={{}}>
                ABOUT
              </h2>
              <h3>Investor Charter in respect of Research Analyst (RA).</h3>
              <>
                <section className="section-content">
                  We also offer a variety of tools and resources to help you
                  make the best decisions when it comes to investing. Our team
                  of experts work hard to provide accurate and timely research
                  so you can stay ahead of the market. We understand that
                  investing in the stock market can be a daunting task, so we
                  strive to make it as simple and stress-free as possible.
                  <br />
                </section>
                <section className="section-content">
                  At Tradabulls.com, we recognize that every investor has unique
                  needs and goals. That’s why we provide an array of resources
                  to meet those needs. Whether you are a beginner or an
                  experienced investor, you can find the information and tools
                  you need to make wise decisions and maximize your returns.
                </section>
                <section className="section-content">
                  Our team of experienced professionals is dedicated to helping
                  you make the most of your investments. We’re here to answer
                  any questions you may have and provide the guidance you need
                  to become a successful investor.
                </section>
                <section className="section-content">
                  At Tradabulls.com, we are committed to providing our users
                  with the best possible stock market research and resources.
                  With our up-to-date information, detailed analysis, and market
                  data, you can be confident that you are making the smartest
                  decisions for your investments.
                </section>
              </>
            </Grid>
          </Grid>
        </Grid> */}

        {/* <Grid className="services" id="services">
          <div className="first-half">
            <Typography style={{color :"#154A72",fontFamily:"Futura PT Heavy",fontSize: "20px",}}>
              Our Services
            </Typography>
            <Typography style={{ fontFamily:"Futura PT Book", fontSize: "14px",color:"#000000" }}>
              we offer a range of services designed to empower investors with
              the
              <br /> insights and tools they need to succeed in the stock
              market.
            </Typography>
            <div className="card-service">
              <Services/>
            </div>
          </div>
          <div className="second-half" style={{ width: "50%" }}>
            <img src={serviceImg}/>
          </div>
        </Grid> */}

        {/* <Grid id="testimonials">
          <Testominals/>
        </Grid> */}

        {/* <div id="#FAQ"/> */}
        {/* <Grid id="FAQSection">
          <FAQSections/>
        </Grid> */}
        {/* <Grid id="#contact">
        </Grid> */}
      </div>
    </div>
  )
}

export default LandingComponent;