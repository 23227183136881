import React, { useState } from "react";
import LandingHeader from "./header";
import { Box, Divider, Grid, Typography, Card, CardContent } from "@material-ui/core";
import SiteFooter from "./SiteFooter";
import QR from "../../assets/images/Qrcode.jpeg"

function ExploreUs() {
    const [activeStep, setActiveStep] = useState(1);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isHidden, setIsHidden] = useState(false);
    const firstPart = "At Tradabulls, we have a business model that emphasizes a horizontal flow of data rather than a traditional hierarchical structure, This approach ensures that every employee has a voice and plays a significant role in the decision-making process, We believe that great ideas and innovation can come from anyone within our organization, regardless of their position or seniority, and we actively encourage a culture where these contributions are valued and appreciated. ";

    const remainingPart = (
        <ul className="eu-remaing">
            <li><span>Customer Priority:</span> We consider our customers as central to our future success and the very reason for our existence. Our commitment to a "Customer First" approach means that we prioritize understanding and fulfilling their needs, providing exceptional service, and building lasting relationships based on trust and satisfaction. </li>
            <li><span>Stakeholder Respect:</span> We approach our relationships with stakeholders — including shareholders, partners, and the community — with respect and integrity. By acting responsibly and inclusively, we strive to create value and achieve our shared vision while maintaining transparency and accountability </li>
            <li><span>Employee and Workspace Safety:</span> The safety, health, and integrity of our employees are fundamental values that guide our actions. We are committed to providing a safe and supportive workspace where employees can thrive professionally and personally. We prioritize ongoing improvements in safety standards and practices, ensuring that our workforce feels secure and empowered to contribute their best efforts. </li>
            <li>At Tradabulls, these values are not just principles on paper; they are the foundation of our corporate culture. They guide our decisions, actions, and interactions, shaping a workplace where innovation flourishes, stakeholders are respected, and employees are valued as the key to our success. </li>
            {/* Add more points as needed */}
        </ul>
    );

    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };

    const handleStepClick = (step) => {
        setActiveStep(step);
    };

    const isActive = (step) => activeStep >= step;

    const renderContent = () => {
        switch (activeStep) {
            case 1:
                return (
                    <div className="eu-text" >
                        <h1 >Why choose Tradabulls!</h1>
                        <p style={{fontFamily:'Futura PT Book',fontSize:'16px',fontWeight:'500',textShadow: 'rgba(157, 157, 157, 0.8) 2px 2px 4px'}}>
                            We offer a wide range of resources to meet diverse investment needs. Whether you're new to investing or a seasoned trader, our platform provides tools and information to help you make informed decisions and maximize returns. Using a holistic approach, we combine quantitative and qualitative factors to optimize investment outcomes, manage risks, and adapt to market changes. We simplify the investment process and reduce stress by providing expert research and resources. Our experienced team is dedicated to helping you achieve your investment goals with confidence and precision.
                        </p>
                    </div>
                );
            case 2:
                return (
                    <div className="eu-text" >
                        <h1 >Fundamental & Technical Data Analysis</h1>
                        <p>We provide a wide range of resources, targeted towards stock market Investors and Traders. You will find the information and tools to make smart decisions and maximize your returns. Our website presents real-time data supported by historical data to both entry-level and professional investors.</p>
                    </div>
                );
            case 3:
                return (
                    <div className="eu-text" >
                        <h1>Transforming Stock Market Exprience</h1>
                        <p>By applying algorithms and statistical models to historical price and volume data, traders can identify potential signals with greater accuracy. Meritocratic analysis as per trading and investment requirements, leveraging tools like Fibonacci retracements, standard pivots, and the Central Pivot Range (CPR) to enhance trading strategies. Transforming stock market experiences with technical data analysis involves using sophisticated tools to make sense of market trends and patterns. Enhancing decision-making and improving overall investment strategies, making the stock market more accessible and manageable for investors.</p>
                    </div>
                );
            default:
                return "Click on a step to see more details.";
        }
    };

    return (
        <div>
            <LandingHeader />
            <div className="main-width" style={{ position: 'absolute', top: '100px', fontFamily: 'Futura PT Book' }}>
                <Grid alignItems="center" style={{ backgroundColor: '#1E496F', padding: '10px 20px', borderRadius: '25px', display: 'flex', margin: "10px 20px" }}>
                    <Typography
                        variant="body1"
                        className={activeStep === 1 ? 'eu-active' : 'eu-non-active'}
                        // style={{ color: activeStep === 1 ? '#D0111C' : '#fff', cursor: 'pointer', textDecoration: activeStep === 1 ? 'none' : 'underline ' }}
                        onClick={() => handleStepClick(1)}
                    >
                        Why choose Tradabulls
                    </Typography>

                    <Box mx={1} style={{ flex: 1 }}>
                        <Divider
                            orientation="horizontal"
                            flexItem
                            style={{
                                backgroundColor: isActive(2) ? '#c50610' : 'rgba(255, 255, 255, 0.3)',
                                height: '5px',
                                margin: 'auto',
                                width: '80%',
                                borderRadius: '12px'
                            }}
                        />
                    </Box>
                    <Typography
                        variant="body1"
                        className={activeStep === 2 ? 'eu-active' : 'eu-non-active'}
                        // style={{ color: activeStep === 2 ? '#D0111C' : '#fff', cursor: 'pointer', textDecoration: activeStep === 2 ? 'none' : 'underline ' }}
                        onClick={() => handleStepClick(2)}
                    >
                        Need of Fundamental and Technical Analysis
                    </Typography>

                    <Box mx={1} style={{ flex: 1 }}>
                        <Divider
                            orientation="horizontal"
                            flexItem
                            style={{
                                backgroundColor: isActive(3) ? '#c50610' : 'rgba(255, 255, 255, 0.3)',
                                height: '5px',
                                margin: 'auto',
                                width: '80%',
                                borderRadius: '12px'
                            }}
                        />
                    </Box>

                    <Typography
                        variant="body1"
                        className={activeStep === 3 ? 'eu-active' : 'eu-non-active'}
                        // style={{ color: activeStep === 3 ? '#D0111C' : '#fff', cursor: 'pointer', textDecoration: activeStep === 3 ? 'none' : 'underline ' }}
                        onClick={() => handleStepClick(3)}
                    >
                        Transforming Stock Market Experience
                    </Typography>
                </Grid>

                {/* Card to display the content */}
                <div style={{ margin: "20px", background: '#ededed' }}>
                    <Card style={{ background: '#ededed' }}>
                        <CardContent>
                            <Typography variant="h6">{renderContent()}</Typography>
                        </CardContent>
                    </Card>
                </div>
                <div className="eu-behaviour-main" >
                    <div className='eu-behaviour'>
                        <h1 style={{ display: 'flex', justifyContent: 'center', fontSize: '20px', color: '#fff', padding: '5px', fontFamily: 'Futura PT Heavy' }}>Our values & behaviors </h1>
                        <p>
                            {firstPart}
                            {isExpanded && remainingPart}
                        </p>
                        {isExpanded ? (
                            <div className="rl-main">
                                <button className={isExpanded ? 'rl' : 'rm'} onClick={toggleReadMore}>Read Less</button>
                            </div>
                        ) : (
                            <div className="rm-main">
                                <button className={isExpanded ? 'rl' : 'rm'} onClick={toggleReadMore}>Read More</button>
                            </div>
                        )}

                    </div>
                </div>
                <SiteFooter />
                <div>
                        {!isHidden && (
                            <div className="qr-wrapper">
                                <img src={QR} alt="QR Code" className="qr-code" draggable="false"/>
                                <button className="close-btn" onClick={() => setIsHidden(true)}>&times;</button>
                            </div>
                        )}

                        {isHidden && (
                            <button className="slider-btn" onClick={() => setIsHidden(false)}>
                                Show QR Code
                            </button>
                        )}
                    </div>
            </div>
        </div>
    );
}

export default ExploreUs;
