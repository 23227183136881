import React, { useEffect, useState } from "react";
import { mutualFundsData } from "../apiService/tradaApiService";
import { DataGrid } from "@mui/x-data-grid";
import { TextField } from "@mui/material"; // Add this for the search field
import './mutualfund.css'
import { Star, StarBorder } from "@material-ui/icons";

function MutualFunds() {
    const [mutualFundData, setMutualFundData] = useState([]);
    const [filteredData, setFilteredData] = useState([]); // State for filtered data
    const [searchTerm, setSearchTerm] = useState(''); // State for search input

    const fetchSelectedItem = async () => {
        const response = await mutualFundsData();
        const data = response?.data?.data || [];
        setMutualFundData(data);
        setFilteredData(data); // Initially set both to the same data
    };

    useEffect(() => {
        fetchSelectedItem();
    }, []);

    // Filter the data based on the search term with priority on first letter matches
    useEffect(() => {
        if (searchTerm) {
            const lowerCaseSearchTerm = searchTerm.toLowerCase();

            // High priority: Items where the fund name starts with the search term
            const startsWithMatches = mutualFundData.filter((fund) =>
                fund.fundName.toLowerCase().startsWith(lowerCaseSearchTerm)
            );

            // Low priority: Items where the fund name contains the search term (but doesn't start with it)
            const containsMatches = mutualFundData.filter((fund) =>
                !fund.fundName.toLowerCase().startsWith(lowerCaseSearchTerm) &&
                fund.fundName.toLowerCase().includes(lowerCaseSearchTerm)
            );

            // Combine results with startsWithMatches first, followed by containsMatches
            setFilteredData([...startsWithMatches, ...containsMatches]);
        } else {
            setFilteredData(mutualFundData); // If search is empty, show all data
        }
    }, [searchTerm, mutualFundData]);

    const headerCell  = (num,sipBool) => (
        <div className="flex flex-col items-center">
            <span className="font-black font-futura">{num} Year</span>
            <span className="font-black font-futura -mt-2">{sipBool ?"Sip Returns(%)" : "Returns(%)"}</span>
        </div>
    )

    const columns = [
        {
            field: 'fundName', headerName: 'Fund Name', width: 250, headerClassName: 'header-with-search', renderHeader: (params) => {
                return (
                    <div className="flex items-center">
                        <span className="font-black font-futura" >Fund Name</span>
                        <TextField
                            className="search-input rounded-lg ml-4"
                            placeholder="Search..."
                            variant="outlined"
                            size="small"
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                            InputProps={{
                                style: { color: 'white', padding: "0px", border: "2px solid white" },
                            }}
                            InputLabelProps={{
                                style: { color: 'white' },
                            }}
                            sx={{
                                '& .MuiInputBase-input::placeholder': {
                                    color: '#fff', // Placeholder color
                                },
                            }}
                        />
                        {params.value}
                    </div>
                )
            }
        },
        // { field: 'rating', headerName: 'Rating', width: 80, cellClassName: 'centered-cell', resizable: false },
        {
            field: 'rating',
            headerName: 'Rating',
            width: 140,
            resizable: false,
            cellClassName: 'centered-cell',
            headerAlign: 'center',
            renderCell: (params) => {
                const rating = params.value;
                const stars = [];
                // Push filled stars
                for (let i = 0; i < Math.floor(rating); i++) {
                    stars.push(<Star key={i} style={{ color: '#f5c518' }} />); // Filled star (custom color)
                }
                // Push empty stars (for remaining stars)
                for (let i = Math.floor(rating); i < 5; i++) {
                    stars.push(<StarBorder key={i} style={{ color: '#f5c518' }} />); // Empty star
                }
                return <div className="flex">{stars}</div>;
            },
        },
        { field: 'category', headerName: 'Category', width: 125, cellClassName: 'centered-cell', resizable: false, sortable: false,headerAlign: 'center' },
        { field: 'latestNav', headerName: 'Latest Nav', width: 120, cellClassName: 'centered-cell',resizable: false,headerAlign: 'center',  },
        { field: 'oneYearReturnsPercentage', headerName: '1 Year Returns (%)', width: 90, cellClassName: 'centered-cell', resizable: false,renderHeader: () =>  headerCell("1",false), },
        { field: 'oneYearSipReturnsPercentage', headerName: '1 Year Sip Returns (%)', width: 115, cellClassName: 'centered-cell',resizable: false,renderHeader: () => headerCell("1",true)},
        { field: 'threeYearReturnsPercentage', headerName: '3 Year Returns (%)', width: 90, cellClassName: 'centered-cell',resizable: false,renderHeader: () =>  headerCell("3",false)  },
        { field: 'threeYearSipReturnsPercentage', headerName: '3 Year Sip  Returns (%)', width: 115, cellClassName: 'centered-cell',resizable: false,renderHeader: () => headerCell("3",true)  },
        { field: 'fiveYearReturnsPercentage', headerName: '5 Year Returns (%)', width: 90, cellClassName: 'centered-cell',resizable: false,renderHeader: () =>  headerCell("5",false)  },
        { field: 'fiveYearSipReturnsPercentage', headerName: '5 Year Sip  Returns (%)', width: 115, cellClassName: 'centered-cell',resizable: false,renderHeader: () => headerCell("5",true)  },
        { field: 'tenYearReturnsPercentage', headerName: '10 Year Returns (%)', width: 90 , cellClassName: 'centered-cell',resizable: false,renderHeader: () =>  headerCell("10",false) },
        { field: 'tenYearSipReturnsPercentage', headerName: '10 Year Sip  Returns (%)', width: 115, cellClassName: 'centered-cell',resizable: false,renderHeader: () => headerCell("10",true) },
        { field: 'expenseRatioPercentage', headerName: 'Expense Ratio (%)', width: 120, cellClassName: 'centered-cell',resizable: false  },
        { field: 'riskometer', headerName: 'Riskometer', width: 120, cellClassName: 'centered-cell',resizable: false,sortable: false,headerAlign: 'center'  },
        { field: 'returnGrade', headerName: 'Return Grade', width: 120 , cellClassName: 'centered-cell',resizable: false,sortable: false,headerAlign: 'center' },
    ];

    return (
        <div className="mf-container">
            <div className="table-container" >
                <DataGrid
                    rows={filteredData}
                    columns={columns}
                    getRowId={(row) => row.fundName} // Use fundName as the unique identifier
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    disableColumnFilter
                    pagination={false}
                    hideFooter
                    disableColumnReorder
                    disableColumnMenu // Disable the three dots (column menu)
                    rowHeight={35} 
                    columnHeaderHeight={45}
                />
            </div>
        </div>
    );
}

export default MutualFunds;
