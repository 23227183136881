import { Box, Button, Drawer, Grid, Menu, MenuItem, OutlinedInput, Tab, Tabs, Typography, makeStyles, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from 'react';
import { AppLogo } from "../icons/AppIcon";
import { AccountCircleOutlined, KeyboardArrowDown, SearchOutlined } from "@material-ui/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom/cjs/react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import Home from "../HomePage/HomePage";
import { logOutApi } from "../apiService/apiService";
import { fetchImbalance, NiftyPlusPlus, oichangeGetWeeklyDatesApi, oiChangeWeeklyApi } from "../apiService/tickerApiService";
import { ArrowDownward, ArrowUpward, Height } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  globalSearchinput: {
    height: "35px",
    width: "100%",
    backgroundColor: "#E5F1FB",
    borderRadius: "20px",
    border: "none"
  },
  oneTab: {
    minWidth: "fit-content",
    color: "#000000",
    fontWeight: "bold",
    height: "40px",
    fontFamily: "Futura PT Book",
    fontSize: '12px !important',
    padding: '6px 9px !important'
  },
  tabs: {
    margin: "10px 0px 10px 0px",
  },
  subMenu: {
    color: "#000000",
    fontFamily: "Futura PT Book",
    display: "flex",
    justifyItems: "center",
  },
  subMenuItem: {
    fontFamily: 'Futura PT Book',
    fontWeight: 'bold',
    fontSize: "13px",
  },
  menuHead: {
    fontFamily: 'Futura PT Book',
    /* color: #4c4c4c; */
    fontSize: '12px !important',
    fontWeight: "bold",
    paddingRight: "0px",
  }
}));

const menuList = [
  // {label : "Profile",path : "/profile"},
  { label: "Log out", path: "/" },
]

const AppHeader = (props) => {
  const matches = useMediaQuery('(min-width:900px)');
  const [showSidebar, setShowSidebar] = useState(false);
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [index, setIndex] = useState(null);
  const [showSubmenuList, setShowSubmenuList] = useState([]);
  const [anchorEquityEl, setAnchorEquityEl,] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [imbalanceData, setImbalanceData] = React.useState(null);
  const [error, setError] = React.useState(null);

  const [callCOIChange, setCallCOIChange] = useState(0);
  const [putCOIChange, setPutCOIChange] = useState(0);
  const [callCOI, setCallCOI] = useState(0);
  const [putCOI, setPutCOI] = useState(0);
  const [sumCallsOi, setSumCallsOi] = useState(0)
  const [sumPutsOi, setSumPutsOi] = useState(0)

  const [nifty50DataPerc, setNifty50DataPerc] = useState('')
  const [bankNIftyDataPerc, setBankNIftyDataPerc] = useState('')
  const [itDataPerc, setItDataPerc] = useState('')

  const open = Boolean(anchorEl);
  const { NavabarList, page, setPage, submenu, setSubmenu, submenuEtfs, setSubmenuEtfs, setSubmenuIndecies, setSubmenuHm } = props;
  const classes = useStyles();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const NsePlusPlusData = async () => {
    const nifty50_data = await NiftyPlusPlus("nifty50", 256265)
    const bankNifty_data = await NiftyPlusPlus("banknifty", 260105)
    const itNifty_data = await NiftyPlusPlus("itnifty", 259849)

    const traderViewPercentages_nifty = ((nifty50_data?.data?.daily[0]?.percentage + nifty50_data?.data?.weekly[0]?.percentage + nifty50_data?.data?.monthly[0]?.percentage) / 3)
    const traderViewPercentages_Banknifty = ((bankNifty_data?.data?.daily[0]?.percentage + bankNifty_data?.data?.weekly[0]?.percentage + bankNifty_data?.data?.monthly[0]?.percentage) / 3)
    const traderViewPercentages_itNifty = ((itNifty_data?.data?.daily[0]?.percentage + itNifty_data?.data?.weekly[0]?.percentage + itNifty_data?.data?.monthly[0]?.percentage) / 3)
    
    setNifty50DataPerc(traderViewPercentages_nifty)
    setBankNIftyDataPerc(traderViewPercentages_Banknifty)
    setItDataPerc(traderViewPercentages_itNifty)

  }
  useEffect(() => {
    const intervalId = setInterval(() => {
      NsePlusPlusData();
    }, 60000);
    return () => {
      clearInterval(intervalId)
    }
  }, []);

  useMemo(() => {
    NsePlusPlusData();
  }, [])

  // const fetchData = async () => {
  //   try {
  //     const _response = await fetchImbalance(res?.data?.[0]?.expiryDate);
  //     setImbalanceData(response.data); // Update state with the fetched data
  //     let apiData;
  //     const res = await oichangeGetWeeklyDatesApi()
  //     const response = await oiChangeWeeklyApi('NIFTY50', '250', res?.data?.[0]?.expiryDate)
  //     apiData = response.data.sort((a, b) => b.strikePrice - a.strikePrice);
  //     console.log('apiDataapiData...', apiData);
  //     if (apiData) {
  //       const diffOiChange = apiData.map(el => {
  //         let CEIndex = el.companies?.[0]?.companySymbol.endsWith("CE") ? 0 : 1
  //         let PEIndex = el.companies?.[1]?.companySymbol.endsWith("PE") ? 1 : 0
  //         return (el.companies[PEIndex]?.oichange - el.companies[CEIndex]?.oichange)
  //       })
  //       const maxOi = Math.max(...diffOiChange);
  //       const minOi = Math.min(...diffOiChange);

  //       const oiChangeAvg = diffOiChange.reduce((a, b) => a + b, 0)
  //       const callCOIAvg = apiData.reduce((a, b) => a + b.CallCOI, 0)
  //       const putCOIAvg = apiData.reduce((a, b) => a + b.PutCOI, 0)
  //       const diffCOIAvg = apiData.reduce((a, b) => a + b.Diff, 0)

  //       const totalOiChange = apiData.reduce((acc, item) => {
  //         const ceSum = item.companies.filter(company => company.companySymbol.endsWith("CE")).reduce((sum, company) => sum + company.oichange, 0);
  //         const peSum = item.companies.filter(company => company.companySymbol.endsWith("PE")).reduce((sum, company) => sum + company.oichange, 0);


  //         const ceOiSum = item.companies.filter(company => company.companySymbol.endsWith("CE")).reduce((sum, company) => sum + company.oi, 0);
  //         const peOiSum = item.companies.filter(company => company.companySymbol.endsWith("PE")).reduce((sum, company) => sum + company.oi, 0);

  //         return { totalCE: acc.totalCE + ceSum, totalPE: acc.totalPE + peSum, total_CeOi: acc.total_CeOi + ceOiSum, total_Peoi: acc.total_Peoi + peOiSum }
  //       }, { totalCE: 0, totalPE: 0, ceArray: [], peArray: [], total_CeOi: 0, total_Peoi: 0 });


  //       let ceOiChanges = [];
  //       let peOiChanges = [];

  //       apiData.forEach((strike) => {
  //         strike.companies.forEach((company) => {
  //           if (company.companySymbol.includes("CE")) {
  //             ceOiChanges.push(company.oichange);
  //           } else if (company.companySymbol.includes("PE")) {
  //             peOiChanges.push(company.oichange);
  //           }
  //         });
  //       });




  //       setCallCOIChange(totalOiChange.totalCE)
  //       setPutCOIChange(totalOiChange.totalPE)
  //       setSumCallsOi(totalOiChange.total_CeOi)
  //       setSumPutsOi(totalOiChange.total_Peoi)
  //       setCallCOI((callCOIAvg * 100) / apiData?.length)
  //       setPutCOI(putCOIAvg * 100 / apiData?.length)

  //     }

  //   } catch (err) {
  //     setError(err.message); // Handle any errors
  //   }
  // };

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     fetchData();
  //   }, 60000);
  //   return () => {
  //     clearInterval(intervalId)
  //   }
  // }, []);

  // useMemo(() => {
  //   fetchData();
  // }, [])

  // const getOverAllOutLook = () => {
  //   if (putCOIChange < callCOIChange &&
  //     //  sumPutsOi < sumCallsOi && 
  //      putCOI < callCOI) return "DOWN TREND"
  //   else if (putCOIChange > callCOIChange &&
  //     //  sumPutsOi > sumCallsOi && 
  //      putCOI > callCOI) return "UP TREND"
  //   else return "NEUTRAL"
  // }

  const handleSearch = (e) => {
    // console.log("Global Search");
  };
  const history = useHistory();

  const handleEquitiessClose = () => {
    setAnchorEquityEl(null);
  }
  const handleEquitiesClick = (e) => {
    setAnchorEquityEl(e.currentTarget);
  }

  const onTabClick = (tabName, setState) => {
    setPage(tabName.id)
    setShowSidebar(false);
    setShowSubmenuList([])
    setShowSubmenu(false)
  }
  const handleMenuClick = () => {
    setShowSidebar(true);
  }
  const logout = () => {
    localStorage.clear() // Clear token
    history.push(menuList[0].path); // Redirect to login page
  };
  useEffect(() => {
    let timeoutId;

    if (localStorage.token) {
      const sixHoursInMilliseconds = 10 * 60 * 60 * 1000;
      timeoutId = setTimeout(logout, sixHoursInMilliseconds);
    }

    return () => {
      clearTimeout(timeoutId); // Clear timeout on component unmount or token change
    };
  }, [localStorage.token]);

  const onClickSubmenu = (el, e, setState) => {
    setAnchorEl(el.currentTarget)
    // setPage(e.id)
    if (e.subMenu) {
      setIndex(e.id)
    }
    setShowSidebar(false);
    setShowSubmenuList(e.subMenu)
    setShowSubmenu(true)
  }

  const onClickItem = (e, value) => {
    // if(!e.subMenu)setPage(e.id)
    setShowSidebar(false);
    setShowSubmenu(false)
    handleClose()
    switch (index) {
      case 1:
        setSubmenuIndecies(value)
        setPage(index)
      case 2:
        setSubmenuEtfs(value)
        setPage(index)
      case 3:
        setSubmenu(value)
        setPage(index)
      case 4:
        setSubmenuHm(value)
        setPage(index)
      default:
        return 0
    }

  }

  const roundPercentage = (value) => {
    const decimalPart = value % 1;
    return decimalPart > 0.5 ? Math.ceil(value) : Math.floor(value);
  };

  // console.log('nifty50Data..',nifty50Data);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

          <Grid item xs={4} sm={4} md={2} className=" my-2 pl-4" style={{ display: 'flex' }}>
            {!matches && <Button className="menu-icon" onClick={handleMenuClick}>
              <MenuIcon />
            </Button>}
            <Grid onClick={() => window.location.reload()} >
              <AppLogo width="135px" />
            </Grid>
          </Grid>
          {/* {matches && <Grid container item xs={6} sm={6} md={3}
            justifyContent="flex-start" alignItems="center"
            width={"100%"}
          >
            <OutlinedInput
              className={classes.globalSearchinput}
              startAdornment={<SearchOutlined className="w-5 mr-2" />}
              placeholder="Search for stocks & more"
            />
          </Grid>} */}

          {matches && <Grid item container xs={10} sm={10} md={9} justifyContent="flex-start" alignItems="flex-start">
            <Tabs value={page} className={classes.tabs}>
              {NavabarList?.map((e, i) => {
                if (e.subMenu) {
                  return <div key={e.id} className="flex">
                    <Box onClick={(el) => onClickSubmenu(el, e)} className={classes.subMenu}>
                      {/* <Tab key={e.id} label={`${e.label}`} className={classes.oneTab} style={{ paddingRight: "0px" }} /> */}
                      <Button id="basic-button" aria-controls={open ? 'basic-menu' : undefined}
                        className={classes.menuHead} style={{ color: `${page === e.id ? "#000000" : "#4c4c4c"}` }}
                        aria-haspopup="true" aria-expanded={open ? 'true' : undefined}
                      >
                        {e.label}
                      </Button>
                      <Box style={{ cursor: 'pointer', display: "flex", alignItems: "center" }}>
                        <KeyboardArrowDown style={{ color: `${page === e.id ? "#000000" : "#4c4c4c"}` }} />
                      </Box>
                    </Box>
                    <Menu id="basic-menu" className="mt-12 ml-2 sub-menu"
                      anchorEl={anchorEl} open={open} onClose={handleClose}>
                      {showSubmenuList?.map((subItem) => (
                        <MenuItem key={subItem.value} className={`${classes.subMenuItem} submenu-tabs`} onClick={() => onClickItem(e, subItem.value)}>{subItem.label}</MenuItem>
                      ))}
                    </Menu>
                  </div>
                } else {
                  return (
                    <Tab key={e.id} label={`${e.label}`}
                      className={classes.oneTab}
                      onClick={() => onTabClick(e, setPage)} />
                  )
                }
              })}
            </Tabs>
          </Grid>}

          <Grid
            style={{ display: "flex", whiteSpace: 'nowrap', alignItems: 'center',}}
          >
            {/* <span style={{ color: "#333333", fontWeight: 'bold', marginRight: '2px' }}>OI.IMB : </span> */}
            {/* <span style={{whiteSpace: 'nowrap', color: "#000", fontWeight: 'bold', display: 'flex', alignItems: 'center', background: roundPercentage(imbalanceData?.Average) <= -30 ? 'red' : roundPercentage(imbalanceData?.Average) >= 30 ? "green" : "#DAA520", padding: '1px 5px', borderRadius: '5px',marginLeft:'3px' }}>
              NIFTY 50 {roundPercentage(imbalanceData?.Average) <= -30 ? <ArrowDownward /> : roundPercentage(imbalanceData?.Average) >= 30 ? <ArrowUpward /> : <Height style={{ rotate: "90deg" }} />}
            </span> */}
            {/* <span>{getOverAllOutLook()}</span> */}


            {/* <span style={{
              whiteSpace: 'nowrap', color: "#000", fontWeight: 'bold', display: 'flex', alignItems: 'center',
              background: getOverAllOutLook() === "DOWN TREND" ? 'red' : getOverAllOutLook() === "UP TREND" ? "green" : "#DAA520",
              padding: '1px 5px', borderRadius: '5px', marginLeft: '3px'
            }}>
              Nifty 50 {getOverAllOutLook() === "DOWN TREND" ? <ArrowDownward /> : getOverAllOutLook() === "UP TREND" ? <ArrowUpward /> : <Height style={{ rotate: "90deg" }} />}
            </span> */}
              <span style={{
                whiteSpace: 'nowrap', color: "#000", fontWeight: 'bold', display: 'flex', alignItems: 'center',
                background: nifty50DataPerc >= 61.8 ? 'green' : nifty50DataPerc <= 38.2 ? 'red' : '#DAA520',
                color: nifty50DataPerc >= 61.8 ? '#fff' : "#333333",
                padding: '1px 5px', borderRadius: '5px', marginLeft: '3px'
              }}>
                Nifty 50 {nifty50DataPerc >= 61.8 ? <ArrowUpward /> : nifty50DataPerc <= 38.2 ? <ArrowDownward /> : <Height style={{ rotate: "90deg" }} />}
              </span>

            <span style={{
              whiteSpace: 'nowrap', color: "#000", fontWeight: 'bold', display: 'flex', alignItems: 'center',
              background: bankNIftyDataPerc >= 61.8 ? 'green' : bankNIftyDataPerc <= 38.2 ? 'red' : '#DAA520',
              color: bankNIftyDataPerc >= 61.8 ? '#fff' : "#333333",
              padding: '1px 5px', borderRadius: '5px', marginLeft: '3px'
            }}>
              Bank Nifty {bankNIftyDataPerc >= 61.8 ? <ArrowUpward /> : bankNIftyDataPerc <= 38.2 ? <ArrowDownward /> : <Height style={{ rotate: "90deg" }} />}
            </span>

            <span style={{
              whiteSpace: 'nowrap', color: "#000", fontWeight: 'bold', display: 'flex', alignItems: 'center',
              background: itDataPerc >= 61.8 ? 'green' : itDataPerc <= 38.2 ? 'red' : '#DAA520',
              color: itDataPerc >= 61.8 ? '#fff' : "#333333",
              padding: '1px 5px', borderRadius: '5px', marginLeft: '3px'
            }}>
              IT Nifty {itDataPerc >= 61.8 ? <ArrowUpward /> : itDataPerc <= 38.2 ? <ArrowDownward /> : <Height style={{ rotate: "90deg" }} />}
            </span>

          </Grid>
          <Grid item container xs={2} sm={2} md={1} justifyContent="flex-end" alignItems="center">
            <Button>
              <AccountCircleOutlined
                onClick={handleEquitiesClick}
                className="text-black"
              />
            </Button>
            <Menu
              id="equities"
              keepMounted
              anchorEl={anchorEquityEl}
              open={Boolean(anchorEquityEl)}
              onClose={handleEquitiessClose}
              classes={{ list: "w-32 text-center" }}
              style={{ marginTop: "50px" }}
            >
              {menuList?.map((item, index) => (
                <MenuItem key={index}
                  onClick={async () => {
                    if (item.label === "Log out") {
                      const logOutStatus = await logOutApi()
                      if (logOutStatus?.data?.success) {
                        localStorage.clear()
                        history.push(`${item.path}`);
                      }
                    }
                  }}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        </Grid>
      </Grid>

      <Drawer
        variant="temporary"
        open={showSidebar}
      // anchor={'right'}
      >
        <Grid >
          <div className='flex justify-end' style={{ width: '250px', margin: '10px' }}>
            <CloseIcon onClick={() => { setShowSidebar(false) }} />
          </div>
          <Grid className='sideNav'>

            {NavabarList?.map((e, i) => {
              return (
                <Button
                  value={page}
                  className=" text-black font-medium mr-4 nav-link-btn"
                  key={e.id} onClick={() => onTabClick(e, setPage)}>
                  {`${e.label}`}
                </Button>
              )
            })}
          </Grid>

        </Grid>
      </Drawer>
      {/* <Home /> */}
    </Grid>
  );
  {/* <Grid item xs={3}>
      <AppLogo classes="ml-0" />
    </Grid> */}
  {/* <Grid item className="ml-auto flex items-center mr-8" xs="auto"> */ }
  {/* <Typography className="text-black">Welcome User <Person/> Test User </Typography> */
  }
  {/* <Button>
        <Typography><AccountCircleOutlined onClick={handleEquitiesClick} className="text-black" /></Typography>
      </Button> */}

  {/* </Grid> */ }
}


export default AppHeader;