import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core'
import React from 'react'
import { getCurrentDate, getCurrentMonth, getCurrentWeek, getDateDurationFromQuarterOfYear, getDateDurationFromYearStartToEnd, getDateDurationFromYearStartToHalf } from '../../utils';

const hregex = /H\d+/;
const lregex = /L\d+/;
const pregex = /p\d+/;
const nregex = /\d+/;

const getTooltip = (e) => {
  switch (e) {
    case "Yearly":
      let stry = getDateDurationFromYearStartToEnd()
      return stry
    case "Half Yearly":
      let strhy = getDateDurationFromYearStartToHalf()
      return strhy
    case "Quarterly":
      let strq = getDateDurationFromQuarterOfYear()
      return strq
    case "Monthly":
      let strm = getCurrentMonth()
      return strm
    case "Weekly":
      let strw = getCurrentWeek()
      return strw
    case "Daily":
      let strd = getCurrentDate()
      return strd
    default:
      return e
  }
}

function AnalysisTables(props) {
  const { heading, timeFramesForDataTable, data, keys, value, zones, fibonacci, Gaan, h_l_keys } = props

  const GaanFontColor = (e, i, _keys, t) => {
    if (i < 17) {
      if ((i === 15 && (t === "dailyKeys" || t === "quarterlyKeys")) || (i === 14 && (t === "weeklyKeys" || t === 'halfYearlyKeys')) || (i === 14 && (t === "monthlyKeys" || t === 'yearlyKeys'))) return "#fff"
      return "#339249";
    } else {
      if ((i === 17 && (t === "dailyKeys" || t === "quarterlyKeys")) || (i === 18 && (t === "weeklyKeys" || t === 'halfYearlyKeys')) || (i === 18 && (t === "monthlyKeys" || t === 'yearlyKeys'))) return "#fff"
      return "#DA2424";
    }


  }

  const GaanColor = (e, i, _keys, t) => {
    if (i < 17) {
      if ((i === 15 && (t === "dailyKeys" || t === "quarterlyKeys")) || (i === 14 && (t === "weeklyKeys" || t === 'halfYearlyKeys')) || (i === 14 && (t === "monthlyKeys" || t === 'yearlyKeys'))) return "#339249"
      return "#fff";
    } else {
      if ((i === 17 && (t === "dailyKeys" || t === "quarterlyKeys")) || (i === 18 && (t === "weeklyKeys" || t === 'halfYearlyKeys')) || (i === 18 && (t === "monthlyKeys" || t === 'yearlyKeys'))) return "#DA2424"
      return "#fff";
    }
  }
  const getColor = (e, i, _keys, t) => {
    if (heading === "All Pivot Points Analysis") {
      switch (heading) {
        case "All Pivot Points Analysis":
          let a_p_key = _keys[t][i];
          switch (t) {
            case "dailyKeys":
              switch (a_p_key) {
                case "FPH2_D":
                case "SPH1_D":
                case "FPL2_D":
                case "SPL1_D":
                  return "#333333"
                default:
                  let color = hregex.test(_keys[t][i]) ? "#339249" : lregex.test(_keys[t][i]) ? "#DA2424" : "#4D8FCC"
                  return color
              }
            case "weeklyKeys":
              switch (a_p_key) {
                case "FPH2_W":
                case "SPH1_W":
                case "FPL2_W":
                case "SPL1_W":
                  return "#333333"
                default:
                  let color = hregex.test(_keys[t][i]) ? "#339249" : lregex.test(_keys[t][i]) ? "#DA2424" : "#4D8FCC"
                  return color
              }
            case "monthlyKeys":
              switch (a_p_key) {
                case "FPH2_M":
                case "SPH1_M":
                case "FPL2_M":
                case "SPL1_M":
                  return "#333333"
                default:
                  let color = hregex.test(_keys[t][i]) ? "#339249" : lregex.test(_keys[t][i]) ? "#DA2424" : "#4D8FCC"
                  return color
              }
            case "quarterlyKeys":
              switch (a_p_key) {
                case "FPH2_Q":
                case "SPH1_Q":
                case "FPL2_Q":
                case "SPL1_Q":
                  return "#333333"
                default:
                  let color = hregex.test(_keys[t][i]) ? "#339249" : lregex.test(_keys[t][i]) ? "#DA2424" : "#4D8FCC"
                  return color
              }
            case "halfYearlyKeys":
              switch (a_p_key) {
                case "FPH2_HY":
                case "SPH1_HY":
                case "FPL2_HY":
                case "SPL1_HY":
                  return "#333333"
                default:
                  let color = hregex.test(_keys[t][i]) ? "#339249" : lregex.test(_keys[t][i]) ? "#DA2424" : "#4D8FCC"
                  return color
              }
            case "yearlyKeys":
              switch (a_p_key) {
                case "FPH2_Y":
                case "SPH1_Y":
                case "FPL2_Y":
                case "SPL1_Y":
                  return "#333333"
                default:
                  let color = hregex.test(_keys[t][i]) ? "#339249" : lregex.test(_keys[t][i]) ? "#DA2424" : "#4D8FCC"
                  return color
              }
            default:
              let color = hregex.test(_keys[t][i]) ? "#339249" : lregex.test(_keys[t][i]) ? "#DA2424" : "#4D8FCC"
              return color
          }
        default:
          let color = hregex.test(_keys[t][i]) ? "#339249" : lregex.test(_keys[t][i]) ? "#DA2424" : "#4D8FCC"
          return color
      }
    } else {
      let color = _keys[t] && _keys[t][i] ? (hregex.test(_keys[t][i]) ? "#339249" : lregex.test(_keys[t][i]) ? "#DA2424" : "#4D8FCC") : "#4D8FCC";
      return color
    }
  }
  const zonesColor = (e, i, _keys, t) => {
    let color;
    switch (i) {
      case 0:
      case 1:
      case 7:
      case 8:
        color = "#339249";
        return color

      case 2:
      case 3:
      case 9:
      case 10:
        color = "#DA2424";
        return color
      case 4:
      case 5:
      case 6:
        color = "#4D8FCC";
        return color

      default:
        color = "#444444"
        return color
    }

  }
  const fibonacciFontColor = (e, i, _keys, t) => {
    let color;;
    switch (i) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 6:
      case 8:
      case 10:
      case 12:
      case 14:
        color = "#339249";
        return color
      case 5:
      case 15:
        color = "#fff";
        return color
      case 7:
      case 17:
      case 23:
        color = "#fff";
        return color
      case 9:
      case 19:
        color = "#fff";
        return color
      case 16:
      case 18:
      case 20:
      case 21:
      case 22:
      case 24:
        color = "#DA2424"
        return color
      default:
        color = "#fff"
        return color
    }

  }


  const fibonacciColor = (e, i, _keys, t) => {
    let color;;
    switch (i) {
      case 5:
      case 15:
        color = "#f78452";
        return color
      case 7:
      case 17:
      case 23:
        color = "#7fc8fb";
        return color
      case 9:
      case 19:
        color = "#74c259";
        return color
      case 11:
        color = "#c1d635";
        return color
      case 13:
        color = "#fdab4b";
        return color
      default:
        color = "#fff"
        return color
    }

  }

  const getBgColor = (e, i, _keys, t) => {
    // let a_p_key = _keys[t][i];
    let a_p_key;
    if (_keys[t] && _keys[t][i] !== undefined) {
      a_p_key = _keys[t][i];
    } else {
      a_p_key = null; // or some default value
    }

    if (heading === "All Pivot Points Analysis") {
      switch (t) {
        case "dailyKeys":
          switch (a_p_key) {
            case "FPH2_D":
            case "SPH1_D":
              return "#4d8fcc"
            case "FPL2_D":
            case "SPL1_D":
              return "#FFA3B3"
            default:
              return "#fff"
          }
        case "weeklyKeys":
          switch (a_p_key) {
            case "FPH2_W":
            case "SPH1_W":
              return "#4d8fcc"
            case "FPL2_W":
            case "SPL1_W":
              return "#FFA3B3"
            default:
              return "#fff"
          }
        case "monthlyKeys":
          switch (a_p_key) {
            case "FPH2_M":
            case "SPH1_M":
              return "#4d8fcc"
            case "FPL2_M":
            case "SPL1_M":
              return "#FFA3B3"
            default:
              return "#fff"
          }
        case "quarterlyKeys":
          switch (a_p_key) {
            case "FPH2_Q":
            case "SPH1_Q":
              return "#4d8fcc"
            case "FPL2_Q":
            case "SPL1_Q":
              return "#FFA3B3"
            default:
              return "#fff"
          }
        case "halfYearlyKeys":
          switch (a_p_key) {
            case "FPH2_HY":
            case "SPH1_HY":
              return "#4d8fcc"
            case "FPL2_HY":
            case "SPL1_HY":
              return "#FFA3B3"
            default:
              return "#fff"
          }
        case "yearlyKeys":
          switch (a_p_key) {
            case "FPH2_Y":
            case "SPH1_Y":
              return "#4d8fcc"
            case "FPL2_Y":
            case "SPL1_Y":
              return "#FFA3B3"
            default:
              return "#fff"
          }
        default:
          return "#fff"
      }
    }
  }

  const getKeys = (i) => {
    return `${h_l_keys[i]}`
  }

  return (
    <>
      <TableContainer style={{ borderRadius: "10px", marginTop: '10px' }}>
        <p className='table-heading'>{heading}</p>
        <Table>
          <TableHead className='table-head'>
            <TableRow>
              {h_l_keys?.length > 0 && <TableCell style={{ padding: "0px 5px !important" }}>{heading == "Gaan Analysis" ? "Degree" : "Level"}</TableCell>}
              {timeFramesForDataTable.map((e, i) => (
                <Tooltip key={i} title={getTooltip(e)} arrow placement="top">
                  <TableCell key={i} align='center'>{e}</TableCell>
                </Tooltip>))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!zones && !fibonacci && !Gaan && data.length && data.map((e, i) => {
              return (
                <TableRow key={i} className='table-row'>
                  {h_l_keys?.length && <TableCell style={{ color: getColor(e, i, keys, `${value == 2 ? "dailyKeys" : "quarterlyKeys"}`), fontSize: "11px", width: "40px" }}>{getKeys(i)}</TableCell>}
                  <TableCell style={{ color: getColor(e, i, keys, `${value == 2 ? "dailyKeys" : "quarterlyKeys"}`), backgroundColor: getBgColor(e, i, keys, `${value == 2 ? "dailyKeys" : "quarterlyKeys"}`) }}>{e.daily}</TableCell>
                  <TableCell style={{ color: getColor(e, i, keys, `${value == 2 ? "weeklyKeys" : "halfYearlyKeys"}`), backgroundColor: getBgColor(e, i, keys, `${value == 2 ? "weeklyKeys" : "halfYearlyKeys"}`) }}>{e.weekly}</TableCell>
                  <TableCell style={{ color: getColor(e, i, keys, `${value == 2 ? "monthlyKeys" : "yearlyKeys"}`), backgroundColor: getBgColor(e, i, keys, `${value == 2 ? "monthlyKeys" : "yearlyKeys"}`) }}>{e.monthly}</TableCell>
                </TableRow>
              )
            })}
            {zones && data.length && data.map((e, i) => {
              return (
                <TableRow key={i} className='table-row'>
                  {h_l_keys?.length && <TableCell style={{ color: zonesColor(e, i, keys, `${value == 2 ? "dailyKeys" : "quarterlyKeys"}`), fontSize: "11px", width: "40px" }}>{getKeys(i)}</TableCell>}
                  <TableCell style={{ color: zonesColor(e, i, keys, `${value == 2 ? "dailyKeys" : "quarterlyKeys"}`) }}>{e.daily}</TableCell>
                  <TableCell style={{ color: zonesColor(e, i, keys, `${value == 2 ? "weeklyKeys" : "halfYearlyKeys"}`) }}>{e.weekly}</TableCell>
                  <TableCell style={{ color: zonesColor(e, i, keys, `${value == 2 ? "monthlyKeys" : "yearlyKeys"}`) }}>{e.monthly}</TableCell>
                </TableRow>
              )
            })}
            {fibonacci && data.length && data.map((e, i) => {
              return (
                <TableRow key={i} className='table-row'>
                  {h_l_keys?.length && <TableCell style={{ color: fibonacciFontColor(e, i, keys, `${value == 2 ? "dailyKeys" : "quarterlyKeys"}`), background: fibonacciColor(e, i, keys, `${value == 2 ? "dailyKeys" : "quarterlyKeys"}`), fontSize: "11px", width: "40px", padding: "3px 5px" }}>{getKeys(i)}</TableCell>}
                  <TableCell style={{ color: fibonacciFontColor(e, i, keys, `${value == 2 ? "dailyKeys" : "quarterlyKeys"}`), background: fibonacciColor(e, i, keys, `${value == 2 ? "dailyKeys" : "quarterlyKeys"}`) }}>{e.daily}</TableCell>
                  <TableCell style={{ color: fibonacciFontColor(e, i, keys, `${value == 2 ? "dailyKeys" : "quarterlyKeys"}`), background: fibonacciColor(e, i, keys, `${value == 2 ? "weeklyKeys" : "halfYearlyKeys"}`) }}>{e.weekly}</TableCell>
                  <TableCell style={{ color: fibonacciFontColor(e, i, keys, `${value == 2 ? "dailyKeys" : "yearlyKeys"}`), background: fibonacciColor(e, i, keys, `${value == 2 ? "weeklyKeys" : "yearlyKeys"}`) }}>{e.monthly}</TableCell>
                </TableRow>
              )
            })}
            {Gaan && data.length && data.map((e, i) => {
              return (
                <TableRow key={i} className='table-row'>
                  {h_l_keys?.length && <TableCell style={{ color: i < 17 ? "#339249" : "#DA2424" }}>{getKeys(i)}&#176;</TableCell>}
                  <TableCell style={{ color: GaanFontColor(e, i, keys, `${value == 2 ? "dailyKeys" : "quarterlyKeys"}`), backgroundColor: GaanColor(e, i, keys, `${value == 2 ? "dailyKeys" : "quarterlyKeys"}`) }}>{e.daily}</TableCell>
                  <TableCell style={{ color: GaanFontColor(e, i, keys, `${value == 2 ? "weeklyKeys" : "halfYearlyKeys"}`), backgroundColor: GaanColor(e, i, keys, `${value == 2 ? "weeklyKeys" : "halfYearlyKeys"}`) }}>{e.weekly}</TableCell>
                  <TableCell style={{ color: GaanFontColor(e, i, keys, `${value == 2 ? "monthlyKeys" : "yearlyKeys"}`), backgroundColor: GaanColor(e, i, keys, `${value == 2 ? "monthlyKeys" : "yearlyKeys"}`) }}>{e.monthly}</TableCell>
                </TableRow>
              )
            })}

          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default AnalysisTables